import { customFetch } from "../helpers";
import { IAdmin } from "../state/user/user.interface";

const mapAdminResponse = (admin) => {
  return {
    id: admin.id,
    name: admin.name,
    password: admin.password,
    email: admin.email,
    phone: admin.phone,
    adminActive: admin.admin_active,
    c_at: admin.c_at,
    u_at: admin.u_at,
  } as IAdmin;
};
export const getAdmin = async (params: { limit?: number; skip?: number }) => {
  try {
    const response: any = await customFetch(
      `${process.env.REACT_APP_ACCOUNT_API}/api/v1/admin?limit=${params.limit}&skip=${params.skip}`,
      "GET"
    );

    response.data.results = response.data.results.map((admin) =>
      mapAdminResponse(admin)
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const createAdmin = async (admin: IAdmin) => {
  try {
    const response: any = await customFetch(
      `${process.env.REACT_APP_ACCOUNT_API}/api/v1/admin`,
      "POST",
      admin
    );

    response.data = mapAdminResponse(response.data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const editAdmin = async (editedAdmin: IAdmin) => {
  try {
    const response: any = await customFetch(
      `${process.env.REACT_APP_ACCOUNT_API}/api/v1/admin/${editedAdmin.id}`,
      "PUT",
      editedAdmin
    );

    response.data = mapAdminResponse(response.data);

    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteAdmin = async (adminId: string) => {
  try {
    const response: any = await customFetch(
      `${process.env.REACT_APP_ACCOUNT_API}/api/v1/admin/${adminId}`,
      "DELETE"
    );

    return response;
  } catch (error) {
    throw error;
  }
};
