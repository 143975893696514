import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  Button,
  Modal,
  Divider,
  Row,
  Select,
  Input,
  Tag,
  Col,
  Popconfirm,
} from "antd";
import moment from "moment";
import { InboxOutlined } from '@ant-design/icons';
import { Breakpoint } from "antd/lib/_util/responsiveObserve";
import { UploadFile } from 'antd/lib/upload/interface';

import * as MitraActions from "../../../state/mitra/mitra.action";
import * as MitraSelectors from "../../../state/mitra/mitra.selector";
import { IMitra, ITransactionMitra } from "../../../state/mitra/mitra.interface";
import { Layout } from "../../../const";
import { UploadImage, SimplePagination, CustomImage } from '../../../components'; 

const DashboardAdmin = (props) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(MitraSelectors.selectLoading);
  const transactionList = useSelector(MitraSelectors.selectTransactions);
  const isSuccess = useSelector(MitraSelectors.selectSuccess);
  const [transactionModal, setTransactionModal] = useState(false);
  const [filterStatus, setFilterStatus] = useState<string>("");
  const [filterType, setFilterType] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [selectedTransaction, setSelectedTransaction] = useState<ITransactionMitra>({
    code: "",
    name: "",
    amount: 0,
    notes: "",
    status: "",
    receiptImage: "",
    type: "",
    order: {
      code: "",
      status: "",
      price: 0,
      service_code: "",
      work_time: "",
    },
    mitra: {
      code: "",
      phone: "",
      name: "",
    },
    payment: {
      code: "",
      name: "",
      type: "",
    },
    c_at: "",
    u_at: "",
  });
  const [images, setImages] = useState<any>([]);

  useEffect(() => {
    dispatch(
      MitraActions.getTransaction.request({
        status: filterStatus,
        page: currentPage,
        type: filterType
      })
    );
  }, [currentPage, filterStatus, filterType]);

  useEffect(() => {
    if (isSuccess) {
      setTransactionModal(false);
    }
  }, [isSuccess]);

  const setStateFileList = (fileList: UploadFile[], name: string) => {
    // eslint-disable-next-line no-undef
    return new Promise(async (resolve) => {
      await setImages(fileList);
      await resolve()
    });
  };

  const sliceFileList = (name: string) => {
    setImages(images[name].splice(0, 1));
  };

  const setStateForm = (result: string | ArrayBuffer | null, name: string) => {
    if (result) {
      setSelectedTransaction({
        ...selectedTransaction,
        receiptImage: result
      })
    }
  };

  const tagColor = (status: string) => {
    let color = "";

    switch (status) {
      case "done":
        color = "green";
        break;
      case "pending":
        color = "geekblue";
        break;
      case "cancel":
        color = "volcano";
        break;
      default:
        break;
    }

    return (
      <Tag color={color} key={status}>
        {status.toUpperCase()}
      </Tag>
    );
  };

  const columns = [
    {
      title: "Kode",
      dataIndex: "code",
      key: "code",
      responsive: ["lg"] as Breakpoint[],
    },
    {
      title: "Tanggal",
      dataIndex: "c_at",
      key: "created_at",
      render: (createdAt: string) => (
        <p>
          {moment(createdAt).format("l")} - {moment(createdAt).format("HH:mm")}
        </p>
      ),
    },
    {
      title: "Mitra",
      dataIndex: "mitra",
      key: "mitra",
      render: (mitra: IMitra) => {
        return mitra.name;
      },
    },
    // {
    //   title: "Keterangan",
    //   dataIndex: "name",
    //   key: "name",
    // },
    {
      title: "Jumlah",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Tipe",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: string) => tagColor(status),
    },
    {
      title: "Action",
      key: "action",
      render: (transaction: ITransactionMitra) => {
        return (
          <Button onClick={() => onClickTransactionDetail(transaction)}>
            Detail
          </Button>
        );
      },
    },
  ];

  const onClickTransactionDetail = (transaction: ITransactionMitra) => {
    setTransactionModal(true);
    setSelectedTransaction(transaction);
  };

  const onClickAccept = (value: string) => {
    dispatch(
      MitraActions.editTransactionStatus.request({
        code: selectedTransaction.code,
        notes: "",
        status: "done",
        amount: Number(value),
      })
    );
  };

  const onClickReject = () => {
    dispatch(
      MitraActions.editTransactionStatus.request({
        code: selectedTransaction.code,
        notes: "",
        status: "cancel",
        amount: 0,
      })
    );
  };

  const renderModalAction = (status: string, type: string) => {
    if (status === "pending") {
      return (
        <Row justify="end">
          {
            type === 'deposit' ? 
            <Input.Search
              style={{ width: "50%" }}
              allowClear
              placeholder="input nominal"
              enterButton="Terima"
              loading={isLoading}
              type="number"
              onSearch={onClickAccept}
            /> : 
            <Popconfirm 
              title="Apakah file yang diupload sudah benar?"
              onConfirm={() => dispatch(MitraActions.editTransactionStatus.request({
                code: selectedTransaction.code,
                notes: "",
                status: "done",
                amount: selectedTransaction.amount,
                receiptImage: selectedTransaction.receiptImage
              }))}>
              <Button
                disabled={!selectedTransaction.receiptImage}
                loading={isLoading}>Withdraw</Button>
            </Popconfirm>
          }
          <Popconfirm
            title="Batalkan pengajuan ini?"
            onConfirm={() => onClickReject()}
          >
            <Button
              danger
              style={{
                marginLeft: "10px",
              }}
              loading={isLoading}
            >
              Batalkan
            </Button>
          </Popconfirm>
        </Row>
      );
    }
  };

  const renderModalTransactionDetail = () => {
    return (
      <Modal
        visible={transactionModal}
        onCancel={() => setTransactionModal(false)}
        footer={null}
      >
        <h2
          style={{
            display: "flex",
          }}
        >
          {selectedTransaction.type.toUpperCase()} - {selectedTransaction.code}{" "}
          <span
            style={{
              marginLeft: "10px",
              alignItems: "center",
              display: "flex",
            }}
          >
            {tagColor(selectedTransaction.status)}
          </span>
        </h2>
       
        {/* <p>Nama Transaksi: {selectedTransaction.name}</p> */}
        <Row justify='space-between'>
          <Col>
            <h3>Mitra - {selectedTransaction.mitra?.code}</h3>
            <p>{selectedTransaction.mitra?.name}</p>
          </Col>
          <Col>
            <h3>
            {moment(selectedTransaction.c_at).format("ll")} -{" "}
            {moment(selectedTransaction.c_at).format("HH:mm")}
            </h3>
          </Col>
        </Row>
       
        <p>Jumlah: Rp {selectedTransaction.amount}</p>
        <p>Notes: {selectedTransaction.notes}</p>
        <p>
          {selectedTransaction.order?.code ? (
            <>
              <Divider></Divider>
              <h3>Order - {selectedTransaction.order?.code}</h3>
              <p>Status: {selectedTransaction.order?.status}</p>
              <p>Harga: {selectedTransaction.order?.price}</p>
              <p>Waktu pengerjaan: {selectedTransaction.order.work_time}</p>
            </>
          ) : null}
        </p>

        
        <Divider></Divider>
        <h3>Payment - {selectedTransaction.payment?.code}</h3>
        <p>{selectedTransaction.payment?.name}</p>
        {
          selectedTransaction.type === 'withdraw' ?
          selectedTransaction.receiptImage ? 
          <CustomImage
            image={selectedTransaction.receiptImage}
            command={() => setSelectedTransaction({
            ...selectedTransaction,
            receiptImage: ""
          })}></CustomImage> :
          <UploadImage
            setStateFileList={ setStateFileList }
            sliceFileList={ sliceFileList }
            setStateForm={ setStateForm }
            name='image'
            fileList={ images.image }
            accept='.png,.jpg'
            icon={ <InboxOutlined style={{fontSize: '24px'}}/> }
          /> :
          <img
            style={{
              width: "100%",
            }}
            alt="transaction_receipt"
            src={selectedTransaction.receiptImage}
          />
        }
        
        <Divider></Divider>
        {renderModalAction(selectedTransaction.status, selectedTransaction.type)}
      </Modal>
    );
  };

  const { Option } = Select;
  return (
    <>
      <h1>Transaksi</h1>
      <Row align="middle">
      <Select
          style={{
            marginBottom: "20px",
            width: "200px",
            marginRight: "20px"
          }}
          showSearch
          placeholder="Filter tipe"
          onChange={(value: string) => {
            setFilterType(value);
            setCurrentPage(1);
          }}
        >
          <Option value="">Semua Tipe</Option>
          <Option value="deposit">Deposit</Option>
          <Option value="withdraw">Withdraw</Option>
        </Select>
        <Select
          style={{
            marginBottom: "20px",
            width: "200px",
          }}
          showSearch
          placeholder="Filter status"
          onChange={(value: string) => {
            setFilterStatus(value);
            setCurrentPage(1);
          }}
        >
          <Option value="">Semua Status</Option>
          <Option value="pending">Pending</Option>
          <Option value="done">Done</Option>
          <Option value="cancel">Cancel</Option>
        </Select>
      </Row>
      <Table
        loading={isLoading}
        columns={columns}
        dataSource={transactionList}
        {...Layout.table}
      />
      <SimplePagination
        currentPage={currentPage}
        onChange={setCurrentPage}
        dataLength={transactionList.length}
      />
      {renderModalTransactionDetail()}
    </>
  );
};

export default DashboardAdmin;
