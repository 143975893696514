export enum UserActionType {
  // USER
  LoginPassword = "[AUTH] LOGIN",
  LoginPasswordSuccess = "[AUTH] LOGIN SUCCESS",
  LoginPasswordError = "[AUTH] LOGIN ERROR",

  GetAdmin = "[USER] GET ADMIN",
  GetAdminSuccess = "[USER] GET ADMIN SUCCESS",
  GetAdminError = "[USER] GET ADMIN ERROR",

  CreateAdmin = "[USER] CREATE ADMIN",
  CreateAdminSuccess = "[USER] CREATE ADMIN SUCCESS",
  CreateAdminError = "[USER] CREATE ADMIN ERROR",

  EditAdmin = "[USER] EDIT ADMIN",
  EditAdminSuccess = "[USER] EDIT ADMIN SUCCESS",
  EditAdminError = "[USER] EDIT ADMIN ERROR",

  DeleteAdmin = "[USER] DELETE ADMIN",
  DeleteAdminSuccess = "[USER] DELETE ADMIN SUCCESS",
  DeleteAdminError = "[USER] DELETE ADMIN ERROR",
}
