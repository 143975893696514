import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Button } from "antd";

import * as ServiceActions from "../../../state/service/service.action";
import { IArea } from "../../../state/service/service.interface";
import * as ServiceSelectors from "../../../state/service/service.selector";
import { Layout } from "../../../const";

const ServiceSettings = (props) => {
  const dispatch = useDispatch();
  const areas = useSelector(ServiceSelectors.selectAreas);
  const isLoading = useSelector(ServiceSelectors.selectLoading);

  useEffect(() => {
    dispatch(ServiceActions.getArea.request());
  }, []);

  const columns = [
    {
      title: "Kode",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Kota",
      dataIndex: "city",
      key: "city",
    },
    {
      title: "longitude",
      dataIndex: "longitude",
      key: "longitude",
    },
    {
      title: "latitude",
      dataIndex: "latitude",
      key: "latitude",
    },
    {
      title: "radius",
      dataIndex: "radius",
      key: "radius",
    },
    {
      title: "Action",
      key: "action",
      render: (area: IArea) => {
        return (
          <>
            <Button
              onClick={() =>
                props.history.push(`/settings/service/${area.code}`)
              }
            >
              Layanan
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <>
      <h1>SERVICE SETTINGS</h1>
      <Table
        loading={isLoading}
        dataSource={areas}
        columns={columns}
        {...Layout.table}
      />
    </>
  );
};

export default ServiceSettings;
