import React, { useEffect } from "react";
import DashboardAdmin from "./dashboardAdmin";
import DashboardBusinessPartner from "./dashboardBusinessPartner";

const Dashboard = (props) => {
  useEffect(() => {});

  if (localStorage.getItem("role") === "admin") {
    return <DashboardAdmin />;
  } else {
    return <DashboardBusinessPartner />;
  }
};

export default Dashboard;
