export enum MitraActionType {
  MitraError = "[MITRA] ACTION ERROR",

  GetMitra = "[MITRA] GET MITRA",
  GetMitraSuccess = "[MITRA] GET MITRA SUCCESS",

  SetSelectedMitraCode = "[MITRA] SET SELECTED MITRA CODE",

  EditMitra = "[MITRA] EDIT MITRA",
  EditMitraSuccess = "[MITRA] EDIT MITRA SUCCESS",

  GetMitraByCode = "[MITRA] GET MITRA BY CODE",
  GetMitraByCodeSuccess = "[MITRA] GET MITRA BY CODE SUCCESS",

  GetMitraServices = "[MITRA] GET MITRA SERVICES",
  GetMitraServicesSuccess = "[MITRA] GET MITRA SERVICES SUCCESS",

  InsertMitraService = "[MITRA] INSERT MITRA SERVICES",
  InsertMitraServiceSuccess = "[MITRA] INSERT MITRA SERVICES SUCCESS",

  RemoveMitraService = "[MITRA] REMOVE MITRA SERVICES",
  RemoveMitraServiceSuccess = "[MITRA] REMOVE MITRA SERVICES SUCCESS",

  RegisterMitra = "[MITRA] REGISTER MITRA",
  RegisterMitraSuccess = "[MITRA] REGISTER MITRA SUCCESS",

  GetTransaction = "[MITRA] GET TRANSACTION",
  GetTransactionSuccess = "[MITRA] GET TRANSACTION SUCCESS",

  EditTransactionStatus = "[MITRA] EDIT TRANSACTION STATUS",
  EditTransactionStatusSuccess = "[MITRA] EDIT TRANSACTION STATUS SUCCESS",

  GetBalance = "[MITRA] GET BALANCE",
  GetBalanceSuccess = "[MITRA] GET BALANCE SUCCESS",

  GetOrderOnGoing = "[MITRA] GET ORDER ON GOING",
  GetOrderOnGoingSuccess = "[MITRA] GET ORDER ON GOING SUCCESS",

  GetOrderHistory = "[MITRA] GET ORDER HISTORY",
  GetOrderHistorySuccess = "[MITRA] GET ORDER HISTORY SUCCESS",

  SETSUCCESSFALSE = '[MITRA] SET SUCCESS FALSE'
}
