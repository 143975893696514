/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncAction } from "typesafe-actions";
import { AuthActionType } from "./auth.actionType";

// AUTH
export const loginPassword = createAsyncAction(
  AuthActionType.LoginPassword,
  AuthActionType.LoginPasswordSuccess,
  AuthActionType.AuthError
)<
  { username: String; password: String },
  {
    email: String;
    id: String;
    name: String;
    phone: String;
  },
  {
    errorMsg: string;
  }
>();
