import { createAction, createAsyncAction } from "typesafe-actions";

import { ServiceActionType } from "./service.actionType";
import {
  IArea,
  IBusinessPartner,
  ICoupon,
  IPromotion,
  IService,
  IServiceDuration,
} from "./service.interface";

export const getServiceParent = createAsyncAction(
  ServiceActionType.GetServiceParent,
  ServiceActionType.GetServiceParentSuccess,
  ServiceActionType.ServiceError
)<
  { areaCode: string },
  any,
  {
    errorMsg: string;
  }
>();

export const getServiceChild = createAsyncAction(
  ServiceActionType.GetServiceChild,
  ServiceActionType.GetServiceChildSuccess,
  ServiceActionType.ServiceError
)<
  { parentCode: string },
  any,
  {
    errorMsg: string;
  }
>();

export const createServiceChild = createAsyncAction(
  ServiceActionType.CreateServiceChild,
  ServiceActionType.CreateServiceChildSuccess,
  ServiceActionType.ServiceError
)<
  {
    name: string;
    price: number;
    duration: number;
    imageUrl: string;
  },
  {
    service: IService;
  },
  {
    errorMsg: string;
  }
>();

export const editServiceChild = createAsyncAction(
  ServiceActionType.EditServiceChild,
  ServiceActionType.EditServiceChildSuccess,
  ServiceActionType.ServiceError
)<
  {
    service: IService;
  },
  {
    service: IService;
  },
  {
    errorMsg: string;
  }
>();

export const deleteService = createAsyncAction(
  ServiceActionType.DeleteService,
  ServiceActionType.DeleteServiceSuccess,
  ServiceActionType.ServiceError
)<
  {
    service: IService;
  },
  {
    service: IService;
  },
  {
    errorMsg: string;
  }
>();

export const getServiceDuration = createAsyncAction(
  ServiceActionType.GetServiceDuration,
  ServiceActionType.GetServiceDurationSuccess,
  ServiceActionType.ServiceError
)<
  { serviceCode: string },
  any,
  {
    errorMsg: string;
  }
>();

export const createServiceDuration = createAsyncAction(
  ServiceActionType.CreateServiceDuration,
  ServiceActionType.CreateServiceDurationSuccess,
  ServiceActionType.ServiceError
)<
  {
    name: string;
    price: number;
    duration: number;
    serviceCode: string;
  },
  {
    duration: IServiceDuration;
  },
  {
    errorMsg: string;
  }
>();

export const editServiceDuration = createAsyncAction(
  ServiceActionType.EditServiceDuration,
  ServiceActionType.EditServiceDurationSuccess,
  ServiceActionType.ServiceError
)<
  {
    name: string;
    price: number;
    duration: number;
    serviceCode: string;
    durationCode: string;
  },
  {
    duration: IServiceDuration;
  },
  {
    errorMsg: string;
  }
>();

export const removeServiceDuration = createAsyncAction(
  ServiceActionType.RemoveServiceDuration,
  ServiceActionType.RemoveServiceDurationSuccess,
  ServiceActionType.ServiceError
)<
  {
    durationCode: string;
  },
  {
    durationCode: string;
  },
  {
    errorMsg: string;
  }
>();

export const getBusinessPartner = createAsyncAction(
  ServiceActionType.GetBusinessPartner,
  ServiceActionType.GetBusinessPartnerSuccess,
  ServiceActionType.ServiceError
)<
  {
    page: number;
  },
  any,
  {
    errorMsg: string;
  }
>();

export const getSelfBusinessPartner = createAsyncAction(
  ServiceActionType.GetSelfBusinessPartner,
  ServiceActionType.GetSelfBusinessPartnerSuccess,
  ServiceActionType.ServiceError
)<
  undefined,
  IBusinessPartner,
  {
    errorMsg: string;
  }
>();

export const registerBusinessPartner = createAsyncAction(
  ServiceActionType.RegisterBusinessPartner,
  ServiceActionType.RegisterBusinessPartnerSuccess,
  ServiceActionType.ServiceError
)<
  IBusinessPartner,
  IBusinessPartner,
  {
    errorMsg: string;
  }
>();

export const editBusinessPartner = createAsyncAction(
  ServiceActionType.EditBusinessPartner,
  ServiceActionType.EditBusinessPartnerSuccess,
  ServiceActionType.ServiceError
)<
  { businessPartner: IBusinessPartner; businessPartnerCode: string },
  IBusinessPartner,
  {
    errorMsg: string;
  }
>();

export const deleteBusinessPartner = createAsyncAction(
  ServiceActionType.DeleteBusinessPartner,
  ServiceActionType.DeleteBusinessPartnerSuccess,
  ServiceActionType.ServiceError
)<
  {
    businessPartnerCode: string;
  },
  {
    businessPartnerCode: string;
  },
  {
    errorMsg: string;
  }
>();

// PROMOTION ACTION
export const getPromotion = createAsyncAction(
  ServiceActionType.GetPromotions,
  ServiceActionType.GetPromotionsSuccess,
  ServiceActionType.ServiceError
)<
  undefined,
  IPromotion[],
  {
    errorMsg: string;
  }
>();

export const editPromotion = createAsyncAction(
  ServiceActionType.EditPromotion,
  ServiceActionType.EditPromotionSuccess,
  ServiceActionType.ServiceError
)<
  IPromotion,
  IPromotion,
  {
    errorMsg: string;
  }
>();

export const createPromotion = createAsyncAction(
  ServiceActionType.CreatePromotion,
  ServiceActionType.CreatePromotionSuccess,
  ServiceActionType.ServiceError
)<
  {
    name: string;
    description: string;
    imageUrl: string;
    serviceCode: string;
  },
  IPromotion,
  {
    errorMsg: string;
  }
>();

export const deletePromotion = createAsyncAction(
  ServiceActionType.DeletePromotion,
  ServiceActionType.DeletePromotionSuccess,
  ServiceActionType.ServiceError
)<
  {
    promotionCode: string;
  },
  {
    promotionCode: string;
  },
  {
    errorMsg: string;
  }
>();

// COUPON ACTION
export const getCoupon = createAsyncAction(
  ServiceActionType.GetCoupon,
  ServiceActionType.GetCouponSuccess,
  ServiceActionType.ServiceError
)<
  {
    page: number;
  },
  ICoupon[],
  {
    errorMsg: string;
  }
>();

export const createCoupon = createAsyncAction(
  ServiceActionType.CreateCoupon,
  ServiceActionType.CreateCouponSuccess,
  ServiceActionType.ServiceError
)<
  ICoupon,
  ICoupon,
  {
    errorMsg: string;
  }
>();

export const editCoupon = createAsyncAction(
  ServiceActionType.EditCoupon,
  ServiceActionType.EditCouponSuccess,
  ServiceActionType.ServiceError
)<
  ICoupon,
  ICoupon,
  {
    errorMsg: string;
  }
>();

export const deleteCoupon = createAsyncAction(
  ServiceActionType.DeleteCoupon,
  ServiceActionType.DeleteCouponSuccess,
  ServiceActionType.ServiceError
)<
  string,
  string,
  {
    errorMsg: string;
  }
>();

export const getArea = createAsyncAction(
  ServiceActionType.GetAreas,
  ServiceActionType.GetAreasSuccess,
  ServiceActionType.ServiceError
)<
  undefined,
  IArea[],
  {
    errorMsg: string;
  }
>();

// export const editArea = createAsyncAction(
//   ServiceActionType.EditArea,
//   ServiceActionType.EditAreaSuccess,
//   ServiceActionType.EditAreaError
// )<IArea, IArea, any>();

// export const createArea = createAsyncAction(
//   ServiceActionType.CreateArea,
//   ServiceActionType.CreateAreaSuccess,
//   ServiceActionType.CreateAreaError
// )<any, IArea, any>();

// export const deleteArea = createAsyncAction(
//   ServiceActionType.DeleteArea,
//   ServiceActionType.DeleteAreaSuccess,
//   ServiceActionType.DeleteAreaError
// )<
//   {
//     areaCode: string;
//   },
//   {
//     areaCode: string;
//   },
//   any
// >();

export const setParentCode = createAction(
  ServiceActionType.SetParentCode
)<string>();
