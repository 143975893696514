import React from 'react';
import { Badge, Avatar } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

import { Colors } from '../../const';
import { Container } from './style';

const { red } = Colors;

interface CustomImageAndVideoProps {
  command: (event: any) => void;
  image?: string;
}

const CustomImage : React.FC<CustomImageAndVideoProps> = (props) => {
  return (
    <Container>
      <Badge
        style={ {
          marginRight: '5px',
        } }
        count={
          <CloseCircleOutlined
            onClick={ props.command }
            style={ {fontSize: '2em', color: red, cursor: 'pointer' } }
          />
        }
      >
        <Avatar shape='square' src={ props.image }/>
      </Badge>
    </Container>
  );
  
}

export default CustomImage;
