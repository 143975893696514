import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Button, Modal, Row, Form, Input } from "antd";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";

import * as UserActions from "../../../state/user/user.action";
import * as UserSelectors from "../../../state/user/user.selector";
import { IAdmin } from "../../../state/user/user.interface";
import { Layout } from "../../../const";
import SimplePagination from "../../../components/simplePagination";

const AdminSetting = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(UserSelectors.selectLoading);
  const adminList = useSelector(UserSelectors.selectAdminList);
  const isSuccess = useSelector(UserSelectors.selectSuccess);

  const [form] = Form.useForm();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedAdmin, setSelectedAdmin] = useState<IAdmin>({
    name: "",
    password: "",
    email: "",
    phone: "",
    id: "",
    adminActive: false,
    c_at: "",
    u_at: "",
  });

  useEffect(() => {
    dispatch(
      UserActions.getAdmin.request({
        page: currentPage,
      })
    );
  }, [currentPage]);

  useEffect(() => {
    if (isSuccess) {
      setIsModalOpen(false);
    }
  }, [isSuccess]);

  const columns = [
    {
      title: "Nama",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Active",
      dataIndex: "adminActive",
      key: "adminActive",
      render: (adminActive: IAdmin) => {
        if (adminActive) {
          return <CheckCircleTwoTone />;
        } else {
          return <CloseCircleTwoTone twoToneColor="#eb2f96" />;
        }
      },
    },
    {
      title: "Action",
      key: "action",
      render: (admin: IAdmin) => {
        return <Button onClick={() => onClickEdit(admin)}>Edit</Button>;
      },
    },
  ];

  const onClickEdit = (admin: IAdmin) => {
    setSelectedAdmin(admin);
    setIsModalOpen(true);
    setIsEdit(true);
    form.setFieldsValue({
      name: admin.name,
      phone: admin.phone,
      email: admin.email,
    });
  };

  const handleFormAdmin = (value) => {
    if (isEdit) {
      value.id = selectedAdmin.id;
      dispatch(UserActions.editAdmin.request(value));
    } else {
      dispatch(UserActions.createAdmin.request(value));
    }
  };

  const onClickDelete = () => {
    dispatch(
      UserActions.deleteAdmin.request({
        adminId: selectedAdmin.id,
      })
    );
  };

  const renderEditModal = () => {
    return (
      <Form
        id="adminForm"
        onFinish={handleFormAdmin}
        form={form}
        {...Layout.formItem}
      >
        <Modal
          title={isEdit ? "Edit Admin" : "Create Admin"}
          visible={isModalOpen}
          onCancel={() => {
            setIsModalOpen(false);
            form.resetFields();
          }}
          footer={[
            <Button
              style={{
                display: isEdit ? "" : "none",
              }}
              danger
              loading={isLoading}
              onClick={() => onClickDelete()}
            >
              Delete
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={isLoading}
              htmlType="submit"
              form="adminForm"
            >
              {isEdit ? "Edit" : "Submit"}
            </Button>,
          ]}
        >
          <Form.Item
            label="Nama"
            name="name"
            rules={[{ required: true }]}
            hasFeedback
          >
            <Input placeholder={"name"} />
          </Form.Item>

          <Form.Item
            label="Phone"
            name="phone"
            rules={[{ required: true }]}
            hasFeedback
          >
            <Input type="number" placeholder={"phone"} />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[{ required: true, type: "email" }]}
            hasFeedback
          >
            <Input type="email" placeholder={"email"} />
          </Form.Item>
          {isEdit ? null : (
            <>
              <Form.Item
                label="Password"
                name="password"
                rules={[{ required: true }]}
                hasFeedback
              >
                <Input.Password type="password" placeholder={"password"} />
              </Form.Item>
              <Form.Item
                label="Confirm Password"
                name="confirmPassword"
                dependencies={["password"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        "The two passwords that you entered do not match!"
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  type="password"
                  placeholder={"confirm password"}
                />
              </Form.Item>
            </>
          )}
        </Modal>
      </Form>
    );
  };

  return (
    <>
      <h1>ADMIN SETING</h1>
      <Row
        justify="end"
        style={{
          marginBottom: "20px",
        }}
      >
        <Button
          onClick={() => {
            setIsModalOpen(true);
            setIsEdit(false);
          }}
        >
          Create
        </Button>
      </Row>
      <Table
        loading={isLoading}
        dataSource={adminList}
        columns={columns}
        {...Layout.table}
      />
      <SimplePagination
        currentPage={currentPage}
        onChange={setCurrentPage}
        dataLength={adminList.length}
      />
      {renderEditModal()}
    </>
  );
};

export default AdminSetting;
