import Mitra from "../../pages/mitra/";
import MitraTransaction from "../../pages/mitra/mitraTransaction";
import MitraProfile from '../../pages/mitra/mitraProfile';
import MitraRegistration from "../../pages/mitra/mitraRegistration";

const baseMitraUrl = "mitra";

const mitraRouteAdmin = [
  {
    path: `${baseMitraUrl}`,
    component: Mitra,
  },
  {
    path: `${baseMitraUrl}/registration`,
    component: MitraRegistration,
  },
  {
    path: `${baseMitraUrl}/:mitraCode`,
    component: MitraTransaction,
  },
  {
    path: `${baseMitraUrl}/:mitraCode/profile`,
    component: MitraProfile,
  },
];

const mitraRouteBusinessPartner = [
  {
    path: `${baseMitraUrl}`,
    component: Mitra,
  },
  {
    path: `${baseMitraUrl}/:mitraCode`,
    component: MitraTransaction,
  },
  {
    path: `${baseMitraUrl}/:mitraCode/profile`,
    component: MitraProfile,
  },
];

let mitraRoute =
  localStorage.getItem("role") === "admin"
    ? mitraRouteAdmin
    : mitraRouteBusinessPartner;

export default mitraRoute;
