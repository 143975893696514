// import { useSelector } from "react-redux";
import { createSelector } from "reselect";

import { IMitra, IMitraReducer } from "./mitra.interface";

const selectMitraReducer = (state: any) => state.mitra;

const selectLoading = createSelector(
  selectMitraReducer,
  (mitraReducer: IMitraReducer) => mitraReducer.loading
);

const selectSuccess = createSelector(
  selectMitraReducer,
  (mitraReducer: IMitraReducer) => mitraReducer.success
);

const selectMitraList = createSelector(
  selectMitraReducer,
  (mitraReducer: IMitraReducer) => mitraReducer.mitraList
);

const currentMitraCode = createSelector(
  selectMitraReducer,
  (mitraReducer: IMitraReducer) => mitraReducer.selectedMitraCode
);

const selectCurrentMitra = createSelector(
  selectMitraList,
  currentMitraCode,
  (mitraList: IMitra[], mitraCode: string) => {
    return mitraList.find((mitra: IMitra) => mitra.code === mitraCode);
  }
);

const selectMitraServices = createSelector(
  selectMitraReducer,
  (mitraReducer: IMitraReducer) => mitraReducer.mitraServices
);

const selectTransactions = createSelector(
  selectMitraReducer,
  (mitraReducer: IMitraReducer) => mitraReducer.transactionList
);

const selectMitraTransaction = createSelector(
  selectMitraReducer,
  (mitraReducer: IMitraReducer) => mitraReducer.mitraTransaction
)

const selectOrderOnGoing = createSelector(
  selectMitraReducer,
  (mitraReducer: IMitraReducer) => mitraReducer.mitraOrder.onGoing
)

const selectOrderHistory = createSelector(
  selectMitraReducer,
  (mitraReducer: IMitraReducer) => mitraReducer.mitraOrder.history
)

export {
  selectLoading,
  selectSuccess,
  selectMitraList,
  selectCurrentMitra,
  selectMitraServices,
  selectTransactions,
  selectMitraTransaction,
  selectOrderOnGoing,
  selectOrderHistory
};
