import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import { Table, Button, Select, Input, Row, Col } from "antd";

import * as OrderActions from "../../state/order/order.action";
import * as OrderSelectors from "../../state/order/order.selector";
import { Layout } from "../../const";
import { SimplePagination, OrderModal } from "../../components";
import { IOrder } from "../../state/order/order.interface";

const Orders = (props: any) => {
  const dispatch = useDispatch();
  const orderList: IOrder[] = useSelector(OrderSelectors.selectOrder);
  const isLoading = useSelector(OrderSelectors.selectLoading);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [orderModal, setOrderModal] = useState(false);
  const [orderCode, setOrderCode] = useState<string>('');
  const [filterStatus, setFilterStatus] = useState<string>("");;

  useEffect(() => {
    dispatch(
      OrderActions.getOrder.request({
        orderStatus: filterStatus,
        page: currentPage,
        code: ''
      })
    );
  }, [currentPage, filterStatus]);

  const columns = [
    {
      title: "Kode",
      dataIndex: "code",
      key: "code",
      render: (text: string) => <p>{text}</p>,
    },
    {
      title: "Waktu",
      dataIndex: "workTime",
      key: "workTime",
      render: (text: string) => (
        <p>
          {moment(text).format("ll")} - {moment(text).format("HH:mm")}
        </p>
      ),
    },
    {
      title: "Service",
      dataIndex: "service",
      key: "service-name",
      render: (service: any) => <p>{service.name}</p>,
    },
    {
      title: "Durasi (menit)",
      dataIndex: "duration",
      key: "duration",
      render: (duration: any) => <p>{duration.duration / 60}</p>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Action",
      key: "action",
      render: (order: any) => {
        return (
          <Button onClick={() => onClickOrderDetail(order)}>Detail</Button>
        );
      },
    },
  ];

  const onClickOrderDetail = (order: any) => {
    setOrderModal(true);
    setOrderCode(order.code)
  };

  const onSearch = (orderCode: string) => {
    console.warn('orderCode', orderCode);
    dispatch(
      OrderActions.getOrder.request({
        orderStatus: "",
        page: 1,
        code: orderCode
      })
    );
  };

  return (
    <>
      <h1>ORDER</h1>
      <Row gutter={20} style={{
         marginBottom: "20px",
      }}>
        <Col>
          <Select
            style={{
              width: "200px",
            }}
            showSearch
            placeholder="Filter status"
            onChange={(value: string) => {
              setFilterStatus(value);
              setCurrentPage(1);
            }}
          >
            <Select.Option value="">Semua Status</Select.Option>
            <Select.Option value="looking_for_mitra">Looking For Mitra</Select.Option>
            <Select.Option value="mitra_on_the_way">Mitra On The Way</Select.Option>
            <Select.Option value="start_service">Start Service</Select.Option>
            <Select.Option value="finish_service">Finish Service</Select.Option>
            <Select.Option value="done">Done</Select.Option>
            <Select.Option value="cancel">Cancel</Select.Option>
          </Select>
        </Col>
        <Col>
          <Input.Search
            style={{ width: "200px" }}
            allowClear
            placeholder="Search order code"
            loading={isLoading}
            type="string"
            onSearch={onSearch}
          />
        </Col>
      </Row>
      
      <Table
        loading={isLoading}
        columns={columns}
        dataSource={orderList}
        {...Layout.table}
      />
      <SimplePagination
        currentPage={currentPage}
        onChange={setCurrentPage}
        dataLength={orderList.length}
      />
      <OrderModal 
        active={orderModal}
        onChangeActive={setOrderModal}
        orderCode={orderCode}
      />
    </>
  );
};

export default Orders;
