import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { Customers, Dashboard, Orders, Mitra, Login, Main } from "../pages";

// import actions from '../../redux/dataMaster/agency/actions';
import { history } from "../helpers";
import PrivateRoute from "./private.routes";

function Router(props) {
  return (
    <ConnectedRouter history={history}>
      <Switch>
        <Route exact path="/login" component={Login} />
        <PrivateRoute path="/" component={Main} />
      </Switch>
    </ConnectedRouter>
  );
}

export default Router;
