import { createReducer } from "typesafe-actions";

import * as AuthActions from "../auth/auth.action";
import * as UserActions from "./user.action";
import { IAdmin, IUserReducer } from "./user.interface";

const initialState: IUserReducer = {
  user: {
    email: "",
    id: "",
    name: "",
    phone: "",
    businessPartnerCode: "",
  },
  adminList: [],
  loading: false,
  errorMsg: null,
  success: false,
};

export default createReducer(initialState)
  .handleAction(
    AuthActions.loginPassword.success,
    (state = initialState, { payload }: any) => {
      return {
        ...state,
        user: payload,
      };
    }
  )
  .handleAction(UserActions.getAdmin.request, (state = initialState) => ({
    ...state,
    loading: true,
  }))
  .handleAction(
    UserActions.getAdmin.success,
    (state = initialState, { payload }: any) => {
      return {
        ...state,
        loading: false,
        adminList: payload,
      };
    }
  )

  .handleAction(
    UserActions.getAdmin.failure,
    (state = initialState, { payload }: any) => ({
      ...state,
      loading: false,
      errorMsg: payload.errorMsg,
    })
  )
  .handleAction(UserActions.editAdmin.request, (state = initialState) => ({
    ...state,
    loading: true,
    success: false,
  }))
  .handleAction(
    UserActions.editAdmin.success,
    (state = initialState, { payload }: any) => {
      let currentAdminList = [...state.adminList];

      let index = currentAdminList.findIndex(
        (admin: IAdmin) => admin.id === payload.id
      );

      if (index > -1) {
        currentAdminList[index] = payload;
      }
      return {
        ...state,
        loading: false,
        adminList: currentAdminList,
        success: true,
      };
    }
  )

  .handleAction(
    UserActions.editAdmin.failure,
    (state = initialState, { payload }: any) => ({
      ...state,
      loading: false,
      errorMsg: payload.errorMsg,
      success: false,
    })
  )
  .handleAction(UserActions.createAdmin.request, (state = initialState) => ({
    ...state,
    loading: true,
    success: false,
  }))
  .handleAction(
    UserActions.createAdmin.success,
    (state = initialState, { payload }: any) => {
      return {
        ...state,
        loading: false,
        adminList: [payload, ...state.adminList],
        success: true,
      };
    }
  )

  .handleAction(
    UserActions.createAdmin.failure,
    (state = initialState, { payload }: any) => ({
      ...state,
      loading: false,
      errorMsg: payload.errorMsg,
      success: false,
    })
  )
  .handleAction(UserActions.deleteAdmin.request, (state = initialState) => ({
    ...state,
    loading: true,
    success: false,
  }))
  .handleAction(
    UserActions.deleteAdmin.success,
    (state = initialState, { payload }) => {
      let newAdminList = [...state.adminList];

      let index = newAdminList.findIndex(
        (admin: IAdmin) => admin.id == payload.adminId
      );

      if (index > -1) {
        newAdminList.splice(index, 1);
      }

      return {
        ...state,
        loading: false,
        adminList: newAdminList,
        success: true,
      };
    }
  )
  .handleAction(
    UserActions.deleteAdmin.failure,
    (state = initialState, { payload }: any) => ({
      ...state,
      loading: false,
      errorMsg: payload.errorMsg,
      success: false,
    })
  );
