import React, { useState } from "react";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Row } from "antd";

const SimplePagination = (props: {
  currentPage: number;
  onChange: Function;
  dataLength: number;
}) => {
  const [isFirstPage, setIsFirstPage] = useState<boolean>(true);

  if (props.dataLength < 10 && isFirstPage) {
    return null;
  } else {
    return (
      <Row
        justify={"end"}
        align={"middle"}
        style={{
          marginTop: 20,
        }}
      >
        <div
          style={{
            height: "25px",
            width: "25px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: props.currentPage === 1 ? "not-allowed" : "pointer",
            backgroundColor: "#fff",
            border: `1px solid #d9d9d9`,
          }}
          onClick={() => {
            if (props.currentPage > 1) {
              setIsFirstPage(false);
              return props.onChange(props.currentPage - 1);
            }
          }}
        >
          <LeftOutlined />
        </div>

        <div
          style={{
            margin: 0,
            borderTop: "1px solid #d9d9d9",
            borderBottom: "1px solid #d9d9d9",
            height: "25px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: 25,
            backgroundColor: "#fff",
          }}
        >
          {props.currentPage}
        </div>
        <div
          style={{
            height: "25px",
            width: "25px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: props.dataLength === 10 ? "pointer" : "not-allowed",
            backgroundColor: "#fff",
            border: `1px solid #d9d9d9`,
          }}
          onClick={() => {
            if (props.dataLength === 10) {
              setIsFirstPage(false);
              props.onChange(props.currentPage + 1);
            }
          }}
        >
          <RightOutlined />
        </div>
      </Row>
    );
  }
};

export default SimplePagination;
