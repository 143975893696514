import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { combineReducers } from "redux";
import { connectRouter, routerMiddleware } from "connected-react-router";
import logger from "redux-logger";

import { history } from "../helpers";
import { rootReducer } from "./reducers";
import rootSaga from "./sagas";
import authSaga from "./auth/auth.saga";

const sagaMiddleware = createSagaMiddleware();
const routeMiddleware = routerMiddleware(history);

// eslint-disable-next-line no-undef
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  combineReducers({
    ...rootReducer,
    router: connectRouter(history),
  }),
  composeEnhancers(applyMiddleware(sagaMiddleware, routeMiddleware, logger))
);

sagaMiddleware.run(rootSaga);

export { history, store };
