const listOfColors = {
  yobenBlue: "#55BBCC",
  yobenBlueTransparent: "rgba(85, 187, 204, 0.1)",
  yobenBlueTransparent2: "rgba(85, 187, 204, 0.2)",
  darkBlue: "#2D688C",
  darkBlueShadow: "rgba(45, 104, 140, 0.1)",
  darkBlueTransparent: "rgba(45, 104, 140, 0.3)",
  darkBlueGrey: "#646C71",

  black: "#313537",
  blackDefault: "#000",
  white: "#FFFFFF",
  red: "#DC7272",
  pink: "pink",
  orange: "#F8AA00",
  orangeTransparent: "rgba(248, 170, 0, 0.1)",
  green: "#70D34E",
  greenTransparent: "rgba(112, 211, 78, 0.1)",

  background: "#FBFDFF",
  inputBorder: "#F2F2F2",
  greyText: "#D0D0D0",
  buttonShadow: "rgba(110, 205, 221, 0.4)",

  pillStatusBg: {
    "Mencari Mitra": "rgba(45, 104, 140, 0.1)",
    "Mitra Didapatkan": "rgba(85, 187, 204, 0.1)",
    "Dalam Perjalanan": "rgba(248, 170, 0.1)",
    Selesai: "rgba(112, 211, 78, 0.1)",
  },
  statusColor: {
    "Mencari Mitra": "#2D688C",
    "Mitra Didapatkan": "#55BBCC",
    "Dalam Perjalanan": "#F8AA00",
    Selesai: "#70D34E",
  },
};

const Colors: typeof listOfColors = listOfColors;

export default Colors;
