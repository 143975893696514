import { all, takeEvery, call, put, fork } from "redux-saga/effects";
import { notification } from "antd";

import * as ServiceActions from "./service.action";
import * as ServiceServices from "../../services/service.service";
import { Http } from "../../const";

export function* getServiceParent() {
  yield takeEvery(
    ServiceActions.getServiceParent.request,
    function* ({ payload }) {
      const result = yield call(
        ServiceServices.getServiceParent,
        payload.areaCode
      );

      if (result.code === 200) {
        yield put(
          ServiceActions.getServiceParent.success({
            services: result.data.results,
          })
        );
      } else {
        yield put(
          ServiceActions.getServiceParent.failure({
            errorMsg: "Ger Service Parent error",
          })
        );
      }
    }
  );
}

export function* getServiceChild() {
  yield takeEvery(
    ServiceActions.getServiceChild.request,
    function* ({ payload }) {
      const result = yield call(
        ServiceServices.getServiceChild,
        payload.parentCode
      );

      if (result.code === 200) {
        yield put(
          ServiceActions.getServiceChild.success({
            service: result.data.results.map((service) => {
              return {
                ...service,
                parentCode: payload.parentCode,
              };
            }),
          })
        );
      } else {
        yield put(
          ServiceActions.getServiceChild.failure({
            errorMsg: "Ger Service Child error",
          })
        );
      }
    }
  );
}

export function* createServiceChild() {
  yield takeEvery(
    ServiceActions.createServiceChild.request,
    function* ({ payload }) {
      const result = yield call(ServiceServices.createServiceChild, payload);
      if (result.code === 200) {
        yield put(
          ServiceActions.createServiceChild.success({
            service: result.data,
          })
        );
        notification.success({
          placement: "topRight",
          bottom: 50,
          duration: 3,
          message: "Create Service Success",
        });
      } else {
        yield put(
          ServiceActions.createServiceChild.failure({
            errorMsg: "Create Service Child error",
          })
        );
      }
    }
  );
}

export function* editServiceChild() {
  yield takeEvery(
    ServiceActions.editServiceChild.request,
    function* ({ payload }) {
      const result = yield call(
        ServiceServices.editServiceChild,
        payload.service
      );
      if (result.code === 200) {
        yield put(
          ServiceActions.editServiceChild.success({
            service: result.data,
          })
        );
        notification.success({
          placement: "topRight",
          bottom: 50,
          duration: 3,
          message: "Edit Service Success",
        });
      } else {
        yield put(
          ServiceActions.editServiceChild.failure({
            errorMsg: "Edit Service Child error",
          })
        );
      }
    }
  );
}

export function* deleteService() {
  yield takeEvery(
    ServiceActions.deleteService.request,
    function* ({ payload }) {
      const result = yield call(ServiceServices.deleteService, payload.service);
      if (result.code === 202) {
        yield put(
          ServiceActions.deleteService.success({
            service: payload.service,
          })
        );
        notification.success({
          placement: "topRight",
          bottom: 50,
          duration: 3,
          message: "Delete Service Success",
        });
      } else {
        yield put(
          ServiceActions.deleteService.failure({
            errorMsg: "Delete Service Child error",
          })
        );
      }
    }
  );
}

export function* getServiceDuration() {
  yield takeEvery(
    ServiceActions.getServiceDuration.request,
    function* ({ payload }) {
      const result = yield call(
        ServiceServices.getServiceDuration,
        payload.serviceCode
      );

      if (result.code === 200) {
        yield put(
          ServiceActions.getServiceDuration.success({
            durations: result.data.results,
          })
        );
      } else {
        yield put(
          ServiceActions.getServiceDuration.failure({
            errorMsg: "Get Service Duration Error",
          })
        );
      }
    }
  );
}

export function* createServiceDuration() {
  yield takeEvery(
    ServiceActions.createServiceDuration.request,
    function* ({ payload }) {
      const result = yield call(ServiceServices.createServiceDuration, payload);

      if (result.code === 200) {
        notification.success({
          placement: "topRight",
          bottom: 50,
          duration: 3,
          message: "Create Service Duration Success",
        });
        yield put(
          ServiceActions.createServiceDuration.success({
            duration: result.data,
          })
        );
      } else {
        yield put(
          ServiceActions.createServiceDuration.failure({
            errorMsg: "Create Service Duration Error",
          })
        );
      }
    }
  );
}

export function* editServiceDuration() {
  yield takeEvery(
    ServiceActions.editServiceDuration.request,
    function* ({ payload }) {
      const result = yield call(ServiceServices.editServiceDuration, payload);
      if (result.code === 200) {
        notification.success({
          placement: "topRight",
          bottom: 50,
          duration: 3,
          message: "Edit Service Duration Success",
        });
        yield put(
          ServiceActions.editServiceDuration.success({
            duration: result.data,
          })
        );
      } else {
        yield put(
          ServiceActions.editServiceDuration.failure({
            errorMsg: "Edit Service Duration Error",
          })
        );
      }
    }
  );
}

export function* removeServiceDuration() {
  yield takeEvery(
    ServiceActions.removeServiceDuration.request,
    function* ({ payload }) {
      const result = yield call(
        ServiceServices.removeServiceDuration,
        payload.durationCode
      );
      if (result.code === 202) {
        notification.success({
          placement: "topRight",
          bottom: 50,
          duration: 3,
          message: "Delete Service Duration Success",
        });
        yield put(
          ServiceActions.removeServiceDuration.success({
            durationCode: payload.durationCode,
          })
        );
      } else {
        yield put(
          ServiceActions.removeServiceDuration.failure({
            errorMsg: "Delete Service Duration Error",
          })
        );
      }
    }
  );
}

export function* getBusinessPartner() {
  yield takeEvery(
    ServiceActions.getBusinessPartner.request,
    function* ({ payload }) {
      const result = yield call(ServiceServices.getBusinessPartner, {
        limit: 10,
        skip: Http.skip(payload.page, 10),
      });

      if (result.code === 200) {
        yield put(
          ServiceActions.getBusinessPartner.success({
            businessPartners: result.data.results,
          })
        );
      } else {
        yield put(
          ServiceActions.getBusinessPartner.failure({
            errorMsg: "Get Business Partner error",
          })
        );
      }
    }
  );
}

export function* getSelfBusinessPartner() {
  yield takeEvery(
    ServiceActions.getSelfBusinessPartner.request,
    function* () {
      const result = yield call(ServiceServices.getSelfBusinessPartner);

      if (result.code === 200) {
        yield put(
          ServiceActions.getSelfBusinessPartner.success(result.data)
        );
      } else {
        yield put(
          ServiceActions.getSelfBusinessPartner.failure({
            errorMsg: "Get Business Partner error",
          })
        );
      }
    }
  );
}

export function* registerBusinessPartner() {
  yield takeEvery(
    ServiceActions.registerBusinessPartner.request,
    function* ({ payload }) {
      const result = yield call(
        ServiceServices.registerBusinessPartner,
        payload
      );

      if (result.code === 200) {
        notification.success({
          placement: "topRight",
          bottom: 50,
          duration: 3,
          message: "Register Business Partner Success",
        });
        yield put(ServiceActions.registerBusinessPartner.success(result.data));
      } else {
        yield put(
          ServiceActions.getServiceChild.failure({
            errorMsg: "Resister Business Partner Error",
          })
        );
      }
    }
  );
}

export function* editBusinessPartner() {
  yield takeEvery(
    ServiceActions.editBusinessPartner.request,
    function* ({ payload }) {
      const result = yield call(
        ServiceServices.editBusinessPartner,
        payload.businessPartner,
        payload.businessPartnerCode
      );

      if (result.code === 200) {
        notification.success({
          placement: "topRight",
          bottom: 50,
          duration: 3,
          message: "Edit Business Partner Success",
        });
        yield put(ServiceActions.editBusinessPartner.success(result.data));
      } else {
        yield put(
          ServiceActions.getServiceChild.failure({
            errorMsg: "Edit Business Partner Error",
          })
        );
      }
    }
  );
}

export function* deleteBusinessPartner() {
  yield takeEvery(
    ServiceActions.deleteBusinessPartner.request,
    function* ({ payload }) {
      const result = yield call(
        ServiceServices.deleteBusinessPartner,
        payload.businessPartnerCode
      );

      if (result.code === 202) {
        notification.success({
          placement: "topRight",
          bottom: 50,
          duration: 3,
          message: "Delete Business Partner Success",
        });
        yield put(
          ServiceActions.deleteBusinessPartner.success({
            businessPartnerCode: payload.businessPartnerCode,
          })
        );
      } else {
        yield put(
          ServiceActions.getServiceChild.failure({
            errorMsg: "Edit Business Partner Error",
          })
        );
      }
    }
  );
}

// PROMOTION - SAGA
export function* getPromotions() {
  yield takeEvery(ServiceActions.getPromotion.request, function* () {
    const result = yield call(ServiceServices.getPromotions);

    if (result.code === 200) {
      yield put(ServiceActions.getPromotion.success(result.data.results));
    } else {
      yield put(
        ServiceActions.getPromotion.failure({
          errorMsg: "Get Promotions error",
        })
      );
    }
  });
}

export function* createPromotion() {
  yield takeEvery(
    ServiceActions.createPromotion.request,
    function* ({ payload }) {
      const result = yield call(ServiceServices.createPromotion, payload);
      if (result.code === 200) {
        notification.success({
          placement: "topRight",
          bottom: 50,
          duration: 3,
          message: "Create Promotion Success",
        });
        yield put(ServiceActions.createPromotion.success(result.data));
      } else {
        yield put(
          ServiceActions.createPromotion.failure({
            errorMsg: "Create Promotion error",
          })
        );
      }
    }
  );
}

export function* editPromotion() {
  yield takeEvery(
    ServiceActions.editPromotion.request,
    function* ({ payload }) {
      const result = yield call(ServiceServices.editPromotion, payload);

      if (result.code === 200) {
        notification.success({
          placement: "topRight",
          bottom: 50,
          duration: 3,
          message: "Edit Promotion Success",
        });
        yield put(ServiceActions.editPromotion.success(result.data));
      } else {
        yield put(
          ServiceActions.editPromotion.failure({
            errorMsg: "Edit Promotion error",
          })
        );
      }
    }
  );
}

export function* deletePromotion() {
  yield takeEvery(
    ServiceActions.deletePromotion.request,
    function* ({ payload }) {
      const result = yield call(
        ServiceServices.deletePromotion,
        payload.promotionCode
      );

      if (result.code === 202) {
        notification.success({
          placement: "topRight",
          bottom: 50,
          duration: 3,
          message: "Delete Promotion Success",
        });
        yield put(
          ServiceActions.deletePromotion.success({
            promotionCode: payload.promotionCode,
          })
        );
      } else {
        yield put(
          ServiceActions.deletePromotion.failure({
            errorMsg: "Delete Promotion error",
          })
        );
      }
    }
  );
}

// COUPON - SAGA
export function* getCoupon() {
  yield takeEvery(ServiceActions.getCoupon.request, function* ({ payload }) {
    const result = yield call(ServiceServices.getCoupon, {
      limit: 10,
      skip: Http.skip(payload.page),
    });

    if (result.code === 200) {
      yield put(ServiceActions.getCoupon.success(result.data.results));
    } else {
      yield put(
        ServiceActions.getCoupon.failure({
          errorMsg: "Get areas error",
        })
      );
    }
  });
}

export function* createCoupon() {
  yield takeEvery(ServiceActions.createCoupon.request, function* ({ payload }) {
    const result = yield call(ServiceServices.createCoupon, payload);

    if (result.code === 200) {
      yield put(ServiceActions.createCoupon.success(result.data));
      notification.success({
        placement: "topRight",
        bottom: 50,
        duration: 3,
        message: "Create Coupon Success",
      });
    } else {
      yield put(
        ServiceActions.createCoupon.failure({
          errorMsg: "Create Coupon error",
        })
      );
    }
  });
}

export function* editCoupon() {
  yield takeEvery(ServiceActions.editCoupon.request, function* ({ payload }) {
    const result = yield call(ServiceServices.editCoupon, payload);

    if (result.code === 200) {
      yield put(ServiceActions.editCoupon.success(result.data));
      notification.success({
        placement: "topRight",
        bottom: 50,
        duration: 3,
        message: "Edit Coupon Success",
      });
    } else {
      yield put(
        ServiceActions.editCoupon.failure({
          errorMsg: "Edit Coupon error",
        })
      );
    }
  });
}

export function* deleteCoupon() {
  yield takeEvery(ServiceActions.deleteCoupon.request, function* ({ payload }) {
    const result = yield call(ServiceServices.deleteCoupon, payload);

    if (result.code === 202) {
      yield put(ServiceActions.deleteCoupon.success(payload));
      notification.success({
        placement: "topRight",
        bottom: 50,
        duration: 3,
        message: "Delete Coupon Success",
      });
    } else {
      yield put(
        ServiceActions.deleteCoupon.failure({
          errorMsg: "Delete Coupon error",
        })
      );
    }
  });
}

export function* getArea() {
  yield takeEvery(ServiceActions.getArea.request, function* () {
    const result = yield call(ServiceServices.getAreas);

    if (result.code === 200) {
      yield put(ServiceActions.getArea.success(result.data.results));
    } else {
      yield put(
        ServiceActions.getArea.failure({
          errorMsg: "Get areas error",
        })
      );
    }
  });
}

export default function* rootSaga() {
  yield all([fork(getServiceParent)]);
  yield all([fork(deleteService)]);

  yield all([fork(getServiceChild)]);
  yield all([fork(createServiceChild)]);
  yield all([fork(editServiceChild)]);

  yield all([fork(createServiceDuration)]);
  yield all([fork(getServiceDuration)]);
  yield all([fork(editServiceDuration)]);
  yield all([fork(removeServiceDuration)]);

  yield all([fork(getBusinessPartner)]);
  yield all([fork(getSelfBusinessPartner)]);
  yield all([fork(registerBusinessPartner)]);
  yield all([fork(editBusinessPartner)]);
  yield all([fork(deleteBusinessPartner)]);
  // promotion
  yield all([fork(getPromotions)]);
  yield all([fork(createPromotion)]);
  yield all([fork(editPromotion)]);
  yield all([fork(deletePromotion)]);

  yield all([fork(getCoupon)]);
  yield all([fork(createCoupon)]);
  yield all([fork(editCoupon)]);
  yield all([fork(deleteCoupon)]);

  yield all([fork(getArea)]);
}
