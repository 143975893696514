import AdminSetting from "../../pages/settings/admin";
import PromotionSetting from "../../pages/settings/promotion";
import ServiceSetting from "../../pages/settings/service";
import BusinessPartners from "../../pages/settings/businessPartners";
import MarketingTool from "../../pages/settings/marketingTool";
import ServiceEachArea from "../../pages/settings/service/serviceEachArea";

const baseSettingUrl = "settings";
const settingRouteAdmin = [
  {
    path: `${baseSettingUrl}/business-partner`,
    component: BusinessPartners,
  },
  {
    path: `${baseSettingUrl}/admin`,
    component: AdminSetting,
  },
  {
    path: `${baseSettingUrl}/marketing-tool`,
    component: MarketingTool,
  },
  {
    path: `${baseSettingUrl}/marketing-tool/promotion`,
    component: PromotionSetting,
  },
  {
    path: `${baseSettingUrl}/service`,
    component: ServiceSetting,
  },
  {
    path: `${baseSettingUrl}/service/:areaCode`,
    component: ServiceEachArea,
  },
];

const settingRouteBusinessPartner = [];

let settingRoute =
  localStorage.getItem("role") === "admin"
    ? settingRouteAdmin
    : settingRouteBusinessPartner;

export default settingRoute;
