const customFetch = async (url: any, method: any, reqBody?: any) => {
  const headers: any = {};
  if (!url.includes("login")) {
    headers.Authorization = localStorage.getItem("token");
  }

  headers["content-type"] = "application/json; charset=utf-8";

  try {
    const makeRequest = await fetch(url, {
      method: method || "GET",
      body: JSON.stringify(reqBody),
      mode: "cors",
      headers,
    });

    const response = await makeRequest.json();

    let message: string;
    if (response.code === 401) {
      localStorage.clear();
      window.location.replace("/login");
      message = "unauthorized";
    } else {
      message = response.message;
    }

    const data = {
      code: response.code,
      data: response.data,
      message: response.message ? response.message : response.validation,
    };

    return data;
  } catch (err) {
    throw err;
  }
};
export default customFetch;
