import { faPray } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

import {Colors} from '../../../const'

const Style = styled.div`
  img {
    max-width: 100%;
  }
  .section-edit-button {
    margin-top: 20px;
  }

  .ant-spin-container {
    background: white;
  }

  .ant-input[disabled] {
    color: ${Colors.black};
    background-color: transparent;
  }

  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector{
    color: ${Colors.black};
    background-color: transparent;
  }
`;

export { Style };
