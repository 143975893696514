/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncAction } from "typesafe-actions";
import { UserActionType } from "./user.actionType";
import { IAdmin } from "./user.interface";

export const getAdmin = createAsyncAction(
  UserActionType.GetAdmin,
  UserActionType.GetAdminSuccess,
  UserActionType.GetAdminError
)<
  {
    page: number;
  },
  IAdmin[],
  any
>();

export const createAdmin = createAsyncAction(
  UserActionType.CreateAdmin,
  UserActionType.CreateAdminSuccess,
  UserActionType.CreateAdminError
)<IAdmin, IAdmin, any>();

export const editAdmin = createAsyncAction(
  UserActionType.EditAdmin,
  UserActionType.EditAdminSuccess,
  UserActionType.EditAdminError
)<IAdmin, IAdmin, any>();

export const deleteAdmin = createAsyncAction(
  UserActionType.DeleteAdmin,
  UserActionType.DeleteAdminSuccess,
  UserActionType.DeleteAdminError
)<{ adminId: string }, { adminId: string }, any>();
