import { all, takeEvery, call, put, fork } from "redux-saga/effects";
import { notification } from "antd";
import { push } from "react-router-redux";

import * as AuthServices from "../../services/auth.service";
import * as AuthActions from "./auth.action";

export function* loginRequest() {
  yield takeEvery(AuthActions.loginPassword.request, function* ({ payload }) {
    const result = yield call(AuthServices.login, {
      phone: payload.username,
      password: payload.password,
    });

    if (result.code === 200) {
      yield put(
        AuthActions.loginPassword.success({
          email: result.data.email,
          id: String(result.data.id),
          name: result.data.name,
          phone: result.data.phone,
          businessPartnerCode: result.data.code ? result.data.code : "",
        })
      );

      yield localStorage.setItem("token", result.token);
      yield localStorage.setItem("email", result.data.email);
      yield localStorage.setItem("id", result.data.id);
      yield localStorage.setItem("name", result.data.name);
      yield localStorage.setItem("phone", result.data.phone);
      yield localStorage.setItem("role", process.env.REACT_APP_ROLE);
      yield put(push("/dashboard"));
    } else {
      yield put(
        AuthActions.loginPassword.failure({
          message: "Invalid username or password",
        })
      );
      notification.error({
        placement: "topRight",
        bottom: 50,
        duration: 3,
        message: "Invalid username or password",
      });
    }
  });
}

export default function* rootSaga() {
  yield all([fork(loginRequest)]);
}
