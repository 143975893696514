import { createReducer } from "typesafe-actions";
import { IOrderReducer } from "./order.interface";

import * as OrderActions from "./order.action";
import { OrderActionType } from "./order.actionType";
import { removeOne } from "../../helpers";

const initialState: IOrderReducer = {
  orders: [],
  unassignedOrders: [],
  availableMitras: [],
  transactions: [],
  loading: false,
  errorMsg: "",
  success: false,
  lazyLoad: {
    transaction: false,
    balance: false,
  }
};

export default createReducer(initialState)
  .handleAction(OrderActions.getOrder.request, (state = initialState) => {
    return {
      ...state,
      loading: true,
    };
  })
  .handleAction(
    OrderActions.getOrder.success,
    (state = initialState, { payload }: any) => {
      if (payload.orderStatus === "looking_for_mitra") {
        return {
          ...state,
          loading: false,
          unassignedOrders: payload.order,
        };
      } else {
        return {
          ...state,
          orders: payload.order,
          loading: false,
        };
      }
    }
  )
  .handleAction(OrderActions.getOrderDetail.request, (state = initialState) => {
    return {
      ...state,
      loading: true,
    };
  })
  .handleAction(
    OrderActions.getOrderDetail.success,
    (state = initialState, { payload }: any) => {
      let newOrderList = [...state.orders];

      let index = newOrderList.findIndex(
        (order) => order.code === payload.order.code
      );

      if (index === -1) {
        newOrderList.push(payload.order);
      } else {
        newOrderList[index] = payload.order;
      }
      return {
        ...state,
        orders: newOrderList,
        loading: false,
      };
    }
  )
  .handleAction(
    OrderActions.getMitraAvailable.request,
    (state = initialState) => {
      return {
        ...state,
        loading: true,
      };
    }
  )
  .handleAction(
    OrderActions.getMitraAvailable.success,
    (state = initialState, { payload }: any) => {
      return {
        ...state,
        availableMitras: payload.availableMitras,
        loading: false,
      };
    }
  )
  .handleAction(OrderActions.assignMitra.request, (state = initialState) => {
    return {
      ...state,
      loading: true,
      success: false,
    };
  })
  .handleAction(
    OrderActions.assignMitra.success,
    (state = initialState, { payload }: any) => {
      let newUnassignedOrders = [...state.unassignedOrders];

      let index = newUnassignedOrders.findIndex(
        (order) => order.code === payload.orderCode
      );

      if (index > -1) {
        newUnassignedOrders.splice(index, 1);
      }

      return {
        ...state,
        unassignedOrders: newUnassignedOrders,
        loading: false,
        success: true,
      };
    }
  )
  .handleAction(OrderActions.cancelOrder.request, (state = initialState) => {
    return {
      ...state,
      loading: true,
      success: false,
    };
  })
  .handleAction(
    OrderActions.cancelOrder.success,
    (state = initialState, { payload }) => {
      if (payload.source === 'unassignedOrders') {
        return {
          ...state,
          unassignedOrders: removeOne(state.unassignedOrders, payload.orderCode),
          loading: false,
          success: true
        }
      } else {
        let newOrders = [...state[payload.source]];
        let index = newOrders.findIndex(order => order.code === payload.orderCode);
        newOrders[index].status = 'cancel';

        return {
          ...state,
          [payload.sources]: newOrders,
          loading: false,
          success: true
        }
      }
    }
  )
  .handleAction(
    OrderActions.getMitraTransaction.request,
    (state = initialState) => {
      return {
        ...state,
        loading: true,
      };
    }
  )
  .handleAction(
    OrderActions.getMitraTransaction.success,
    (state = initialState, { payload }) => {
      return {
        ...state,
        transactions: payload,
        loading: false,
      };
    }
  )
  .handleAction(
    OrderActions.postTransaction.request,
    (state = initialState) => {
      return {
        ...state,
        success: false,
        lazyLoad: {
          ...state.lazyLoad,
          transaction: true,
        }
      };
    }
  )
  .handleAction(
    OrderActions.postTransaction.success,
    (state = initialState, { payload }) => {
      return {
        ...state,
        transactions: state.transactions.unshift(payload),
        success: true,
        lazyLoad: {
          ...state.lazyLoad,
          transaction: false,
        }
      };
    }
  )
  .handleAction(
    OrderActionType.OrderError,
    (
      state = initialState,
      action: {
        payload: {
          errorMsg: string;
        };
      }
    ) => ({
      ...state,
      loading: false,
      success: false,
      errorMsg: action.payload.errorMsg,
      lazyLoad: {
        balance: false,
        transaction: false
      }
    })
  );
