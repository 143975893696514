import styled from "styled-components";

const ProfileImage = styled.div`
  height: 50px;
  width: 50px;

  img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
    object-position: 50% 0%;
  }
`;

export { ProfileImage };
