export enum ServiceActionType {
  ServiceError = "[SERVICE] ACTION ERROR",
  SetParentCode = "[SERVICE] SET PARENT CODE",

  // SERVICE
  GetServiceParent = "[SERVICE] GET SERVICE PARENT",
  GetServiceParentSuccess = "[SERVICE] GET SERVICE PARENT SUCCESS",

  DeleteService = "[SERVICE] DELETE SERVICE",
  DeleteServiceSuccess = "[SERVICE] DELETE SERVICE SUCCESS",

  GetServiceChild = "[SERVICE] GET SERVICE CHILD",
  GetServiceChildSuccess = "[SERVICE] GET SERVICE CHILD SUCCESS",

  CreateServiceChild = "[SERVICE] CREATE SERVICE CHILD",
  CreateServiceChildSuccess = "[SERVICE] CREATE SERVICE CHILD SUCCESS",

  EditServiceChild = "[SERVICE] EDIT SERVICE CHILD",
  EditServiceChildSuccess = "[SERVICE] EDIT SERVICE CHILD SUCCESS",

  // SERVICE DURATION
  GetServiceDuration = "[SERVICE] GET SERVICE DURATION",
  GetServiceDurationSuccess = "[SERVICE] GET SERVICE DURATION SUCCESS",

  CreateServiceDuration = "[SERVICE] CREATE SERVICE DURATION",
  CreateServiceDurationSuccess = "[SERVICE] CREATE SERVICE DURATION SUCCESS",

  EditServiceDuration = "[SERVICE] EDIT SERVICE DURATION",
  EditServiceDurationSuccess = "[SERVICE] EDIT SERVICE DURATION SUCCESS",

  RemoveServiceDuration = "[SERVICE] REMOVE SERVICE DURATION",
  RemoveServiceDurationSuccess = "[SERVICE] REMOVE SERVICE DURATION SUCCESS",

  // BUSINESS PARTNER
  GetBusinessPartner = "[SERVICE] GET BUSINESS PARTNER",
  GetBusinessPartnerSuccess = "[SERVICE] GET BUSINESS PARTNER SUCCESS",

  GetSelfBusinessPartner = "[SERVICE] GET SELF BUSINESS PARTNER",
  GetSelfBusinessPartnerSuccess = "[SERVICE] GET SELF BUSINESS PARTNER SUCCESS",

  RegisterBusinessPartner = "[SERVICE] REGISTER BUSINESS PARTNER",
  RegisterBusinessPartnerSuccess = "[SERVICE] REGISTER BUSINESS PARTNER SUCCESS",

  EditBusinessPartner = "[SERVICE] EDIT BUSINESS PARTNER",
  EditBusinessPartnerSuccess = "[SERVICE] EDIT BUSINESS PARTNER SUCCESS",

  DeleteBusinessPartner = "[SERVICE] DELETE BUSINESS PARTNER",
  DeleteBusinessPartnerSuccess = "[SERVICE] DELETE BUSINESS PARTNER SUCCESS",

  // PROMOTION
  GetPromotions = "[SERVICE] GET PROMOTIONS",
  GetPromotionsSuccess = "[SERVICE] GET PROMOTIONS SUCCESS",

  EditPromotion = "[SERVICE] EDIT PROMOTION",
  EditPromotionSuccess = "[SERVICE] EDIT PROMOTION SUCCESS",

  CreatePromotion = "[SERVICE] CREATE PROMOTION",
  CreatePromotionSuccess = "[SERVICE] CREATE PROMOTION SUCCESS",

  DeletePromotion = "[SERVICE] DELETE PROMOTION",
  DeletePromotionSuccess = "[SERVICE] DELETE PROMOTION SUCCESS",

  // COUPON
  GetCoupon = "[SERVICE] GET COUPONS",
  GetCouponSuccess = "[SERVICE] GET COUPONS SUCCESS",

  CreateCoupon = "[SERVICE] CREATE COUPON",
  CreateCouponSuccess = "[SERVICE] CREATE COUPON SUCCESS",

  EditCoupon = "[SERVICE] EDIT COUPON",
  EditCouponSuccess = "[SERVICE] EDIT COUPON SUCCESS",

  DeleteCoupon = "[SERVICE] DELETE COUPON",
  DeleteCouponSuccess = "[SERVICE] DELETE COUPON SUCCESS",
  // AREA
  GetAreas = "[SERVICE] GET AREAS",
  GetAreasSuccess = "[SERVICE] GET AREAS SUCCESS",

  EditArea = "[SERVICE] EDIT AREA",
  EditAreaSuccess = "[SERVICE] EDIT AREA SUCCESS",

  CreateArea = "[SERVICE] CREATE AREA",
  CreateAreaSuccess = "[SERVICE] CREATE AREA SUCCESS",

  DeleteArea = "[SERVICE] DELETE AREA",
  DeleteAreaSuccess = "[SERVICE] DELETE AREA SUCCESS",
}
