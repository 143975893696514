import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  Row,
  Spin,
  Button,
  Form,
  Input,
  Modal,
  Select,
  Popconfirm,
} from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

import * as ServiceActions from "../../../state/service/service.action";
import * as ServiceSelector from "../../../state/service/service.selector";
import {
  IParentService,
  IPromotion,
  IService,
} from "../../../state/service/service.interface";
import { Layout } from "../../../const";

const PromotionSetting = () => {
  const dispatch = useDispatch();

  const isLoading = useSelector(ServiceSelector.selectLoading);
  const isSuccess = useSelector(ServiceSelector.selectSuccess);

  const promotions = useSelector(ServiceSelector.selectPromotions);
  const parentServices = useSelector(ServiceSelector.selectParentServices);
  const childServices = useSelector(ServiceSelector.selectChildServices);

  const [currentParentCode, setCurrentParentCode] = useState<string>("");
  const [currentPromotion, setCurrentPromotion] = useState<IPromotion>({
    code: "",
    name: "",
    imageUrl: "",
    description: "",
    serviceCode: "",
  });
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(ServiceActions.getPromotion.request());
    dispatch(
      ServiceActions.getServiceParent.request({
        areaCode: "AO-KGW6DUAP.FSL",
      })
    );
  }, []);

  useEffect(() => {
    setIsModalOpen(false);
  }, [isSuccess]);

  const handleFormPromotion = (value) => {
    if (isEdit) {
      dispatch(
        ServiceActions.editPromotion.request({
          ...value,
          code: currentPromotion.code,
        })
      );
    } else {
      dispatch(ServiceActions.createPromotion.request(value));
    }
  };

  const onChangeParentService = (parentCode: string) => {
    dispatch(
      ServiceActions.getServiceChild.request({
        parentCode: parentCode,
      })
    );
    // dispatch(ServiceActions.setParentCode(parentCode));
    setCurrentParentCode(parentCode);
  };

  const renderModalPromotion = () => {
    return (
      <Form
        onFinish={handleFormPromotion}
        form={form}
        {...Layout.formItem}
        id="promotionForm"
      >
        <Modal
          title={`${isEdit ? "Edit Promotion" : "Create New Promotion"}`}
          visible={isModalOpen}
          onCancel={() => {
            setIsModalOpen(false);
            form.resetFields();
          }}
          footer={[
            // <Button
            //   style={{
            //     display: isEdit ? "" : "none",
            //   }}
            //   danger
            //   loading={isLoading}
            //   onClick={() => onClickDelete()}
            // >
            //   Delete
            // </Button>,
            <Button
              key="submit"
              type="primary"
              loading={isLoading}
              htmlType="submit"
              form="promotionForm"
            >
              {/* {isEdit ? "Edit" : "Submit"} */}
              Submit
            </Button>,
          ]}
        >
          <Form.Item
            label="Nama"
            name="name"
            rules={[{ required: true }]}
            hasFeedback
          >
            <Input placeholder={"name"} />
          </Form.Item>
          <Form.Item
            label="Promotion Image"
            name="imageUrl"
            rules={[{ required: true }, { type: "url" }]}
            hasFeedback
          >
            <Input type="url" placeholder={"promotion image"} />
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
            rules={[{ required: true }]}
            hasFeedback
          >
            <Input placeholder={"description"} />
          </Form.Item>

          <Form.Item label="Produk">
            <Select
              showSearch
              placeholder="Pilih Produk"
              optionFilterProp="children"
              onChange={onChangeParentService}
            >
              {parentServices.map((parentService: IParentService) => {
                return (
                  <Select.Option value={parentService.code}>
                    {parentService.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item label="Layanan" name="serviceCode">
            <Select
              showSearch
              placeholder="Pilih Layanan"
              optionFilterProp="children"
            >
              {childServices
                .filter(
                  (childService: IService) =>
                    childService.parentCode === currentParentCode
                )
                .map((childService: IService) => {
                  return (
                    <Select.Option value={childService.code}>
                      {childService.name}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Modal>
      </Form>
    );
  };

  const onClickDelete = (promotion: IPromotion) => {
    dispatch(
      ServiceActions.deletePromotion.request({
        promotionCode: promotion.code,
      })
    );
  };

  const onClickEdit = (promotion: IPromotion) => {
    setIsEdit(true);
    setIsModalOpen(true);
    setCurrentPromotion(promotion);

    form.setFieldsValue({
      name: promotion.name,
      imageUrl: promotion.imageUrl,
      description: promotion.description,
      serviceCode: promotion.serviceCode,
      code: promotion.code,
    });
  };

  return (
    <div
      style={{
        marginBottom: 20,
      }}
    >
      <Row justify="space-between">
        <h1>Promotion List</h1>
        <Button
          onClick={() => {
            setIsModalOpen(true);
            // setIsEdit(false);
          }}
        >
          Create Promotion
        </Button>
      </Row>
      {isLoading ? (
        <Row
          justify="center"
          style={{
            height: 300,
          }}
        >
          <Spin size="large" />
        </Row>
      ) : (
        <Row
          wrap={false}
          style={{
            overflowX: "auto",
          }}
        >
          {promotions.map((promotion: IPromotion) => {
            return (
              <Card
                style={{ width: 300, marginRight: "20px" }}
                cover={
                  <img
                    style={{
                      height: "200px",
                      width: "300px",
                    }}
                    alt="example"
                    src={promotion.imageUrl}
                  />
                }
                actions={[
                  <EditOutlined
                    onClick={() => onClickEdit(promotion)}
                    key="edit"
                  />,
                  <Popconfirm
                    title="Sure to delete?"
                    onConfirm={() => onClickDelete(promotion)}
                  >
                    <DeleteOutlined key="delete" />
                  </Popconfirm>,
                ]}
              >
                <Card.Meta
                  title={promotion.name}
                  description={promotion.description}
                />
                <p
                  style={{
                    marginTop: 10,
                  }}
                >
                  Layanan :{" "}
                  {
                    childServices.find(
                      (service: IService) =>
                        service.code == promotion.serviceCode
                    )?.name
                  }
                </p>
              </Card>
            );
          })}
        </Row>
      )}
      {renderModalPromotion()}
    </div>
  );
};

export default PromotionSetting;
