import { customFetch } from "../helpers";

export const login = async (payload) => {
  if (process.env.REACT_APP_ROLE === "businessPartner") {
    try {
      const response = await customFetch(
        `${process.env.REACT_APP_ACCOUNT_API}/api/v1/business-partner/login`,
        "POST",
        payload
      );

      if (response.code === 200) {
        response.data.busPart.id = String(response.data.busPart.id);
        return {
          code: response.code,
          data: response.data.busPart,
          message: response.message,
          token: response.data.token,
        };
      } else {
        return response;
      }
    } catch (error) {
      throw error;
    }
  } else if (process.env.REACT_APP_ROLE === "admin") {
    try {
      const response = await customFetch(
        `${process.env.REACT_APP_ACCOUNT_API}/api/v1/admin/login`,
        "POST",
        payload
      );

      if (response.code === 200) {
        response.data.admin.id = String(response.data.admin.id);

        return {
          code: response.code,
          data: response.data.admin,
          message: response.message,
          token: response.data.token,
        };
      } else {
        return response;
      }
    } catch (error) {
      throw error;
    }
  }
};
