import React from "react";
import { Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";

import { Customers, Dashboard } from "../../pages";
import Orders from "../../pages/orders";
import { history } from "../../helpers";
import settingRoute from "./setting.route";
import mitraRoute from "./mitra.route";

const routes = [
  {
    path: "dashboard",
    component: Dashboard,
  },
  {
    path: "orders",
    component: Orders,
  },
  {
    path: "customers",
    component: Customers,
  },

  ...mitraRoute,
  ...settingRoute,
];

function AppRouter(props: any) {
  return (
    <ConnectedRouter history={history}>
      <Switch>
        {routes.map((route: any) => {
          const { path, exact, component } = route;
          return (
            <Route
              exact={exact !== false}
              key={path}
              path={`${props.url}${path}`}
              component={component}
            />
          );
        })}
      </Switch>
    </ConnectedRouter>
  );
}

export default AppRouter;
