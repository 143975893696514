import { createSelector } from "reselect";

import { IService, IServiceReducer } from "./service.interface";

const selectServiceReducer = (state: any) => state.service;

const selectLoading = createSelector(
  selectServiceReducer,
  (serviceReducer: IServiceReducer) => serviceReducer.loading
);

const selectSuccess = createSelector(
  selectServiceReducer,
  (serviceReducer: IServiceReducer) => serviceReducer.success
);

const selectFilterParentCode = createSelector(
  selectServiceReducer,
  (serviceReducer: IServiceReducer) => serviceReducer.filterParentCode
);
const selectParentServices = createSelector(
  selectServiceReducer,
  (serviceReducer: IServiceReducer) => serviceReducer.parentServices
);

const selectChildServices = createSelector(
  selectServiceReducer,
  (serviceReducer: IServiceReducer) => serviceReducer.childServices
);

const selectAllServices = createSelector(
  selectParentServices,
  selectChildServices,
  (parentServices: IService[], childServices: IService[]) => {
    return [...parentServices, ...childServices]
  }
)

const selectServiceChildByParent = createSelector(
  selectChildServices,
  selectFilterParentCode,
  (childServices: any[], parentCode: string) => {
    return childServices.filter((service) => service.parentCode === parentCode);
  }
);

const selectServiceDuration = createSelector(
  selectServiceReducer,
  (serviceReducer: IServiceReducer) => serviceReducer.serviceDurations
);

const selectBusinessPartners = createSelector(
  selectServiceReducer,
  (serviceReducer: IServiceReducer) => serviceReducer.businessPartners
);

const selectPromotions = createSelector(
  selectServiceReducer,
  (serviceReducer: IServiceReducer) => serviceReducer.promotions
);

const selectCoupons = createSelector(
  selectServiceReducer,
  (serviceReducer: IServiceReducer) => serviceReducer.coupons
);
const selectAreas = createSelector(
  selectServiceReducer,
  (serviceReducer: IServiceReducer) => serviceReducer.areas
);

export {
  selectLoading,
  selectChildServices,
  selectParentServices,
  selectAllServices,
  selectServiceChildByParent,
  selectServiceDuration,
  selectBusinessPartners,
  selectSuccess,
  selectPromotions,
  selectCoupons,
  selectAreas,
  selectFilterParentCode,
};
