import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Collapse,
  Row,
  Col,
  Form,
  Input,
  Button,
  Select,
  DatePicker,
} from "antd";
import moment from "moment";
import { InboxOutlined } from '@ant-design/icons';
import { UploadFile } from 'antd/lib/upload/interface';

import * as MitraActions from "../../../state/mitra/mitra.action";
import * as MitraSelector from "../../../state/mitra/mitra.selector";
import * as ServiceActions from "../../../state/service/service.action";
import { Style } from "./style";
import { selectBusinessPartners } from "../../../state/service/service.selector";
import {UploadImage, CustomImage} from '../../../components'

const MitraRegistration = (props: any) => {
  const { Option } = Select;
  const { Panel } = Collapse;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const loadingMitra = useSelector(MitraSelector.selectLoading);
  const businessPartners = useSelector(selectBusinessPartners);

  const [images, setImages] = useState({
    image: [],
    ktpImage: [],
    kkImage: [],
    skckImage: [],
  })
  const [imageBase64, setImageBase64] = useState({
    image: '',
    ktpImage: '',
    kkImage: '',
    skckImage: '',
  })


  function onChange(value: any) {
    console.log(`selected ${value}`);
  }

  const onFinish = (values: any) => {
    values.dob = moment(values.dob).format("YYYY-MM-DD");
    dispatch(MitraActions.registerMitra.request(values));
  };

  useEffect(() => {
    form.setFieldsValue({
      image: imageBase64.image,
      ktpImage: imageBase64.ktpImage,
      kkImage:  imageBase64.kkImage,
      skckImage:  imageBase64.skckImage,
      longitude: "10.0",
      latitude: "20.0",
    });
    if (localStorage.getItem("role") === "admin") {
      dispatch(
        ServiceActions.getBusinessPartner.request({
          page: 1,
        })
      );
    }
  }, []);

  const setStateFileList = (fileList: UploadFile[], name: string) => {
    // eslint-disable-next-line no-undef
    return new Promise(async (resolve) => {
      await setImages({
        ...images,
        [name]: fileList,
      });
      await resolve()
    });
  };

  const sliceFileList = (name: string) => {
    setImages({
      ...images,
      [name]: images[name].splice(0, 1),
    });
  };

  const setStateForm = (result: string | ArrayBuffer | null, name: string) => {
    setImageBase64({
      ...imageBase64,
      [name]: result    
    });
    form.setFieldsValue({
      [name]: result,
    });

  };

  const deleteImage = (name) => {
    if (name === 'image') {
      setImages({...images, image: []})
      setImageBase64({...imageBase64, image: ''})
      form.setFieldsValue({
        image: '',
      });
    } else if (name === 'ktpImage') {
      setImages({...images, ktpImage: []})
      setImageBase64({...imageBase64, ktpImage: ''})
      form.setFieldsValue({
        ktpImage: '',
      });
    } else if (name === 'kkImage') {
      setImages({...images, kkImage: []})
      setImageBase64({...imageBase64, kkImage: ''})
      form.setFieldsValue({
        kkImage:  imageBase64.kkImage,
      });
    } else {
      setImages({...images, skckImage: []})
      setImageBase64({...imageBase64, skckImage: ''})
      form.setFieldsValue({
        skckImage:  imageBase64.skckImage,
      });
    }
    
  }
  return (
    <Style>
      <Form layout={"vertical"} form={form} onFinish={onFinish}>
        <Collapse defaultActiveKey={["1", "2", "3", "4"]}>
          <Panel header="Data Diri" key="1">
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item name="image">
                  {
                    imageBase64.image && imageBase64.image && imageBase64.image !== ''
                    ? (
                      <CustomImage
                        image={
                          imageBase64.image
                        }
                        command={() => deleteImage('image')}
                      />
                    ) : (
                      <UploadImage
                        setStateFileList={ setStateFileList }
                        sliceFileList={ sliceFileList }
                        setStateForm={ setStateForm }
                        name='image'
                        fileList={ images.image }
                        height='300px'
                        accept='.png,.jpg'
                        icon={ <InboxOutlined style={{fontSize: '24px'}}/> }
                      />
                    )
                  }
                 
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  rules={[{ required: true }]}
                  required={true}
                  name="name"
                  label="Nama Mitra"
                >
                  <Input placeholder="input nama mitra" />
                </Form.Item>

                <Form.Item
                  rules={[{ required: true }]}
                  required={true}
                  name="phone"
                  label="Nomor telepon"
                >
                  <Input placeholder="input nomor telepon" type="number" />
                </Form.Item>
                <Form.Item
                  rules={[{ required: true }]}
                  required={true}
                  name="dob"
                  label="Tanggal Lahir"
                >
                  <DatePicker
                    onChange={onChange}
                    defaultPickerValue={moment().subtract(25, "years")}
                    disabledDate={(current) => {
                      return current > moment().subtract(18, "years");
                    }}
                  />
                </Form.Item>
                <Form.Item
                  rules={[{ required: true }]}
                  required={true}
                  name="gender"
                  label="Gender"
                >
                  <Select
                    showSearch
                    style={{ width: 200 }}
                    placeholder="Pilih gender"
                    optionFilterProp="children"
                    onChange={onChange}
                  >
                    <Option value="m">Laki-laki</Option>
                    <Option value="f">Perempuan</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              rules={[{ required: true }]}
              required={true}
              name="fullAddress"
              label="Alamat"
            >
              <Input placeholder="input alamat mitra" />
            </Form.Item>

            <Form.Item
              rules={[{ required: true }]}
              required={true}
              name="latitude"
              label="latitude"
            >
              <Input placeholder="input latitude" />
            </Form.Item>

            <Form.Item
              rules={[{ required: true }]}
              required={true}
              name="longitude"
              label="longitude"
            >
              <Input placeholder="input longitude" />
            </Form.Item>
            {localStorage.getItem("role") == "businessPartner" ? null : (
              <Form.Item
                rules={[{ required: true }]}
                required={true}
                name="businessPartnerCode"
                label="Business Partner"
                style={{
                  display:
                    localStorage.getItem("role") == "businessPartner"
                      ? "none"
                      : "",
                }}
              >
                <Select
                  showSearch
                  style={{ width: 200 }}
                  placeholder="Pilih Business Partner"
                  optionFilterProp="children"
                  onChange={onChange}
                >
                  {businessPartners.map((businessPartner) => {
                    return (
                      <Option value={businessPartner.code}>
                        {businessPartner.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            )}

            <Row gutter={15}>
              <Col span={12}>
                <Form.Item
                  rules={[{ required: true }]}
                  required={true}
                  name="ktpImage"
                  label="KTP"
                >
                  {
                    imageBase64.ktpImage && imageBase64.ktpImage && imageBase64.ktpImage !== ''
                    ? (
                      <CustomImage
                        image={imageBase64.ktpImage}
                        command={() => deleteImage('ktpImage')}
                      />
                    ) : (
                      <UploadImage
                        setStateFileList={ setStateFileList }
                        sliceFileList={ sliceFileList }
                        setStateForm={ setStateForm }
                        name='ktpImage'
                        fileList={ images.ktpImage }
                        height='300px'
                        accept='.png,.jpg'
                        icon={ <InboxOutlined style={{fontSize: '24px'}}/> }
                      />
                    )
                  }
                
                </Form.Item>
                <Form.Item
                  rules={[{ required: true }]}
                  required={true}
                  name="ktpNumber"
                  label="Nomor KTP"
                >
                  <Input placeholder="input nomor KTP mitra" type="number" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  rules={[{ required: true }]}
                  required={true}
                  name="kkImage"
                  label="Kartu Keluarga"
                >
                  {
                    imageBase64.kkImage && imageBase64.kkImage && imageBase64.kkImage !== ''
                    ? (
                      <CustomImage
                        image={imageBase64.kkImage}
                        command={() => deleteImage('kkImage')}
                      />
                    ) : (
                      <UploadImage
                        setStateFileList={ setStateFileList }
                        sliceFileList={ sliceFileList }
                        setStateForm={ setStateForm }
                        name='kkImage'
                        fileList={ images.kkImage }
                        height='300px'
                        accept='.png,.jpg'
                        icon={ <InboxOutlined style={{fontSize: '24px'}}/> }
                      />
                    )
                  }

                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              rules={[{ required: true }]}
              required={true}
              name="skckImage"
              label="Surat Keterangan Catatan Kepolisian"
            >
              {
                imageBase64.skckImage && imageBase64.skckImage && imageBase64.skckImage !== ''
                ? (
                  <CustomImage
                    image={imageBase64.skckImage}
                    command={() => deleteImage('skckImage')}
                  />
                ) : (
                  <UploadImage
                    setStateFileList={ setStateFileList }
                    sliceFileList={ sliceFileList }
                    setStateForm={ setStateForm }
                    name='skckImage'
                    fileList={ images.skckImage }
                    height='300px'
                    accept='.png,.jpg'
                    icon={ <InboxOutlined style={{fontSize: '24px'}}/> }
                  />
                )
              }
            </Form.Item>
          </Panel>
          <Panel header="Deposit Account" key="2">
            <Row gutter={15}>
              <Col span={12}>
                <Form.Item
                  rules={[{ required: true }]}
                  required={true}
                  name="bankName"
                  label="Nama Bank"
                >
                  <Select
                    showSearch
                    placeholder="Pilih Bank"
                    optionFilterProp="children"
                    onChange={onChange}
                  >
                    <Option value="BCA">BCA</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                {" "}
                <Form.Item
                  rules={[{ required: true }]}
                  required={true}
                  name="bankNumber"
                  label="Nomor Bank"
                >
                  <Input placeholder="input nomor bank mitra" type="number" />
                </Form.Item>
              </Col>
            </Row>
          </Panel>

          <Panel header="Emergency Contact" key="3">
            <Form.Item
              rules={[{ required: true }]}
              required={true}
              name="emergencyContactName"
              label="Nama Emergency Contact"
            >
              <Input placeholder="input nama emergency contact" />
            </Form.Item>
            <Form.Item
              rules={[
                { required: true },
                { min: 10, message: "Telepon minimum 10 digit" },
                { max: 14, message: "Telepon maksimum 14 digit" },
              ]}
              required={true}
              name="emergencyContactPhone"
              label="Nomor Telepon"
            >
              <Input
                placeholder="input nomor telepon emergency"
                type="number"
              />
            </Form.Item>
          </Panel>
          {/* <Panel header="YOBEN Setting" key="4">
            <Form.Item
              rules={[{ required: true }]}
              required={true}
              name="userPreference"
              label="Preferensi customer"
            >
              <Select
                style={{ width: 200 }}
                placeholder="Pilih Preferensi"
                optionFilterProp="children"
                onChange={onChange}
              >
                <Option value="m">Laki-laki</Option>
                <Option value="f">Perempuan</Option>
              </Select>
            </Form.Item>
            <Form.Item
              rules={[{ required: true }]}
              required={true}
              name="status"
              label="Status"
            >
              <Select
                style={{ width: 200 }}
                placeholder="Select status"
                optionFilterProp="children"
                onChange={onChange}
              >
                <Option value="active">active</Option>
                <Option value="inactive">inactive</Option>
              </Select>
            </Form.Item>
          </Panel> */}
        </Collapse>
        <div className="section-edit-button">
          <Button type="primary" htmlType="submit" loading={loadingMitra}>
            Submit
          </Button>
        </div>
      </Form>
    </Style>
  );
};

export default MitraRegistration;
