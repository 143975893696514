import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Divider, Row, Col, Spin } from "antd";
import { CheckCircleTwoTone } from "@ant-design/icons";
import moment from "moment";

import * as OrderActions from "../../state/order/order.action";
import * as OrderSelectors from "../../state/order/order.selector";

const OrderModal = (props: {
  active: boolean;
  onChangeActive: Function;
  orderCode: string;
}) => {
  const orderList = useSelector(OrderSelectors.selectOrder);
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.orderCode) {
      dispatch(
        OrderActions.getOrderDetail.request({
          orderCode: props.orderCode,
        })
      );
    }
  }, [props.orderCode])

  let currentOrder = orderList.find(
    (order) => order.code === props.orderCode
  );

  const renderOrder = () => {
    return (
      <Row gutter={20}>
        <Col span={12}>
          <h3>Service - {currentOrder.code}</h3>
          {currentOrder.duration ? (
            <p>
              Layanan: {currentOrder.service.name} -{" "}
              {currentOrder.duration.duration / 60} menit
            </p>
          ) : null}

          <p>
            Jam pelayanan: {moment(currentOrder.work_time).format("ll")} -{" "}
            {moment(currentOrder.work_time).format("HH:mm")}
          </p>

          <p>
            Alamat:{" "}
            <a
              target="_blank"
              rel="help"
              href={`https://www.google.com/maps/search/?api=1&query=${currentOrder.address?.latitude},${currentOrder.address?.longitude}`}
            >
              {currentOrder.address?.address}
            </a>
          </p>
          <p>deskripsi alamat: {currentOrder.address?.description}</p>

          <Divider></Divider>
          <h3>Customer - {currentOrder.user?.code}</h3>
          <p>Nama: {currentOrder.user.name}</p>
          <p>
            Gender:{" "}
            {currentOrder.user.gender === "m" ? "laki-laki" : "perempuan"}
          </p>
          <p>Phone: {currentOrder.user.phone}</p>
          <p>Image: {currentOrder.user.image}</p>
        </Col>
        <Col span={12}>
          <h3>Status</h3>
          <p>{currentOrder.status}</p>
          {currentOrder.mitra ? (
            <>
              {" "}
              <Divider></Divider>
              <h3>Mitra - {currentOrder.mitra.code}</h3>
              <p>Nama: {currentOrder.mitra.name} - </p>
              <p>
                Favorit:{" "}
                {currentOrder.mitra.favorite ? <CheckCircleTwoTone /> : "-"}{" "}
              </p>{" "}
            </>
          ) : null}

          <Divider></Divider>
          <h3>Harga</h3>
          <p>
            Kupon:{" "}
            {currentOrder.coupon?.name ? currentOrder.coupon.name : "-"}
          </p>
          <p>Discount: {currentOrder?.price.discount}</p>
          <p>Total: {currentOrder?.price.total}</p>
        </Col>
      </Row>
    )
  }

  const cancelOrder = () => {
    const confirmation = window.confirm("Apakah anda yakin untuk membatalkan transaksi ini?");
    if (confirmation) {
      dispatch(OrderActions.cancelOrder.request({
        orderCode: props.orderCode,
        source: 'orders'
      }))
    }
  }

  const notCancelableStatus = ['start_service', 'pending_payment', 'done', 'cancel'];

  return (
    <Modal
      visible={props.active}
      onCancel={() => props.onChangeActive(false)}
      cancelText={"Ok"}
      okText={"Batalkan"}
      onOk={() => cancelOrder()}
      okButtonProps={{
        danger: true,
        disabled: notCancelableStatus.includes(currentOrder?.status)
      }}
      width={1000}
    >
      { currentOrder ? renderOrder() : 
        <Row justify="center">
          <Spin size="large" />
        </Row>
      }
    </Modal>
  )
}

export default OrderModal;