import { createReducer } from "typesafe-actions";

import { ServiceActionType } from "./service.actionType";
import * as ServiceActions from "./service.action";
import {
  IBusinessPartner,
  IService,
  IServiceReducer,
} from "./service.interface";
import { deduplicate, removeOne } from "../../helpers";

const initialState: IServiceReducer = {
  parentServices: [],
  childServices: [],
  serviceDurations: [],
  bugarServices: [],
  resikServices: [],
  businessPartners: [],
  promotions: [],
  coupons: [],
  areas: [],
  filterParentCode: "",
  loading: false,
  errorMsg: "",
  success: false,
};

let serviceReducer = createReducer(initialState)
  .handleAction(
    ServiceActions.setParentCode,
    (state = initialState, { payload }: any) => ({
      ...state,
      filterParentCode: payload,
    })
  )
  .handleAction(
    ServiceActions.getServiceParent.request,
    (state = initialState) => ({
      ...state,
      loading: true,
    })
  )
  .handleAction(
    ServiceActions.getServiceParent.success,
    (state = initialState, { payload }: any) => {
      const parentServices = payload.services.filter(
        (service: IService) => service.type === "PARENT"
      );
      const childServices = payload.services.filter(
        (service: IService) => service.type === "CHILD"
      );
      return {
        ...state,
        loading: false,
        parentServices: parentServices,
        childServices: childServices,
      };
    }
  )
  .handleAction(
    ServiceActions.getServiceDuration.request,
    (state = initialState) => ({
      ...state,
      loading: true,
    })
  )
  .handleAction(
    ServiceActions.getServiceDuration.success,
    (state = initialState, { payload }: any) => {
      return {
        ...state,
        loading: false,
        serviceDurations: deduplicate(
          state.serviceDurations.concat(payload.durations),
          "code"
        ),
      };
    }
  )
  .handleAction(
    ServiceActions.createServiceDuration.request,
    (state = initialState, { payload }: any) => {
      return {
        ...state,
        loading: false,
        success: false,
      };
    }
  )
  .handleAction(
    ServiceActions.createServiceDuration.success,
    (state = initialState, { payload }: any) => {
      return {
        ...state,
        loading: false,
        success: true,
        serviceDurations: [...state.serviceDurations, payload.duration],
      };
    }
  )
  .handleAction(
    ServiceActions.editServiceDuration.request,
    (state = initialState, { payload }: any) => {
      return {
        ...state,
        loading: false,
        success: false,
      };
    }
  )
  .handleAction(
    ServiceActions.editServiceDuration.success,
    (state = initialState, { payload }: any) => {
      return {
        ...state,
        loading: false,
        success: true,
        serviceDurations: deduplicate(
          [...state.serviceDurations, payload.duration],
          "code"
        ),
      };
    }
  )
  .handleAction(
    ServiceActions.removeServiceDuration.request,
    (state = initialState, { payload }: any) => {
      return {
        ...state,
        loading: false,
        success: false,
      };
    }
  )
  .handleAction(
    ServiceActions.removeServiceDuration.success,
    (state = initialState, { payload }: any) => {
      return {
        ...state,
        loading: false,
        success: true,
        serviceDurations: removeOne(
          state.serviceDurations,
          payload.durationCode
        ),
      };
    }
  )
  .handleAction(
    ServiceActions.getServiceChild.request,
    (state = initialState) => ({
      ...state,
      loading: true,
    })
  )
  .handleAction(
    ServiceActions.getServiceChild.success,
    (state = initialState, { payload }: any) => {
      let newChild = state.childServices.concat(payload.service);

      return {
        ...state,
        loading: false,
        childServices: deduplicate(newChild, "code"),
      };
    }
  )
  .handleAction(
    ServiceActions.createServiceChild.request,
    (state = initialState) => ({
      ...state,
      loading: true,
      success: false,
    })
  )
  .handleAction(
    ServiceActions.createServiceChild.success,
    (state = initialState, { payload }: any) => {
      let newChild = state.childServices.concat(payload.service);
      return {
        ...state,
        loading: false,
        success: true,
        childServices: deduplicate(newChild, "code"),
      };
    }
  )
  .handleAction(
    ServiceActions.editServiceChild.request,
    (state = initialState) => ({
      ...state,
      loading: true,
      success: false,
    })
  )
  .handleAction(
    ServiceActions.editServiceChild.success,
    (state = initialState, { payload }: any) => {
      let newChild = state.childServices.concat(payload.service);
      return {
        ...state,
        loading: false,
        success: true,
        childServices: deduplicate(newChild, "code"),
      };
    }
  )
  .handleAction(
    ServiceActions.deleteService.request,
    (state = initialState) => ({
      ...state,
      loading: true,
      success: false,
    })
  )
  .handleAction(
    ServiceActions.deleteService.success,
    (state = initialState, { payload }: any) => {
      return {
        ...state,
        loading: false,
        success: true,
        childServices: removeOne(state.childServices, payload.service),
      };
    }
  )
  .handleAction(
    ServiceActions.getBusinessPartner.request,
    (state = initialState) => ({
      ...state,
      loading: true,
    })
  )
  .handleAction(
    ServiceActions.getBusinessPartner.success,
    (state = initialState, { payload }: any) => {
      return {
        ...state,
        loading: false,
        businessPartners: payload.businessPartners,
      };
    }
  )
  .handleAction(
    ServiceActions.getSelfBusinessPartner.request,
    (state = initialState) => ({
      ...state,
      loading: true,
    })
  )
  .handleAction(
    ServiceActions.getSelfBusinessPartner.success,
    (state = initialState, { payload }: any) => {
      return {
        ...state,
        loading: false,
        businessPartners: deduplicate([...state.businessPartners, payload], "code")
      };
    }
  )
  .handleAction(
    ServiceActions.registerBusinessPartner.request,
    (state = initialState) => ({
      ...state,
      loading: true,
      success: false,
    })
  )
  .handleAction(
    ServiceActions.registerBusinessPartner.success,
    (state = initialState, { payload }: any) => {
      return {
        ...state,
        loading: false,
        businessPartners: [payload, ...state.businessPartners],
        success: true,
      };
    }
  )
  .handleAction(
    ServiceActions.editBusinessPartner.request,
    (state = initialState) => ({
      ...state,
      loading: true,
      success: false,
    })
  )
  .handleAction(
    ServiceActions.editBusinessPartner.success,
    (state = initialState, { payload }: any) => {
      let newBusinessPartner = [...state.businessPartners];
      let index = newBusinessPartner.findIndex(
        (businessPartner: IBusinessPartner) =>
          businessPartner.code === payload.code
      );
      if (index > -1) {
        newBusinessPartner[index] = payload;
      }
      return {
        ...state,
        loading: false,
        businessPartners: newBusinessPartner,
        success: true,
      };
    }
  )
  .handleAction(
    ServiceActions.deleteBusinessPartner.request,
    (state = initialState) => ({
      ...state,
      loading: true,
      success: false,
    })
  )
  .handleAction(
    ServiceActions.deleteBusinessPartner.success,
    (state = initialState, { payload }) => {
      return {
        ...state,
        loading: false,
        businessPartners: removeOne(
          state.businessPartners,
          payload.businessPartnerCode
        ),
        success: true,
      };
    }
  )
  // PROMOTION REDUCER
  .handleAction(
    ServiceActions.getPromotion.request,
    (state = initialState) => ({
      ...state,
      loading: true,
    })
  )
  .handleAction(
    ServiceActions.getPromotion.success,
    (state = initialState, { payload }: any) => {
      return {
        ...state,
        loading: false,
        promotions: payload,
      };
    }
  )
  .handleAction(
    ServiceActions.createPromotion.request,
    (state = initialState) => ({
      ...state,
      loading: true,
      success: false,
    })
  )
  .handleAction(
    ServiceActions.createPromotion.success,
    (state = initialState, { payload }: any) => {
      return {
        ...state,
        loading: false,
        promotions: [payload, ...state.promotions],
        success: true,
      };
    }
  )
  .handleAction(
    ServiceActions.editPromotion.request,
    (state = initialState) => ({
      ...state,
      loading: true,
      success: false,
    })
  )
  .handleAction(
    ServiceActions.editPromotion.success,
    (state = initialState, { payload }: any) => {
      let currentPromotions = [...state.promotions];

      let index = currentPromotions.findIndex(
        (promotion) => promotion.code === payload.code
      );
      currentPromotions[index] = payload;
      return {
        ...state,
        loading: false,
        promotions: currentPromotions,
        success: true,
      };
    }
  )
  .handleAction(
    ServiceActions.deletePromotion.request,
    (state = initialState) => ({
      ...state,
      loading: true,
      success: false,
    })
  )
  .handleAction(
    ServiceActions.deletePromotion.success,
    (state = initialState, { payload }) => {
      return {
        ...state,
        loading: false,
        promotions: removeOne(state.promotions, payload.promotionCode),
        success: true,
      };
    }
  )
  .handleAction(ServiceActions.getCoupon.request, (state = initialState) => ({
    ...state,
    loading: true,
  }))
  .handleAction(
    ServiceActions.getCoupon.success,
    (state = initialState, { payload }: any) => {
      return {
        ...state,
        loading: false,
        coupons: payload,
      };
    }
  )

  .handleAction(
    ServiceActions.createCoupon.request,
    (state = initialState) => ({
      ...state,
      loading: true,
      success: false,
    })
  )
  .handleAction(
    ServiceActions.createCoupon.success,
    (state = initialState, { payload }) => {
      return {
        ...state,
        loading: false,
        coupons: [payload, ...state.coupons],
        success: true,
      };
    }
  )

  .handleAction(ServiceActions.editCoupon.request, (state = initialState) => ({
    ...state,
    loading: true,
    success: false,
  }))
  .handleAction(
    ServiceActions.editCoupon.success,
    (state = initialState, { payload }) => {
      return {
        ...state,
        loading: false,
        coupons: deduplicate([...state.coupons, payload], "code"),
        success: true,
      };
    }
  )
  .handleAction(
    ServiceActions.deleteCoupon.request,
    (state = initialState) => ({
      ...state,
      loading: true,
      success: false,
    })
  )
  .handleAction(
    ServiceActions.deleteCoupon.success,
    (state = initialState, { payload }) => {
      return {
        ...state,
        loading: false,
        coupons: removeOne(state.coupons, payload),
        success: true,
      };
    }
  )
  .handleAction(ServiceActions.getArea.request, (state = initialState) => ({
    ...state,
    loading: true,
  }))
  .handleAction(
    ServiceActions.getArea.success,
    (state = initialState, { payload }: any) => {
      return {
        ...state,
        loading: false,
        areas: payload,
      };
    }
  )
  .handleAction(
    ServiceActionType.ServiceError,
    (
      state = initialState,
      action: {
        payload: {
          errorMsg: string;
        };
      }
    ) => ({
      ...state,
      loading: false,
      success: false,
      errorMsg: action.payload.errorMsg,
    })
  );

export default serviceReducer;
