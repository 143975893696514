import React from "react";
import { Provider } from "react-redux";
import moment from "moment";
import "moment/locale/id";
import "antd/dist/antd.css";

import Router from "./routes";
import { history } from "./helpers";
import "./App.css";
import { store } from "./state";

require('dotenv').config();

function App() {
  moment().locale("id");
  return (
    <Provider store={store}>
      <Router history={history} />
    </Provider>
  );
}

export default App;
