import { customFetch } from "../helpers";
import { IOrder, IMitraAvailable } from "../state/order/order.interface";

const mapUserResponse = (user) => {
  return {
    code: user.code,
    name: user.name,
    gender: user.gender,
    phone: user.phone,
    imageUrl: user.image,
  };
};

const mapOrderService = (orderService) => {
  return {
    code: orderService.code,
    name: orderService.name,
    imageUrl: orderService.image,
  };
};

export const mapOrderResponse = (order) => {
  return {
    address: order.address,
    code: order.code,
    price: order.price,
    service: mapOrderService(order.service),
    status: order.status,
    user: mapUserResponse(order.user),
    workTime: order.work_time,
    duration: order.duration,
  } as IOrder;
};

const mapMitraAvailable = (mitraAvailable) => {
  return {
    code: mitraAvailable.code,
    balance: mitraAvailable.balance,
    fullAddress: mitraAvailable.full_addrress,
    gender: mitraAvailable.gender,
    image: mitraAvailable.image,
    isAvailable: mitraAvailable.is_available,
    name: mitraAvailable.name,
    status: mitraAvailable.status,
    typeNotAvailable: mitraAvailable.type_not_available
  } as IMitraAvailable;
}


export const getOrder = async (params: {
  orderStatus: string;
  limit?: number;
  skip?: number;
  code?: string | "";
}) => {
  console.warn('params', params.code)
  try {
    const response: any = await customFetch(
      `${process.env.REACT_APP_TRANSACTION_API}/api/v1/business-partner/orders?status=${params.orderStatus}&limit=${params.limit}&skip=${params.skip}&code=${params.code ? params.code : ""}`,
      "GET"
    );

    if (response.data.count >= 0) {
      response.data.results = response.data.results.map((order) =>
        mapOrderResponse(order)
      );
    } else {
      response.data.results = [mapOrderResponse(response.data)];
    }
   
    return response;
  } catch (error) {
    throw error;
  }
};

export const getOrderDetail = async (orderCode: string) => {
  try {
    const response: any = await customFetch(
      `${process.env.REACT_APP_TRANSACTION_API}/api/v1/business-partner/orders?code=${orderCode}`,
      "GET"
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const getMitraAvailable = async (orderCode) => {
  try {
    const response: any = await customFetch(
      `${process.env.REACT_APP_TRANSACTION_API}/api/v1/business-partner/orders/mitra/${orderCode}?limit=50`,
      "GET"
    );

    response.data.results = response.data.results.map((mitraAvailable) => mapMitraAvailable(mitraAvailable))
    return response;
  } catch (error) {
    throw error;
  }
};

export const assignMitra = async ({ orderCode, mitraCode }) => {
  try {
    const response: any = await customFetch(
      `${process.env.REACT_APP_TRANSACTION_API}/api/v1/business-partner/orders/assign`,
      "POST",
      {
        order_code: orderCode,
        mitra_code: mitraCode,
      }
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const cancelOrder = async ({ orderCode }) => {
  try {
    const response: any = await customFetch(
      `${process.env.REACT_APP_TRANSACTION_API}/api/v1/business-partner/orders/cancel/${orderCode}`,
      "PUT",
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const getMitraTransaction = async ({ mitraCode, limit, skip }) => {
  try {
    const response: any = await customFetch(
      `${process.env.REACT_APP_TRANSACTION_API}/api/v1/business-partner/transactions/mitra/${mitraCode}?limit=${limit}&skip=${skip}`,
      "GET",
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const postTransaction = async ({ notes, amount, receiptImage, paymentType, type, mitraCode}) => {
  try {
    const response: any = await customFetch(
      `${process.env.REACT_APP_TRANSACTION_API}/api/v1/admin/transactions/mitra/${mitraCode}`,
      "POST",
      {
        notes,
        amount: Number(amount),
        receipt_image: receiptImage,
        payment_type: paymentType,
        type: type
      }
    )
    return response;
  } catch (error) {
    throw error;
  }
};
