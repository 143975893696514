import { createReducer } from "typesafe-actions";

import { AuthActionType } from "./auth.actionType";
import * as AuthActions from "./auth.action";

const initialState = {
  loading: false,
  errorMsg: "",
};

export default createReducer(initialState)
  .handleAction(AuthActions.loginPassword.request, (state = initialState) => ({
    ...state,
    loading: true,
  }))
  .handleAction(AuthActions.loginPassword.success, (state = initialState) => ({
    loading: false,
  }))
  .handleAction(
    AuthActionType.AuthError,
    (
      state = initialState,
      action: {
        type: string;
        payload: {
          errorMsg: string;
        };
      }
    ) => ({
      ...state,
      loading: false,
      errorMsg: action.payload.errorMsg,
    })
  );
