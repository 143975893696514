import styled from "styled-components";

const Style = styled.div`
  img {
    max-width: 100%;
  }
  .section-edit-button {
    margin-top: 20px;
  }

  .ant-spin-container {
    background: white;
  }
`;

export { Style };
