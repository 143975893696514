import { createAction, createAsyncAction, action } from "typesafe-actions";

import { IOrder } from "../order/order.interface";
import { IService } from "../service/service.interface";
import { ITransactionMitra } from "./mitra.interface";
import { MitraActionType } from "./mitra.actionType";

export const getMitra = createAsyncAction(
  MitraActionType.GetMitra,
  MitraActionType.GetMitraSuccess,
  MitraActionType.MitraError
)<
  {
    page?: number;
    filter?: string;
    value?: string;
  },
  {
    mitra: string;
  },
  {
    errorMsg: string;
  }
>();

export const setSelectedMitra = createAction(
  MitraActionType.SetSelectedMitraCode
)<{ mitraCode: string }>();

export const editMitra = createAsyncAction(
  MitraActionType.EditMitra,
  MitraActionType.EditMitraSuccess,
  MitraActionType.MitraError
)<
  any,
  any,
  {
    errorMsg: string;
  }
>();


export const getMitraByCode = createAsyncAction(
  MitraActionType.GetMitraByCode,
  MitraActionType.GetMitraByCodeSuccess,
  MitraActionType.MitraError
)<
  { mitraCode: string },
  any,
  {
    errorMsg: string;
  }
>();

export const getMitraServices = createAsyncAction(
  MitraActionType.GetMitraServices,
  MitraActionType.GetMitraServicesSuccess,
  MitraActionType.MitraError
)<
  { mitraCode: string },
  { mitraCode: string; services: any },
  {
    errorMsg: string;
  }
>();

export const insertMitraService = createAsyncAction(
  MitraActionType.InsertMitraService,
  MitraActionType.InsertMitraServiceSuccess,
  MitraActionType.MitraError
)<
  { mitraCode: string; service: IService },
  {
    service: IService;
  },
  {
    errorMsg: string;
  }
>();

export const removeMitraService = createAsyncAction(
  MitraActionType.RemoveMitraService,
  MitraActionType.RemoveMitraServiceSuccess,
  MitraActionType.MitraError
)<
  { mitraCode: string; service: IService },
  {
    service: IService;
  },
  {
    errorMsg: string;
  }
>();

export const registerMitra = createAsyncAction(
  MitraActionType.RegisterMitra,
  MitraActionType.RegisterMitraSuccess,
  MitraActionType.MitraError
)<any, any, any>();

export const getTransaction = createAsyncAction(
  MitraActionType.GetTransaction,
  MitraActionType.GetTransactionSuccess,
  MitraActionType.MitraError
)<
  { status: string; type: string; page?: number },
  { transactions: ITransactionMitra[]; page?: number },
  {
    errorMsg: string;
  }
>();

export const editTransactionStatus = createAsyncAction(
  MitraActionType.EditTransactionStatus,
  MitraActionType.EditTransactionStatusSuccess,
  MitraActionType.MitraError
)<
  { code: string;
    notes: string;
    status: string;
    amount: number;
    receiptImage?: string },
    any,
  { errorMsg: string; }
>();

export const getMitraBalance = createAsyncAction(
  MitraActionType.GetBalance,
  MitraActionType.GetBalanceSuccess,
  MitraActionType.MitraError
)<
  { mitraCode: string },
  {
    amount: number,
    mitraCode: string
  },
  { errorMsg: string; }
>();

export const getOrderOnGoing = createAsyncAction(
  MitraActionType.GetOrderOnGoing,
  MitraActionType.GetOrderOnGoingSuccess,
  MitraActionType.MitraError
)<
  { mitraCode: string, limit: number, skip: number },
  { orders: IOrder[] },
  { errorMsg: string; }
>();

export const getOrderHistory = createAsyncAction(
  MitraActionType.GetOrderHistory,
  MitraActionType.GetOrderHistorySuccess,
  MitraActionType.MitraError
)<
  { mitraCode: string, limit: number, skip: number },
  { orders: IOrder[] },
  { errorMsg: string; }
>();

export const setSuccessMitraFalse = () => action(MitraActionType.SETSUCCESSFALSE);
