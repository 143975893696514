import { createAsyncAction } from "typesafe-actions";
import { OrderActionType } from "./order.actionType";
import { IOrder, IOrderTransaction } from "./order.interface";

export const getOrder = createAsyncAction(
  OrderActionType.GetOrder,
  OrderActionType.GetOrderSuccess,
  OrderActionType.OrderError
)<
  { code?: string; orderStatus: string; page?: number, limit?: number, skip?: number },
  { order: IOrder[]; orderStatus: string },
  { errorMsg?: string }
>();

export const getOrderDetail = createAsyncAction(
  OrderActionType.GetOrderDetail,
  OrderActionType.GetOrderDetailSuccess,
  OrderActionType.OrderError
)<{ orderCode: string }, { order: IOrder[] }, { errorMsg?: string }>();

export const getMitraAvailable = createAsyncAction(
  OrderActionType.GetMitraAvailable,
  OrderActionType.GetMitraAvailableSuccess,
  OrderActionType.OrderError
)<{ orderCode: string }, any, { errorMsg?: string }>();

export const assignMitra = createAsyncAction(
  OrderActionType.AssignMitra,
  OrderActionType.AssignMitraSuccess,
  OrderActionType.OrderError
)<{ orderCode: string; mitraCode: string }, any, { errorMsg?: string }>();

export const cancelOrder = createAsyncAction(
  OrderActionType.CancelOrder,
  OrderActionType.CancelOrderSuccess,
  OrderActionType.OrderError
)<{ orderCode: string, source: string }, { orderCode: string, source: string }, { errorMsg?: string }>();

export const getMitraTransaction = createAsyncAction(
  OrderActionType.GetMitraTransaction,
  OrderActionType.GetMitraTransactionSuccess,
  OrderActionType.OrderError
)<{ mitraCode: string; limit: number, skip: number }, IOrderTransaction[], { errorMsg?: string }>();

export const postTransaction = createAsyncAction(
  OrderActionType.PostTransaction,
  OrderActionType.PostTransactionSuccess,
  OrderActionType.OrderError
)<{ notes: string, amount: number, receiptImage: string, paymentType: string, type: string, mitraCode: string}, any, { errorMsg?: string }>();