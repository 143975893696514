import { customFetch } from "../helpers";
import {
  IArea,
  IBusinessPartner,
  ICoupon,
  IPromotion,
  IService,
  IServiceDuration,
} from "../state/service/service.interface";

const mapServiceParentResponse = (serviceParent) => {
  return {
    code: serviceParent.code,
    name: serviceParent.name,
    imageUrl: serviceParent.image_url,
    description: serviceParent.description,
    areaCode: serviceParent.area_code ? serviceParent.area_code : "",
    type: serviceParent.type ? serviceParent.type : "",
  };
};

export const getServiceParent = async (areaCode: string) => {
  try {
    const response: any = await customFetch(
      `${process.env.REACT_APP_TRANSACTION_API}/api/v1/admin/services?area_code=${areaCode}`,
      "GET"
    );

    response.data.results = response.data.results.map((serviceParent) =>
      mapServiceParentResponse(serviceParent)
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const mapServiceChildResponse = (serviceChild) => {
  return {
    code: serviceChild.code,
    name: serviceChild.name,
    description: serviceChild.description,
    imageUrl: serviceChild.image_url,
    parentCode: serviceChild.parent_code,
  };
};

export const getServiceChild = async (serviceParentCode: string) => {
  try {
    const response: any = await customFetch(
      `${process.env.REACT_APP_TRANSACTION_API}/api/v1/admin/services?parent_code=${serviceParentCode}`,
      "GET"
    );

    response.data.results = response.data.results.map((serviceParent) =>
      mapServiceChildResponse(serviceParent)
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const createServiceChild = async (serviceForm: any) => {
  try {
    const response: any = await customFetch(
      `${process.env.REACT_APP_TRANSACTION_API}/api/v1/admin/services`,
      "POST",
      {
        name: serviceForm.name,
        image: serviceForm.imageUrl,
        description: serviceForm.description,
        area_code: serviceForm.areaCode,
        parent_code: serviceForm.parentCode,
      }
    );

    response.data = mapServiceChildResponse(response.data);

    return response;
  } catch (error) {
    throw error;
  }
};

export const editServiceChild = async (serviceForm: IService) => {
  try {
    const response: any = await customFetch(
      `${process.env.REACT_APP_TRANSACTION_API}/api/v1/admin/services/${serviceForm.code}`,
      "PUT",
      {
        name: serviceForm.name,
        image: serviceForm.imageUrl,
        description: serviceForm.description,
        area_code: serviceForm.areaCode,
        parent_code: serviceForm.parentCode,
      }
    );

    response.data = mapServiceChildResponse(response.data);

    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteService = async (service: IService) => {
  try {
    const response: any = await customFetch(
      `${process.env.REACT_APP_TRANSACTION_API}/api/v1/admin/services/${service.code}`,
      "DELETE"
    );

    return response;
  } catch (error) {
    throw error;
  }
};

const mapBusinessPartnerResponse = (businessPartner) => {
  return {
    code: businessPartner.code,
    name: businessPartner.name,
    email: businessPartner.email,
    phone: businessPartner.phone,
    imageUrl: businessPartner.image,
    areaOfficeCode: businessPartner.area_office_code,
    c_at: businessPartner.c_at,
    longitude: businessPartner?.longitude,
    latitude: businessPartner?.latitude,
  } as IBusinessPartner;
};

export const getBusinessPartner = async (params: {
  limit: number;
  skip: number;
}) => {
  try {
    const response: any = await customFetch(
      `${process.env.REACT_APP_ACCOUNT_API}/api/v1/admin/business-partner?limit=${params.limit}&skip=${params.skip}`,
      "GET"
    );

    response.data.results = response.data.results.map((businessPartner) =>
      mapBusinessPartnerResponse(businessPartner)
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const getSelfBusinessPartner = async () => {
  try {
    const response: any = await customFetch(
      `${process.env.REACT_APP_ACCOUNT_API}/api/v1/business-partner/self`,
      "GET"
    );
    response.data = mapBusinessPartnerResponse(response.data);
    return response;
  } catch (error) {
    throw error;
  }
};


export const registerBusinessPartner = async (
  businessPartnerForm: IBusinessPartner
) => {
  try {
    const response: any = await customFetch(
      `${process.env.REACT_APP_ACCOUNT_API}/api/v1/admin/business-partner`,
      "POST",
      {
        ...businessPartnerForm,
        area_office_code: businessPartnerForm.areaOfficeCode,
        image: businessPartnerForm.imageUrl,
      }
    );

    response.data = mapBusinessPartnerResponse(response.data);

    return response;
  } catch (error) {
    throw error;
  }
};

export const editBusinessPartner = async (
  businessPartnerForm: IBusinessPartner,
  businessPartnerCode: string
) => {
  try {
    const response: any = await customFetch(
      `${process.env.REACT_APP_ACCOUNT_API}/api/v1/admin/business-partner/${businessPartnerCode}`,
      "PUT",
      {
        ...businessPartnerForm,
        area_office_code: businessPartnerForm.areaOfficeCode,
        image: businessPartnerForm.imageUrl,
      }
    );

    response.data = mapBusinessPartnerResponse(response.data);

    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteBusinessPartner = async (businessPartnerCode: string) => {
  try {
    const response: any = await customFetch(
      `${process.env.REACT_APP_ACCOUNT_API}/api/v1/admin/business-partner/${businessPartnerCode}`,
      "DELETE"
    );

    return response;
  } catch (error) {
    throw error;
  }
};

const mapDurationResponse = (durationRequest) => {
  return {
    code: durationRequest.code,
    duration: durationRequest.duration,
    name: durationRequest.name,
    price: durationRequest.price,
    serviceCode: durationRequest.service_code,
  } as IServiceDuration;
};

export const getServiceDuration = async (serviceCode: string) => {
  try {
    const response: any = await customFetch(
      `${process.env.REACT_APP_TRANSACTION_API}/api/v1/admin/services/details?service_code=${serviceCode}`,
      "GET"
    );

    if (response.data.results) {
      response.data.results = response.data.results.map((response) =>
        mapDurationResponse(response)
      );
    } else {
      response.data.results = [];
    };
    
    return response;
  } catch (error) {
    throw error;
  }
};

export const createServiceDuration = async (serviceForm: {
  name: string;
  price: number;
  duration: number;
  serviceCode: string;
}) => {
  try {
    const response: any = await customFetch(
      `${process.env.REACT_APP_TRANSACTION_API}/api/v1/admin/services/details`,
      "POST",
      {
        name: serviceForm.name,
        price: serviceForm.price,
        duration: serviceForm.duration,
        service_code: serviceForm.serviceCode,
      }
    );

    response.data = mapDurationResponse(response.data);

    return response;
  } catch (error) {
    throw error;
  }
};

export const editServiceDuration = async (durationForm: {
  name: string;
  price: number;
  duration: number;
  serviceCode: string;
  durationCode: string;
}) => {
  try {
    const response: any = await customFetch(
      `${process.env.REACT_APP_TRANSACTION_API}/api/v1/admin/services/details/${durationForm.durationCode}`,
      "PUT",
      {
        name: durationForm.name,
        price: durationForm.price,
        duration: durationForm.duration,
        service_code: durationForm.serviceCode,
      }
    );

    response.data = mapDurationResponse(response.data);

    return response;
  } catch (error) {
    throw error;
  }
};

export const removeServiceDuration = async (durationCode: string) => {
  try {
    const response: any = await customFetch(
      `${process.env.REACT_APP_TRANSACTION_API}/api/v1/admin/services/details/${durationCode}`,
      "DELETE"
    );

    return response;
  } catch (error) {
    throw error;
  }
};

const mapAreaResponse = (area) => {
  return {
    code: area.code,
    city: area.city,
    longitude: area.longitude,
    latitude: area.latitude,
    radius: area.radius,
    c_at: area.c_at,
    u_at: area.u_at,
  };
};

export const getAreas = async () => {
  try {
    const response: any = await customFetch(
      `${process.env.REACT_APP_TRANSACTION_API}/api/v1/admin/area-offices`,
      "GET"
    );

    response.data.results = response.data.results.map((response) =>
      mapAreaResponse(response)
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const editArea = async (area: IArea) => {
  try {
    const response: any = await customFetch(
      `${process.env.REACT_APP_TRANSACTION_API}/api/v1/admin/area-offices/${area.code}`,
      "PUT",
      area
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const createArea = async (areaForm: IArea) => {
  try {
    const response: any = await customFetch(
      `${process.env.REACT_APP_TRANSACTION_API}/api/v1/admin/area-offices`,
      "POST",
      areaForm
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteArea = async (area: IArea) => {
  try {
    const response: any = await customFetch(
      `${process.env.REACT_APP_TRANSACTION_API}/api/v1/admin/area-offices/${area.code}`,
      "DELETE"
    );

    return response;
  } catch (error) {
    throw error;
  }
};

const mapPromotionResponse = (promotion) => {
  return {
    name: promotion.name,
    code: promotion.code,
    imageUrl: promotion.image_url,
    description: promotion.description,
    serviceCode: promotion.service_code,
    c_at: promotion.c_at,
    u_at: promotion.u_at,
  };
};

// SERVICE - PROMOTION
export const getPromotions = async () => {
  try {
    const response: any = await customFetch(
      `${process.env.REACT_APP_TRANSACTION_API}/api/v1/admin/promos`,
      "GET"
    );

    response.data.results = response.data.results.map((response) =>
      mapPromotionResponse(response)
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const editPromotion = async (promotion: IPromotion) => {
  try {
    const response: any = await customFetch(
      `${process.env.REACT_APP_TRANSACTION_API}/api/v1/admin/promos/${promotion.code}`,
      "PUT",
      {
        name: promotion.name,
        image_url: promotion.imageUrl,
        service_code: promotion.serviceCode,
        description: promotion.description,
      }
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const createPromotion = async (promotionForm: {
  name: string;
  description: string;
  imageUrl: string;
  serviceCode: string;
}) => {
  try {
    const response: any = await customFetch(
      `${process.env.REACT_APP_TRANSACTION_API}/api/v1/admin/promos`,
      "POST",
      {
        name: promotionForm.name,
        description: promotionForm.description,
        image_url: promotionForm.imageUrl,
        service_code: promotionForm.serviceCode,
      }
    );

    response.data = mapPromotionResponse(response.data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const deletePromotion = async (promotionCode: string) => {
  try {
    const response: any = await customFetch(
      `${process.env.REACT_APP_TRANSACTION_API}/api/v1/admin/promos/${promotionCode}`,
      "DELETE"
    );

    return response;
  } catch (error) {
    throw error;
  }
};

const mapCouponResponse = (coupon) => {
  return {
    code: coupon.code,
    name: coupon.name,
    description: coupon.description,
    discount: coupon.discount,
    startDate: coupon.start_date,
    endDate: coupon.end_date,
    imageUrl: coupon.image_url,
    maxDiscount: coupon.max_discount,
    serviceCode: coupon.service_code,
    type: coupon.type,
  } as ICoupon;
};

const mapCouponToApi = (coupon: ICoupon) => {
  return {
    code: coupon.code,
    name: coupon.name,
    description: coupon.description,
    discount: coupon.discount,
    start_date: coupon.startDate,
    end_date: coupon.endDate,
    image: coupon.imageUrl,
    max_discount: coupon.maxDiscount,
    service_code: coupon.serviceCode,
    type: coupon.type,
  };
};

export const getCoupon = async (params: { limit: number; skip: number }) => {
  try {
    const response: any = await customFetch(
      `${process.env.REACT_APP_TRANSACTION_API}/api/v1/admin/coupons?limit=${params.limit}&skip=${params.skip}`,
      "GET"
    );

    response.data.results = response.data.results.map((coupon) =>
      mapCouponResponse(coupon)
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const createCoupon = async (couponForm: ICoupon) => {
  try {
    const response: any = await customFetch(
      `${process.env.REACT_APP_TRANSACTION_API}/api/v1/admin/coupons`,
      "POST",
      mapCouponToApi(couponForm)
    );

    response.data = mapCouponResponse(response.data);

    return response;
  } catch (error) {
    throw error;
  }
};

export const editCoupon = async (couponForm: ICoupon) => {
  try {
    const response: any = await customFetch(
      `${process.env.REACT_APP_TRANSACTION_API}/api/v1/admin/coupons/${couponForm.code}`,
      "PUT",
      mapCouponToApi(couponForm)
    );

    response.data = mapCouponResponse(response.data);

    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteCoupon = async (couponCode: string) => {
  try {
    const response: any = await customFetch(
      `${process.env.REACT_APP_TRANSACTION_API}/api/v1/admin/coupons/${couponCode}`,
      "DELETE"
    );

    return response;
  } catch (error) {
    throw error;
  }
};
