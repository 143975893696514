import history from "./history";
import customFetch from "./customFetch";

function deduplicate(array, prop) {
  return Array.from(
    new Map(array.map((i) => [prop in i ? i[prop] : i, i])).values()
  );
}

let removeOne = (
  currentState: any[],
  updatedObj: {} | string,
  key = "code"
) => {
  let newArray = [...currentState];
  let index;
  if (typeof updatedObj === "object") {
    index = newArray.findIndex((arr: any) => arr[key] === updatedObj[key]);
  }

  if (typeof updatedObj === "string") {
    index = newArray.findIndex((arr: any) => arr[key] === updatedObj);
  }

  if (index > -1) {
    newArray.splice(index, 1);
  }

  return newArray;
};

let roleAPIHelper = () => {
  return localStorage.getItem("role") === 'admin' ? 'admin' : 'business-partner';
}

export { history, customFetch, deduplicate, removeOne, roleAPIHelper };
