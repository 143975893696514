import { all, takeEvery, call, put, fork } from "redux-saga/effects";

import * as OrderActions from "./order.action";
import * as OrderServices from "../../services/order.service";
import { notification } from "antd";
import { Http } from "../../const";

export function* getOrder() {
  yield takeEvery(OrderActions.getOrder.request, function* ({ payload }) {
    const result = yield call(OrderServices.getOrder, {
      limit: payload.limit || 10,
      skip: payload.skip || Http.skip(payload.page, 10),
      orderStatus: payload.orderStatus,
      code: payload.code
    });

    if (result.code === 200) {
      yield put(
        OrderActions.getOrder.success({
          order: result.data.results,
          orderStatus: payload.orderStatus,
        })
      );
    } else {
      yield put(
        OrderActions.getOrder.failure({
          errorMsg: "Get Order Error",
        })
      );
    }
  });
}

export function* getOrderDetail() {
  yield takeEvery(OrderActions.getOrderDetail.request, function* ({ payload }) {
    const result = yield call(OrderServices.getOrderDetail, payload.orderCode);

    if (result.code === 200) {
      yield put(
        OrderActions.getOrderDetail.success({
          order: result.data,
        })
      );
    } else {
      yield put(
        OrderActions.getOrderDetail.failure({
          errorMsg: "Get Order Error",
        })
      );
    }
  });
}

export function* getMitraAvailable() {
  yield takeEvery(
    OrderActions.getMitraAvailable.request,
    function* ({ payload }) {
      const result = yield call(
        OrderServices.getMitraAvailable,
        payload.orderCode
      );

      if (result.code === 200) {
        yield put(
          OrderActions.getMitraAvailable.success({
            availableMitras: result.data.results,
          })
        );
      } else {
        yield put(
          OrderActions.getOrderDetail.failure({
            errorMsg: "Get Mitra Available Error",
          })
        );
      }
    }
  );
}

export function* assignMitra() {
  yield takeEvery(OrderActions.assignMitra.request, function* ({ payload }) {
    const result = yield call(OrderServices.assignMitra, {
      orderCode: payload.orderCode,
      mitraCode: payload.mitraCode,
    });

    if (result.code === 200) {
      notification.success({
        placement: "topRight",
        bottom: 50,
        duration: 3,
        message: "Mitra berhasil di assign",
      });
      yield put(
        OrderActions.assignMitra.success({
          orderCode: payload.orderCode,
        })
      );
    } else {
      yield put(
        OrderActions.assignMitra.failure({
          errorMsg: "Assign mitra error",
        })
      );
    }
  });
}

export function* cancelOrder() {
  yield takeEvery(OrderActions.cancelOrder.request, function* ({ payload }) {
    const result = yield call(OrderServices.cancelOrder, {
      orderCode: payload.orderCode,
    });

    if (result.code === 200) {
      notification.success({
        placement: "topRight",
        bottom: 50,
        duration: 3,
        message: "Order berhasil di cancel",
      });
      yield put(
        OrderActions.cancelOrder.success({
          orderCode: payload.orderCode,
          source: payload.source
        })
      );
    } else {
      yield put(
        OrderActions.cancelOrder.failure({
          errorMsg: "Cancel order error",
        })
      );
    }
  });
}

export function* getMitraTransaction() {
  yield takeEvery(
    OrderActions.getMitraTransaction.request,
    function* ({ payload }) {
      const result = yield call(OrderServices.getMitraTransaction, payload);

      if (result.code === 200) {
        yield put(
          OrderActions.getMitraTransaction.success(result.data.results)
        );
      } else {
        yield put(
          OrderActions.getOrderDetail.failure({
            errorMsg: "Get Mitra Available Error",
          })
        );
      }
    }
  );
}

export function* postTransaction() {
  yield takeEvery(
    OrderActions.postTransaction.request,
    function* ({ payload }) {
      const result = yield call(OrderServices.postTransaction, payload);

      if (result.code === 200) {
        yield put(OrderActions.postTransaction.success(result.data))
        notification.success({
          placement: "topRight",
          bottom: 50,
          duration: 3,
          message: "Transaksi berhasil",
        });
      } else {
        notification.error({
          placement: "topRight",
          bottom: 50,
          duration: 3,
          message: "Post Transaction Error",
        });
        yield put(
          OrderActions.getOrderDetail.failure({
            errorMsg: result.message
          })
        );
      }
    }
  )
}

export default function* rootSaga() {
  yield all([fork(getOrder)]);
  yield all([fork(getOrderDetail)]);
  yield all([fork(getMitraAvailable)]);
  yield all([fork(assignMitra)]);
  yield all([fork(cancelOrder)]);
  yield all([fork(getMitraTransaction)]);
  yield all([fork(postTransaction)]);
}
