import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  Collapse,
  Row,
  Col,
  Form,
  Input,
  Button,
  Select,
  Spin,
  Modal,
  Switch,
  Breadcrumb,
} from "antd";
import { InboxOutlined } from '@ant-design/icons';
import { UploadFile } from 'antd/lib/upload/interface';

import * as MitraActions from "../../../state/mitra/mitra.action";
import * as MitraSelector from "../../../state/mitra/mitra.selector";
import * as ServiceActions from "../../../state/service/service.action";
import * as ServiceSelector from "../../../state/service/service.selector";
import { Style } from "./style";
import { IMitra } from "../../../state/mitra/mitra.interface";
import { IService } from "../../../state/service/service.interface";
import { CustomImage, UploadImage } from "../../../components";

const MitraDetail = () => {
  interface pathParams {
    mitraCode: string;
  }

  let { mitraCode } = useParams<pathParams>();
  const dispatch = useDispatch();

  const { Panel } = Collapse;
  const { Option } = Select;
  const [form] = Form.useForm();

  const [isEdit, setIsEdit] = useState(false);
  const [mitraServiceModal, setMitraServiceModal] = useState(false);
  const [areaCode, setAreaCode] = useState<string>("");
  const [images, setImages] = useState({
    image: [],
    ktpImage: [],
    kkImage: [],
    skckImage: [],
  })
  const [imageBase64, setImageBase64] = useState({
    image: '',
    ktpImage: '',
    kkImage: '',
    skckImage: '',
  })
  const [inputDataToForm, setInputDataToForm] = useState(false)


  const businessPartners = useSelector(ServiceSelector.selectBusinessPartners);
  const serviceChild = useSelector(ServiceSelector.selectChildServices);
  const serviceParents = useSelector(ServiceSelector.selectParentServices);
  const mitraServices = useSelector(MitraSelector.selectMitraServices);
  const loadingMitra = useSelector(MitraSelector.selectLoading);
  const mitraSuccess = useSelector(MitraSelector.selectSuccess)

  const currentMitra: IMitra | undefined = useSelector(
    MitraSelector.selectCurrentMitra
  );

  useEffect(() => {
    dispatch(MitraActions.setSelectedMitra({ mitraCode }));
    if (!currentMitra) {
      dispatch(MitraActions.getMitraServices.request({ mitraCode }));
    }
    dispatch(MitraActions.getMitraByCode.request({ mitraCode }));
    if (localStorage.getItem("role") === "admin") {
      dispatch(
        ServiceActions.getBusinessPartner.request({
          page: 1,
        })
      );
    } else {
      dispatch(ServiceActions.getSelfBusinessPartner.request())
    }
  }, [mitraCode, dispatch]);

  useEffect(() => {
    if (currentMitra && businessPartners.length > 0) {
      const select = businessPartners.find(
        (businessPartner) =>
          businessPartner.code === currentMitra.businessPartnerCode
      );

      setAreaCode(select?.areaOfficeCode ? select?.areaOfficeCode : "");
    }
  }, [currentMitra, businessPartners]);

  useEffect(() => {
    if (mitraSuccess) {
      setIsEdit(false)
      dispatch(MitraActions.setSuccessMitraFalse())
    }
  }, [mitraSuccess])

  useEffect(() => {
    serviceParents.map((serviceParent) => {
      return dispatch(
        ServiceActions.getServiceChild.request({
          parentCode: serviceParent.code,
        })
      );
    });
  }, [serviceParents, dispatch]);

  useEffect(() => {
    if (currentMitra && !inputDataToForm) {
      setInputDataToForm(true)
      form.setFieldsValue({
        name: currentMitra.name,
        phone: currentMitra.phone,
        gender: currentMitra.gender,
        imageUrl: currentMitra.imageUrl,
        fullAddress: currentMitra.fullAddress,
        ktpNumber: currentMitra.ktpNumber,
        ktpImage: currentMitra.ktpImage,
        kkImage: currentMitra.kkImage,
        bankName: currentMitra && currentMitra.depositAccount && currentMitra.depositAccount[0] ? currentMitra.depositAccount[0].bankName : '',
        bankNumber: currentMitra && currentMitra.depositAccount && currentMitra.depositAccount[0] ? currentMitra?.depositAccount[0].bankNumber : '',
        emergencyContactName: currentMitra && currentMitra.emergencyContact && currentMitra.emergencyContact[0] ? currentMitra.emergencyContact[0].name : '',
        emergencyContactPhone:  currentMitra && currentMitra.emergencyContact && currentMitra.emergencyContact[0] ? currentMitra.emergencyContact[0].phone : '',
        userPreference: currentMitra.userPreference,
        status: currentMitra.status,
        businessPartnerCode: currentMitra.businessPartnerCode,
        skckImage: currentMitra.skckImage
      });

    setImageBase64({
      image: currentMitra.imageUrl ? currentMitra.imageUrl  : '',
      ktpImage: currentMitra.ktpImage ? currentMitra.ktpImage : '',
      kkImage: currentMitra.kkImage ? currentMitra.kkImage : '',
      skckImage: currentMitra.skckImage ? currentMitra.skckImage : '',
    })
    }
  }, [currentMitra]);

  function onChange(value: any) {
    console.log(`selected ${value}`);
  }

  const onFinish = (values: any) => {
    values.code = mitraCode;
    values.latitude = currentMitra?.latitude;
    values.longitude = currentMitra?.longitude;
    values.image = imageBase64.image
    values.ktpImage = imageBase64.ktpImage
    values.kkImage = imageBase64.kkImage
    values.skckImage = imageBase64.skckImage

    // values.businessPartnercode = currentMitra.businessPartnercode;
    dispatch(MitraActions.editMitra.request(values));
  };

  const onChangeMitraService = (value: boolean, service: IService) => {
    if (value) {
      dispatch(
        MitraActions.insertMitraService.request({
          service: service,
          mitraCode: mitraCode,
        })
      );
    } else {
      dispatch(
        MitraActions.removeMitraService.request({
          service: service,
          mitraCode: mitraCode,
        })
      );
    }
  };

  const onClickMitraService = () => {
    setMitraServiceModal(true);
    if (areaCode) {
      dispatch(
        ServiceActions.getServiceParent.request({
          areaCode: areaCode,
        })
      );
    }
  };

  function itemRender(route, params, routes, paths) {
    const last = routes.indexOf(route) === routes.length - 1;

    return last ? (
      <span>{route.breadcrumbName}</span>
    ) : (
      <Link to={"/" + paths.join("/")}>{route.breadcrumbName}</Link>
    );
  }

  const breadcrumbRoutes = [
    {
      path: "mitra",
      breadcrumbName: "Mitra List",
    },
    {
      path: "detail",
      breadcrumbName: "Mitra Detail",
    },
  ];

  const setStateFileList = (fileList: UploadFile[], name: string) => {
    // eslint-disable-next-line no-undef
    return new Promise(async (resolve) => {
      await setImages({
        ...images,
        [name]: fileList,
      });
      await resolve()
    });
  };

  const sliceFileList = (name: string) => {
    setImages({
      ...images,
      [name]: images[name].splice(0, 1),
    });
  };

  const setStateForm = (result: string | ArrayBuffer | null, name: string) => {
    setImageBase64({
      ...imageBase64,
      [name]: result    
    });
    form.setFieldsValue({
      [name]: result,
    });

  };

  const deleteImage = (name) => {
    if (name === 'image') {
      setImages({...images, image: []})
      setImageBase64({...imageBase64, image: ''})
      form.setFieldsValue({
        image: '',
      });
    } else if (name === 'ktpImage') {
      setImages({...images, ktpImage: []})
      setImageBase64({...imageBase64, ktpImage: ''})
      form.setFieldsValue({
        ktpImage: '',
      });
    } else if (name === 'kkImage') {
      setImages({...images, kkImage: []})
      setImageBase64({...imageBase64, kkImage: ''})
      form.setFieldsValue({
        kkImage:  imageBase64.kkImage,
      });
    } else {
      setImages({...images, skckImage: []})
      setImageBase64({...imageBase64, skckImage: ''})
      form.setFieldsValue({
        skckImage:  imageBase64.skckImage,
      });
    }
    
  }

  return (
    <Style>
      <Breadcrumb itemRender={itemRender} routes={breadcrumbRoutes} />
      {currentMitra ? (
        <>
          <h1>{currentMitra.name}</h1>
          <Row gutter={20}>
            <Col span={12}>
              <Form layout={"vertical"} form={form} onFinish={onFinish}>
                <Collapse defaultActiveKey={["1", "2", "3", "4"]}>
                  <Panel header="Data Diri" key="1">
                    <Form.Item
                      rules={[{ required: true }]}
                      required={true}
                      name="name"
                      label="Nama Mitra"
                    >
                      <Input
                        placeholder="input nama mitra"
                        disabled={!isEdit}
                      />
                    </Form.Item>
                    <Form.Item name="imageUrl">
                      {
                        !isEdit
                        ? (
                          <img
                            src={imageBase64.image}
                            alt="Profile"
                          />
                        )
                        : isEdit && imageBase64.image && imageBase64.image !== ''
                        ? (
                          <CustomImage
                            image={imageBase64.image}
                            command={() => deleteImage('image')}
                          />
                        ) : (
                          <UploadImage
                            setStateFileList={ setStateFileList }
                            sliceFileList={ sliceFileList }
                            setStateForm={ setStateForm }
                            name='image'
                            fileList={ images.image }
                            accept='.png,.jpg'
                            icon={ <InboxOutlined style={{fontSize: '24px'}}/> }
                          />
                        )
                      }
                    </Form.Item>
                    <Form.Item
                      rules={[{ required: true }]}
                      required={true}
                      name="phone"
                      label="Nomor telepon"
                    >
                      <Input
                        placeholder="input nomor telepon"
                        disabled={!isEdit}
                        type="number"
                      />
                    </Form.Item>
                    <Form.Item
                      rules={[{ required: true }]}
                      required={true}
                      name="gender"
                      label="Gender"
                    >
                      <Select
                        showSearch
                        style={{ width: 200 }}
                        placeholder="Pilih gender"
                        optionFilterProp="children"
                        onChange={onChange}
                        disabled={!isEdit}
                      >
                        <Option value="m">Laki-laki</Option>
                        <Option value="f">Perempuan</Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      rules={[{ required: true }]}
                      required={true}
                      name="fullAddress"
                      label="Alamat"
                    >
                      <Input
                        disabled={!isEdit}
                        placeholder="input alamat mitra"
                      />
                    </Form.Item>

                    <Row gutter={15}>
                      <Col span={12}>
                        <Form.Item
                          rules={[{ required: true }]}
                          required={true}
                          name="ktpImage"
                          label="KTP"
                        >
                          {
                            !isEdit
                            ? (
                              <img
                                src={imageBase64.ktpImage}
                                alt="Kartu Tanda Penduduk"
                              />
                            )
                            : isEdit && imageBase64.ktpImage && imageBase64.ktpImage !== ''
                            ? (
                              <CustomImage
                                image={imageBase64.ktpImage}
                                command={() => deleteImage('ktpImage')}
                              />
                            ) : (
                              <UploadImage
                                setStateFileList={ setStateFileList }
                                sliceFileList={ sliceFileList }
                                setStateForm={ setStateForm }
                                name='ktpImage'
                                fileList={ images.ktpImage }
                                accept='.png,.jpg'
                                icon={ <InboxOutlined style={{fontSize: '24px'}}/> }
                              />
                            )
                          }

                        </Form.Item>
                        <Form.Item
                          rules={[{ required: true }]}
                          required={true}
                          name="ktpNumber"
                          label="Nomor KTP"
                        >
                          <Input
                            disabled={!isEdit}
                            placeholder="input nomor KTP mitra"
                            type="number"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          rules={[{ required: true }]}
                          required={true}
                          name="kkImage"
                          label="Kartu Keluarga"
                        >
                          {
                            !isEdit
                            ? (
                              <img
                                src={imageBase64.kkImage}
                                alt="Kartu Keluarga"
                              />
                            )
                            : isEdit && imageBase64.kkImage && imageBase64.kkImage !== ''
                            ? (
                              <CustomImage
                                image={imageBase64.kkImage}
                                command={() => deleteImage('kkImage')}
                              />
                            ) : (
                              <UploadImage
                                setStateFileList={ setStateFileList }
                                sliceFileList={ sliceFileList }
                                setStateForm={ setStateForm }
                                name='kkImage'
                                fileList={ images.kkImage }
                                accept='.png,.jpg'
                                icon={ <InboxOutlined style={{fontSize: '24px'}}/> }
                              />
                            )
                          }
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item
                      rules={[{ required: true }]}
                      required={true}
                      name="skckImage"
                      label="Surat Keterangan Catatan Kepolisian"
                    >
                      {
                        !isEdit
                        ? (
                          <img
                            src={imageBase64.skckImage}
                            alt="Kartu Keluarga"
                          />
                        )
                        : isEdit && imageBase64.skckImage && imageBase64.skckImage !== ''
                        ? (
                          <CustomImage
                            image={imageBase64.skckImage}
                            command={() => deleteImage('skckImage')}
                          />
                        ) : (
                          <UploadImage
                            setStateFileList={ setStateFileList }
                            sliceFileList={ sliceFileList }
                            setStateForm={ setStateForm }
                            name='skckImage'
                            fileList={ images.skckImage }
                            accept='.png,.jpg'
                            icon={ <InboxOutlined style={{fontSize: '24px'}}/> }
                          />
                        )
                      }
                    </Form.Item>
                  </Panel>
                  <Panel header="Deposit Account" key="2">
                    <Row gutter={15}>
                      <Col span={12}>
                        <Form.Item
                          rules={[{ required: true }]}
                          required={true}
                          name="bankName"
                          label="Nama Bank"
                        >
                          <Select
                            showSearch
                            placeholder="Pilih Bank"
                            optionFilterProp="children"
                            onChange={onChange}
                            disabled={!isEdit}
                            >
                            <Option value="bca">BCA</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        {" "}
                        <Form.Item
                          rules={[{ required: true }]}
                          required={true}
                          name="bankNumber"
                          label="Nomor Bank"
                        >
                          <Input
                            disabled={!isEdit}
                            placeholder="input nomor bank mitra"
                            type="number"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Panel>

                  <Panel header="Emergency Contact" key="3">
                    <Form.Item
                      rules={[{ required: true }]}
                      required={true}
                      name="emergencyContactName"
                      label="Nama Emergency Contact"
                    >
                      <Input
                        disabled={!isEdit}
                        placeholder="input nama emergency contact"
                      />
                    </Form.Item>
                    <Form.Item
                      rules={[{ required: true }]}
                      required={true}
                      name="emergencyContactPhone"
                      label="Nomor Telepon"
                    >
                      <Input
                        disabled={!isEdit}
                        placeholder="input nomor telepon emergency"
                        type="number"
                      />
                    </Form.Item>
                  </Panel>
                  <Panel header="YOBEN Setting" key="4">
                    <Form.Item
                      name="userPreference"
                      label="Preferensi customer"
                    >
                      <Select
                        style={{ width: 200 }}
                        placeholder="Pilih Preferensi"
                        optionFilterProp="children"
                        onChange={onChange}
                        disabled={!isEdit}
                      >
                        <Option value="m">Laki-laki</Option>
                        <Option value="f">Perempuan</Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      rules={[{ required: true }]}
                      required={true}
                      name="businessPartnerCode"
                      label="Business Partner"
                      style={{
                        display:
                          localStorage.getItem("role") === "businessPartner"
                            ? "none"
                            : "",
                      }}
                    >
                      <Select
                        showSearch
                        style={{ width: 200 }}
                        placeholder="Pilih Business Partner"
                        optionFilterProp="children"
                        onChange={onChange}
                        disabled={!isEdit}
                      >
                        {businessPartners.map((businessPartner) => {
                          return (
                            <Option value={businessPartner.code}>
                              {businessPartner.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      rules={[{ required: true }]}
                      required={true}
                      name="status"
                      label="Status"
                    >
                      <Select
                        style={{ width: 200 }}
                        placeholder="Select status"
                        optionFilterProp="children"
                        onChange={onChange}
                        disabled={!isEdit}
                        
                      >
                        <Option value="active">active</Option>
                        <Option value="inactive">inactive</Option>
                      </Select>
                    </Form.Item>
                  </Panel>
                </Collapse>
                <div
                  className="section-edit-button"
                  style={{
                    display:
                      localStorage.getItem("role") === "businessPartner"
                        ? "none"
                        : "",
                  }}
                >
                  {isEdit ? (
                    <Row justify="end" gutter={20}>
                      <Col>
                        <Button
                          onClick={() => setIsEdit(!isEdit)}
                          type="default"
                          htmlType="submit"
                        >
                          Cancel
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          loading={loadingMitra}
                          type="primary"
                          htmlType="submit"
                        >
                          Submit Edit
                        </Button>
                      </Col>
                    </Row>
                  ) : (
                    <Button onClick={() => setIsEdit(!isEdit)} type="primary">
                      Edit
                    </Button>
                  )}
                </div>
              </Form>
            </Col>
            <Col span={12}>
              {mitraServices ? (
                <div>
                  <Button type="primary" onClick={() => onClickMitraService()}>
                    Mitra Services
                  </Button>
                  <Modal
                    title="Mitra Services"
                    visible={mitraServiceModal}
                    onOk={() => setMitraServiceModal(false)}
                    onCancel={() => setMitraServiceModal(false)}
                    footer={[
                      <Button
                        type="primary"
                        key="Ok"
                        onClick={() => setMitraServiceModal(false)}
                      >
                        Okay
                      </Button>,
                    ]}
                  >
                    <h3>LAYANAN</h3>
                    {serviceParents.map((serviceParent) => {
                      return (
                        <>
                          <h3>{serviceParent.name}</h3>
                          {serviceChild
                            .filter(
                              (service: IService) =>
                                service.parentCode === serviceParent.code
                            )
                            .map((service: IService) => {
                              return (
                                <div
                                  style={{
                                    marginBottom: 10,
                                  }}
                                >
                                  <Switch
                                    style={{
                                      marginRight: 10,
                                    }}
                                    checked={mitraServices.find(
                                      (mitraService: IService) =>
                                        mitraService.code === service.code
                                    )}
                                    onChange={(status) =>
                                      onChangeMitraService(status, service)
                                    }
                                  ></Switch>
                                  {service.name}
                                </div>
                              );
                            })}
                        </>
                      );
                    })}
                  </Modal>
                </div>
              ) : (
                <p>loading</p>
              )}
            </Col>
          </Row>
        </>
      ) : (
        <Row justify="center">
          <Spin size="large" />
        </Row>
      )}
    </Style>
  );
};

export default MitraDetail;