import styled from 'styled-components'

type Props = {
  height?: string
}

const Container = styled.div<Props>`
  .ant-upload-drag-container {
    height: ${(props) => props.height ? props.height : '100%'};
  }
`

export {Container}