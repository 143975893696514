import { createSelector } from "reselect";
import { IOrderReducer } from "./order.interface";

const orderReducer = (state: any) => state.order;

const selectOrder = createSelector(
  orderReducer,
  (order: IOrderReducer) => order.orders
);

const selectLoading = createSelector(
  orderReducer,
  (order: IOrderReducer) => order.loading
);

const selectUnassignedOrders = createSelector(
  orderReducer,
  (order: IOrderReducer) => order.unassignedOrders
);

const selectAvailableMitras = createSelector(
  orderReducer,
  (order: IOrderReducer) => order.availableMitras
);

const selectSuccess = createSelector(
  orderReducer,
  (order: IOrderReducer) => order.success
);

const selectTransactions = createSelector(
  orderReducer,
  (order: IOrderReducer) => order.transactions
)

const selectLazyLoad = createSelector(
  orderReducer,
  (order: IOrderReducer) => order.lazyLoad
)

export {
  selectOrder,
  selectLoading,
  selectUnassignedOrders,
  selectAvailableMitras,
  selectSuccess,
  selectTransactions,
  selectLazyLoad
};
