import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Table, Button, Divider, Row, Col, Modal, Form, Input, Select, Popconfirm } from "antd";
import { UploadFile } from 'antd/lib/upload/interface';

import * as MitraActions from "../../../state/mitra/mitra.action";
import * as OrderActions from "../../../state/order/order.action";

import * as MitraSelector from "../../../state/mitra/mitra.selector";
import * as OrderSelectors from "../../../state/order/order.selector";

import { Style, OrderTab, Transaction } from "./style";
import { IMitra } from "../../../state/mitra/mitra.interface";
import { CustomImage, OrderModal, SimplePagination, UploadImage } from "../../../components";
import { IOrder, IOrderTransaction } from "../../../state/order/order.interface";
import { Layout } from "../../../const";

const MitraTransaction = (props: {
  history: any
}) => {
  interface pathParams {
    mitraCode: string;
  }

  let { mitraCode } = useParams<pathParams>();
  const dispatch = useDispatch();
  const limit = 10;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [tab, setTab] = useState<string>('onGoing');
  const [modalActive, setModalActive] = useState<boolean>(false);
  const [modalTransaction, setModalTransaction] = useState<boolean>(false);
  const [modalCreateTransaction, setModalCreateTransaction] = useState<boolean>(false);
  const [orderCode, setOrderCode] = useState<string>("");
  const currentMitra: IMitra | undefined = useSelector(
    MitraSelector.selectCurrentMitra
  );
  
  const mitraTransaction = useSelector(
    MitraSelector.selectMitraTransaction
  );
  const lazyLoad = useSelector(OrderSelectors.selectLazyLoad);
  const isLoading = useSelector(OrderSelectors.selectLoading);
  const isSuccess = useSelector(OrderSelectors.selectSuccess);
  const orderOnGoing: IOrder[] = useSelector(MitraSelector.selectOrderOnGoing);
  const orderHistory: IOrder[] = useSelector(MitraSelector.selectOrderHistory);

  const [form] = Form.useForm();
  const [images, setImages] = useState<any>([]);
  const [imageBase64, setImageBase64]= useState<any>('')

  const setStateFileList = (fileList: UploadFile[], name: string) => {
    // eslint-disable-next-line no-undef
    return new Promise(async (resolve) => {
      await setImages(fileList);
      await resolve()
    });
  };

  const sliceFileList = (name: string) => {
    setImages(images[name].splice(0, 1));
  };

  const setStateForm = (result: string | ArrayBuffer | null, name: string) => {
    if (result) {
      setImageBase64(result)
    }
  };

  useEffect(() => {
    dispatch(MitraActions.setSelectedMitra({ mitraCode }));
    if (!currentMitra) {
      dispatch(MitraActions.getMitraByCode.request({ mitraCode }));
    }
    dispatch(MitraActions.getMitraBalance.request({ mitraCode }));
  }, [mitraCode, dispatch]);

  useEffect(() => {
    if (tab === 'onGoing') {
      dispatch(MitraActions.getOrderOnGoing.request({ mitraCode, limit, skip: limit * (currentPage - 1) }));
    } else if (tab === 'history') {
      dispatch(MitraActions.getOrderHistory.request({ mitraCode, limit, skip: limit * (currentPage - 1) }));
    }
  }, [currentPage, tab])

  useEffect(() => {
    if (modalTransaction) {
      dispatch(OrderActions.getMitraTransaction.request({ mitraCode, limit: 20, skip: 0}))
    }
  }, [modalTransaction])

  useEffect(() => {
    if (isSuccess) {
      setModalCreateTransaction(false)
    }
  }, [isSuccess])

  const columns = [
    {
      title: "Kode",
      dataIndex: "code",
      key: "code",
      render: (text: string) => <p>{text}</p>,
    },
    {
      title: "Waktu",
      dataIndex: "workTime",
      key: "workTime",
      render: (text: any) => (
        <p>
          {moment(text).format("ll")} - {moment(text).format("HH:mm")}
        </p>
      )
    },
    {
      title: "Service",
      dataIndex: "service",
      key: "service-name",
      render: (service: any) => <p>{service.name}</p>,
    },
    {
      title: "Durasi (menit)",
      dataIndex: "duration",
      key: "duration",
      render: (duration: any) => <p>{duration.duration / 60}</p>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Action",
      key: "action",
      render: (order: any) => {
        return (
          <Button 
          onClick={() => onClickOrderDetail(order)}
          >Detail</Button>
        );
      },
    },
  ];

  const onClickOrderDetail = (order: IOrder) => {
    setModalActive(true);
    setOrderCode(order.code);
  }

  const renderOnGoingOrder = () => {
    return (
      <>
        <Table
          loading={isLoading}
          columns={columns}
          dataSource={orderOnGoing}
          {...Layout.table}
        />
        <SimplePagination
          currentPage={currentPage}
          onChange={setCurrentPage}
          dataLength={orderOnGoing.length}
        />
      </>
    )
  }

  const renderHistoryOrder = () => {
    return (
      <>
        <Table
          loading={isLoading}
          columns={columns}
          dataSource={orderHistory}
          {...Layout.table}
        />
        <SimplePagination
          currentPage={currentPage}
          onChange={setCurrentPage}
          dataLength={orderHistory.length}
        />
      </>
    )
  }

  const onFinishTransaction = () => {
    let values = form.getFieldsValue();
    dispatch(OrderActions.postTransaction.request({
      notes: values.notes,
      amount: values.amount,
      receiptImage: imageBase64,
      paymentType: values.paymentType,
      type: values.type,
      mitraCode: mitraCode
    }))
  }

  const deleteImage = () => {
    setImageBase64('');
    setImages([]);
  }

  return (
    <Style>
      <Row justify="space-between">
        <h1>Mitra Transaction - {currentMitra?.name}</h1>
        <Button onClick={() => props.history.push(`/mitra/${currentMitra?.code}/profile`)}>Profil Mitra</Button>
      </Row>

      <div>
        <h1>Balance: <a className="balance-clickable" onClick={() => setModalTransaction(true)}>{mitraTransaction.balance}</a></h1>
      </div>
      <div>
        <Row justify="space-between">
          <Row>
            <Col onClick={() => {
              setTab('onGoing');
              setCurrentPage(1);
            }}>
              <OrderTab selected={tab === 'onGoing' ? true : false}>
                <p>Active Order</p>
              </OrderTab>
            </Col>
            <Col onClick={() => {
              setTab('history');
              setCurrentPage(1);
            }}>
              <OrderTab selected={tab === 'history' ? true : false}>
              <p>History</p>
              </OrderTab>
            
            </Col>
          </Row>
          {
            localStorage.getItem('role') === 'admin' ? <Row>
            <Button onClick={() => {
                setModalCreateTransaction(true);
                form.resetFields();
                setImageBase64('');
                setImages([]);
              }}>Create Transaction</Button>
            </Row>: null
          }
        </Row>
        <Divider className="divider-container"></Divider>
        {
          tab === 'onGoing' ? renderOnGoingOrder() : renderHistoryOrder()
        }
      </div>
      
      <OrderModal 
        active={modalActive}
        onChangeActive={setModalActive}
        orderCode={orderCode}
      />

      <Modal 
        visible={modalTransaction}
        onCancel={() => setModalTransaction(false)}
      >
        <h1>Transaction History</h1>
        <Table
          dataSource={useSelector(OrderSelectors.selectTransactions)}
          columns={[
            {
              title: "date",
              dataIndex: 'u_at',
              key: 'u_at',
              render: (u_at: string) => <p>{moment(u_at).format("ll")}</p>
            },
            {
              title: "name",
              dataIndex: 'name',
              key:'name'
            },
            {
              title: "amount",
              key:'amount',
              render: (transaction: IOrderTransaction) => {
                if (transaction.type === 'withdraw') {
                  return <Transaction type="withdraw">- {transaction.amount}</Transaction>
                } else if (transaction.type === 'deposit') {
                  return <Transaction type="deposit">{transaction.amount}</Transaction>
                }
                
              }
            },
          ]}
          {...Layout.table}
        >
        </Table>
      </Modal>
      <Modal
        visible={modalCreateTransaction}
        onCancel={() => setModalCreateTransaction(false)}
        footer={[
          <Popconfirm
            title="Apakah transaksi sudah benar?"
            onConfirm={onFinishTransaction}
          >
            <Button
              type="primary"
              // key="submit"
              // htmlType="submit"
              form="transactionForm"
              loading={lazyLoad.transaction}
            >
              Okay
            </Button>
          </Popconfirm>,
        ]}
      >
        <h1>Create Transaction</h1>
        <Form id="transactionForm" form={form} layout={"vertical"} onFinish={onFinishTransaction}>
          <Form.Item
            name="type"
            label="Transaksi"
            rules={[{ required: true }]}
            required={true}
          >
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder="Pilih transaksi"
              optionFilterProp="children"
            >
              <Select.Option value="withdraw">Withdraw</Select.Option>
              <Select.Option value="deposit">Deposit</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="paymentType"
            label="Tipe pembayaran"
            rules={[{ required: true }]}
            required={true}
          >
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder="Pilih tipe pembayaran"
              optionFilterProp="children"
            >
              <Select.Option value="cash">Cash</Select.Option>
              <Select.Option value="bank_transfer">Bank Transfer</Select.Option>
            </Select>
          </Form.Item>
          
          <Form.Item 
            rules={[{ required: true }]}
            required={true}
            name="amount"
            label="Amount"
          >
            <Input placeholder="amount" type="number"/>
          </Form.Item>
          <Form.Item 
            rules={[{ required: true }]}
            required={true}
            name="notes"
            label="Notes"
          >
            <Input.TextArea placeholder="notes" rows={3}/>
          </Form.Item>
          <Form.Item
            required={true}
            name="receiptImage"
            label="Bukti Transfer"
          >
            {imageBase64 !== '' ? <CustomImage image={imageBase64} command={deleteImage}/> : 
              <UploadImage
                setStateFileList={ setStateFileList }
                sliceFileList={ sliceFileList }
                setStateForm={ setStateForm }
                fileList={ images }
                name='receiptImage'
                accept='.png,.jpg'
              />
            }
          </Form.Item>
        </Form>
      </Modal>
    </Style>
  );
};

export default MitraTransaction;
