import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Button, Modal, Row, Form, Input } from "antd";
import { UploadFile } from 'antd/lib/upload/interface';
import { InboxOutlined } from '@ant-design/icons';

import * as ServiceActions from "../../../state/service/service.action";
import * as ServiceSelectors from "../../../state/service/service.selector";
import { IBusinessPartner } from "../../../state/service/service.interface";
import { Layout } from "../../../const";
import {CustomImage, UploadImage, SimplePagination } from '../../../components'

const BusinessPartners = () => {
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [
    selectedBusinessPartner,
    setSelectedBusinessPartner,
  ] = useState<IBusinessPartner>({
    code: "",
    name: "",
    email: "",
    phone: "",
    imageUrl: "",
    areaOfficeCode: "",
  });
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [images, setImages] = useState<any>([])
  const [imageBase64, setImageBase64]= useState<any>('')

  const businessPartners = useSelector(ServiceSelectors.selectBusinessPartners);
  const isLoading = useSelector(ServiceSelectors.selectLoading);
  const isSuccess = useSelector(ServiceSelectors.selectSuccess);
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(
      ServiceActions.getBusinessPartner.request({
        page: currentPage,
      })
    );
  }, [currentPage]);

  useEffect(() => {
    if (isSuccess) {
      setIsModalOpen(false);
      setImageBase64('')
      setImages([])
      setIsEdit(false)
      form.resetFields();
    }
  }, [isSuccess]);

  const columns = [
    {
      title: "Kode",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Nama",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Area",
      dataIndex: "areaOfficeCode",
      key: "areaOfficeCode",
    },
    {
      title: "Action",
      key: "action",
      render: (businessPartner: IBusinessPartner) => {
        return (
          <Button onClick={() => onClickEdit(businessPartner)}>Edit</Button>
        );
      },
    },
  ];

  const onClickEdit = (businessPartner: IBusinessPartner) => {
    setSelectedBusinessPartner(businessPartner);
    setIsModalOpen(true);
    setIsEdit(true);
    form.setFieldsValue({
      name: businessPartner.name,
      phone: businessPartner.phone,
      imageUrl: businessPartner.imageUrl,
      email: businessPartner.email,
      longitude: businessPartner.longitude,
      latitude: businessPartner.latitude,
      areaOfficeCode: businessPartner.areaOfficeCode,
    });
    setImageBase64(businessPartner.imageUrl)
  };

  const handleFormBusinessPartner = (value) => {
    if (isEdit) {
      dispatch(
        ServiceActions.editBusinessPartner.request({
          businessPartner: {...value, imageUrl: imageBase64},
          businessPartnerCode: selectedBusinessPartner.code,
        })
      );
    } else {
      dispatch(ServiceActions.registerBusinessPartner.request({...value, imageUrl: imageBase64}));
    }
  };

  const onClickDelete = () => {
    dispatch(
      ServiceActions.deleteBusinessPartner.request({
        businessPartnerCode: selectedBusinessPartner.code,
      })
    );
  };

  const setStateFileList = (fileList: UploadFile[], name: string) => {
    // eslint-disable-next-line no-undef
    return new Promise(async (resolve) => {
      await setImages(fileList);
      await resolve()
    });
  };

  const sliceFileList = (name: string) => {
    setImages(images[name].splice(0, 1));
  };

  const setStateForm = (result: string | ArrayBuffer | null, name: string) => {
    setImageBase64(result);
    form.setFieldsValue({
      imageUrl: result,
    });
  };

  const deleteImage = () => {
    setImages([])
    setImageBase64('')
    form.setFieldsValue({
      imageUrl:  '',
    });
  }

  const renderModalCrateBusinessPartner = () => {
    return (
      <Form
        id="businessPartnerForm"
        onFinish={handleFormBusinessPartner}
        form={form}
        {...Layout.formItem}
      >
        <Modal
          title={isEdit ? "Edit Business Partner" : "Create Business Partner"}
          visible={isModalOpen}
          onCancel={() => {
            if (!isLoading) {
              setIsModalOpen(false);
              setImageBase64('')
              setImages([])
              setIsEdit(false)
              form.resetFields();
            }
          }}
          footer={[
            <Button
              style={{
                display: isEdit ? "" : "none",
              }}
              danger
              disabled={isLoading}
              onClick={() => onClickDelete()}
            >
              Delete
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={isLoading}
              htmlType="submit"
              form="businessPartnerForm"
            >
              {isEdit ? "Edit" : "Submit"}
            </Button>,
          ]}
        >
          <Form.Item
            label="Nama"
            name="name"
            rules={[{ required: true }]}
            hasFeedback
          >
            <Input placeholder={"name"} />
          </Form.Item>
          <Form.Item
            rules={[{ required: true }]}
            required={true}
            name="imageUrl"
            label="Profile Image"
          >
            {
              !isEdit && imageBase64 === ''
              || isEdit && imageBase64 === ''
              ? (
                <UploadImage
                  setStateFileList={ setStateFileList }
                  sliceFileList={ sliceFileList }
                  setStateForm={ setStateForm }
                  name='skckImage'
                  fileList={ images.skckImage }
                  height='300px'
                  accept='.png,.jpg'
                  icon={ <InboxOutlined style={{fontSize: '24px'}}/> }
                />
              )
              : !isEdit && imageBase64 !== ''
              || isEdit && imageBase64 !== '' 
              ? (
                <CustomImage
                  image={imageBase64}
                  command={() => deleteImage()}
                />
              ) : (
                <img
                  src={imageBase64}
                  alt="Profile Image"
                />
              )
            }
          </Form.Item>
          <Form.Item
            label="Phone"
            name="phone"
            rules={[{ required: true }]}
            hasFeedback
          >
            <Input type="number" placeholder={"phone"} />
          </Form.Item>

          <Form.Item
            name="email"
            label="Email"
            rules={[{ required: true, type: "email" }]}
            hasFeedback
          >
            <Input type="email" placeholder={"email"} />
          </Form.Item>
          {isEdit ? null : (
            <>
              <Form.Item
                label="Password"
                name="password"
                rules={[{ required: true }]}
                hasFeedback
              >
                <Input.Password type="password" placeholder={"password"} />
              </Form.Item>
              <Form.Item
                label="Confirm Password"
                name="confirmPassword"
                dependencies={["password"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        "The two passwords that you entered do not match!"
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  type="password"
                  placeholder={"confirm password"}
                />
              </Form.Item>
            </>
          )}
          <Form.Item
            label="Latitude"
            name="latitude"
            rules={[{ required: true }]}
            hasFeedback
          >
            <Input type="number" placeholder={"latitude"} />
          </Form.Item>
          <Form.Item
            label="Longitude"
            name="longitude"
            rules={[{ required: true }]}
            hasFeedback
          >
            <Input type="number" placeholder={"longitude"} />
          </Form.Item>
          <Form.Item
            label="Area Office Code"
            name="areaOfficeCode"
            rules={[{ required: true }]}
            hasFeedback
          >
            <Input placeholder={"area office code"} />
          </Form.Item>
        </Modal>
      </Form>
    );
  };

  return (
    <>
      <h1>Business Partner</h1>
      <Row
        justify="end"
        style={{
          marginBottom: "20px",
        }}
      >
        <Button
          onClick={() => {
            setIsModalOpen(true);
            setIsEdit(false);
          }}
        >
          Create
        </Button>
      </Row>
      <Table
        loading={isLoading}
        dataSource={businessPartners}
        columns={columns}
        {...Layout.table}
      />
      <SimplePagination
        currentPage={currentPage}
        onChange={setCurrentPage}
        dataLength={businessPartners.length}
      />
      {renderModalCrateBusinessPartner()}
    </>
  );
};

export default BusinessPartners;
