import { createReducer } from "typesafe-actions";

import * as MitraActions from "./mitra.action";
import * as OrderActions from '../order/order.action';
import { MitraActionType } from "./mitra.actionType";
import { IMitraReducer, ITransactionMitra } from "./mitra.interface";
import { IService } from "../service/service.interface";
import { removeOne } from "../../helpers";

const initialState: IMitraReducer = {
  mitraList: [],
  transactionList: [],
  mitraServices: [],
  selectedMitraCode: "",
  mitraTransaction: {
    balance: 0
  },
  mitraOrder: {
    onGoing: [],
    history: []
  },
  loading: false,
  errorMsg: "",
  success: false,
};

let mitraReducer = createReducer(initialState)
  .handleAction(MitraActions.getMitra.request, (state = initialState) => ({
    ...state,
    loading: true,
  }))
  .handleAction(
    MitraActions.getMitra.success,
    (state = initialState, action: ReturnType<typeof MitraActions.getMitra.success>) => {
      return {
        ...state,
        loading: false,
        mitraList: action.payload.mitra,
      };
    }
  )
  .handleAction(
    MitraActionType.SetSelectedMitraCode,
    (state = initialState, { payload }: any) => ({
      ...state,
      selectedMitraCode: payload.mitraCode,
    })
  )
  .handleAction(
    MitraActions.getMitraByCode.request,
    (state = initialState) => ({
      ...state,
      loading: true,
    })
  )
  .handleAction(
    MitraActions.getMitraByCode.success,
    (state = initialState, { payload }: any) => {
      let currentMitraList = [...state.mitraList];

      let isMitraExist = currentMitraList.findIndex(
        (mitra) => mitra.code === payload.mitra.code
      );

      if (isMitraExist === -1) {
        currentMitraList.push(payload.mitra);
      }

      return {
        ...state,
        loading: false,
        mitraList: currentMitraList,
      };
    }
  )
  .handleAction(MitraActions.editMitra.request, (state = initialState) => ({
    ...state,
    loading: true,
    success: false
  }))
  .handleAction(
    MitraActions.editMitra.success,
    (state = initialState, { payload }: any) => {
      let currentMitraList = [...state.mitraList];

      let mitraIndex = currentMitraList.findIndex(
        (mitra) => mitra.code === payload.mitra.code
      );

      currentMitraList[mitraIndex] = payload.mitra;

      return {
        ...state,
        loading: false,
        mitraList: currentMitraList,
        success: true
      };
    }
  )
  .handleAction(
    MitraActions.getMitraServices.request,
    (state = initialState) => ({
      ...state,
      loading: true,
    })
  )
  .handleAction(
    MitraActions.getMitraServices.success,
    (state = initialState, { payload }: any) => {
      return {
        ...state,
        loading: false,
        mitraServices: payload.services,
      };
    }
  )
  .handleAction(
    MitraActions.insertMitraService.success,
    (
      state = initialState,
      action: ReturnType<typeof MitraActions.insertMitraService.success>
    ) => ({
      ...state,
      loading: true,
      mitraServices: [
        ...state.mitraServices,
        {
          name: action.payload.service.name,
          code: action.payload.service.code,
        },
      ],
    })
  )
  .handleAction(
    MitraActions.removeMitraService.success,
    (
      state = initialState,
      action: ReturnType<typeof MitraActions.removeMitraService.success>
    ) => {
      let currentMitraServices = [...state.mitraServices];
      let index = currentMitraServices.findIndex(
        (mitraService: IService) =>
          mitraService.code == action.payload.service.code
      );

      if (index > -1) {
        currentMitraServices.splice(index, 1);
      }

      return {
        ...state,
        loading: true,
        mitraServices: currentMitraServices,
      };
    }
  )
  .handleAction(MitraActions.registerMitra.request, (state = initialState) => ({
    ...state,
    loading: true,
  }))
  .handleAction(
    MitraActions.registerMitra.success,
    (state = initialState, { payload }: any) => {
      let newMitraList = [...state.mitraList];
      newMitraList.push(payload.mitra);
      return {
        ...state,
        loading: false,
        mitraList: newMitraList,
      };
    }
  )
  .handleAction(
    MitraActions.getTransaction.request,
    (state = initialState) => ({
      ...state,
      loading: true,
    })
  )
  .handleAction(
    MitraActions.getTransaction.success,
    (
      state = initialState,
      action: ReturnType<typeof MitraActions.getTransaction.success>
    ) => {
      return {
        ...state,
        loading: false,
        transactionList: action.payload.transactions,
      };
    }
  )
  .handleAction(
    MitraActions.editTransactionStatus.request,
    (state = initialState) => ({
      ...state,
      loading: true,
      success: false,
    })
  )
  .handleAction(
    MitraActions.editTransactionStatus.success,
    (state = initialState, { payload }: any) => {
      let newTransactionList = [...state.transactionList];

      let index = newTransactionList.findIndex(
        (transaction: ITransactionMitra) =>
          transaction.code === payload.transaction.code
      );

      newTransactionList[index].status = payload.transaction.status;
      newTransactionList[index].amount = payload.transaction.amount;

      return {
        ...state,
        loading: false,
        success: true,
        transactionList: newTransactionList,
      };
    }
  )
  .handleAction(
    MitraActions.getMitraBalance.request,
    (state = initialState) => ({
      ...state,
    })
  )
  .handleAction(
    MitraActions.getMitraBalance.success,
    (
      state = initialState,
      action: ReturnType<typeof MitraActions.getMitraBalance.success>
    ) => {
      let currentMitraTransaction = {...state.mitraTransaction}
      currentMitraTransaction.balance = action.payload.amount
      return {
        ...state,
        mitraTransaction: currentMitraTransaction
      };
    }
  )
  .handleAction(
    MitraActions.getOrderOnGoing.request,
    (state = initialState) => ({
      ...state,
    })
  )
  .handleAction(
    MitraActions.getOrderOnGoing.success,
    (
      state = initialState,
      action: ReturnType<typeof MitraActions.getOrderOnGoing.success>
    ) => {
      let mitraOrder = {...state.mitraOrder};
      mitraOrder.onGoing = action.payload.orders
      return {
        ...state,
        mitraOrder
      };
    }
  )
  .handleAction(
    MitraActions.getOrderHistory.request,
    (state = initialState) => ({
      ...state,
    })
  )
  .handleAction(
    MitraActions.getOrderHistory.success,
    (
      state = initialState,
      action: ReturnType<typeof MitraActions.getOrderHistory.success>
    ) => {
      let mitraOrder = {...state.mitraOrder};
      mitraOrder.history = action.payload.orders
      return {
        ...state,
        mitraOrder
      };
    }
  )
  .handleAction(
    OrderActions.cancelOrder.success,
    (state = initialState, { payload }) => {
      let onGoingOrders = [...state.mitraOrder.onGoing];
      let index = onGoingOrders.findIndex(order => order.code === payload.orderCode);
      if (index > -1) {
        return {
          ...state,
          mitraOrder: {
            onGoing: removeOne(state.mitraOrder.onGoing, payload.orderCode),
            history: state.mitraOrder.history
          }
        }
      } else {
        return state;
      }
    }
  )
  .handleAction(
    OrderActions.postTransaction.success,
    (state = initialState, { payload }) => {
      let updatedBalance = state.mitraTransaction.balance;
      if (payload.type === 'deposit') {
        updatedBalance += payload.amount;
      } else if (payload.type === 'withdraw') {
        updatedBalance -= payload.amount;
      }
      return {
        ...state,
        mitraTransaction: {
          balance: updatedBalance
        }
      }
    }
  )
  .handleAction(MitraActionType.SETSUCCESSFALSE, (state = initialState) => ({
    ...state,
    success: false
  }))
  .handleAction(
    MitraActionType.MitraError,
    (
      state = initialState,
      action: {
        payload: {
          errorMsg: string;
        };
      }
    ) => ({
      ...state,
      loading: false,
      success: false,
      errorMsg: action.payload.errorMsg,
    })
  );

export default mitraReducer;
