import { all, takeEvery, fork, call, put } from "redux-saga/effects";
import { notification } from "antd";

import * as UserActions from "./user.action";
import * as UserServices from "../../services/user.service";
import { Http } from "../../const";

export function* getAdmin() {
  yield takeEvery(UserActions.getAdmin.request, function* ({ payload }) {
    const result = yield call(UserServices.getAdmin, {
      limit: 10,
      skip: Http.skip(payload.page, 10),
    });

    if (result.code === 200) {
      yield put(UserActions.getAdmin.success(result.data.results));
    } else {
      yield put(
        UserActions.getAdmin.failure({
          errorMsg: "Get Admin Error",
        })
      );
    }
  });
}

export function* createAdmin() {
  yield takeEvery(UserActions.createAdmin.request, function* ({ payload }) {
    const result = yield call(UserServices.createAdmin, payload);

    if (result.code === 200) {
      notification.success({
        placement: "topRight",
        bottom: 50,
        duration: 3,
        message: "Create Admin Success",
      });
      yield put(UserActions.createAdmin.success(result.data));
    } else {
      yield put(
        UserActions.editAdmin.failure({
          errorMsg: "Get Admin Error",
        })
      );
    }
  });
}

export function* editAdmin() {
  yield takeEvery(UserActions.editAdmin.request, function* ({ payload }) {
    const result = yield call(UserServices.editAdmin, payload);
    if (result.code === 200) {
      notification.success({
        placement: "topRight",
        bottom: 50,
        duration: 3,
        message: "Edit Admin Success",
      });
      yield put(UserActions.editAdmin.success(result.data));
    } else {
      yield put(
        UserActions.editAdmin.failure({
          errorMsg: "Edit Admin Error",
        })
      );
    }
  });
}

export function* deleteAdmin() {
  yield takeEvery(UserActions.deleteAdmin.request, function* ({ payload }) {
    const result = yield call(UserServices.deleteAdmin, payload.adminId);
    if (result.code === 202) {
      notification.success({
        placement: "topRight",
        bottom: 50,
        duration: 3,
        message: "Delete Admin Success",
      });
      yield put(
        UserActions.deleteAdmin.success({
          adminId: payload.adminId,
        })
      );
    } else {
      yield put(
        UserActions.deleteAdmin.failure({
          errorMsg: "Delete Admin Error",
        })
      );
    }
  });
}

export default function* rootSaga() {
  yield all([fork(getAdmin)]);
  yield all([fork(createAdmin)]);
  yield all([fork(editAdmin)]);
  yield all([fork(deleteAdmin)]);
}
