import React, { Fragment, useEffect, useState } from "react";
import { BackTop, Layout, Drawer, Menu, Avatar } from "antd";
import { isMobile, isMobileOnly } from "react-device-detect";
import { useRouteMatch, Link } from "react-router-dom";

import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
  PieChartOutlined,
  DesktopOutlined,
  SettingOutlined,
  CustomerServiceFilled,
} from "@ant-design/icons";

import AppRouter from "../../routes/app";
import styles from "./styles.scss";

const Main = (props) => {
  const { Header, Footer, Sider, Content } = Layout;
  const [collapsed, setCollapsed] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState(["2"]);
  const [openSubKeys, setOpenSubKeys] = useState([""]);

  let match = useRouteMatch();

  let onClickSignOut = () => {
    localStorage.clear();
    props.history.push("/login");
  };

  useEffect(() => {
    setSelectedKeys(window.location.pathname);

    if (window.location.pathname.includes("/settings")) {
      setOpenSubKeys(["/settings"]);
    }
  }, [window.location.pathname]);

  useEffect(() => {
    if (isMobile) {
      setCollapsed(true);
    }
  }, isMobile);

  const rightContent = [
    <Menu key="user" mode="horizontal" onClick={() => onClickSignOut()}>
      <Menu.SubMenu
        title={
          <Fragment>
            <span style={{ color: "#999", marginRight: 4 }}>Hi,</span>
            <span>{localStorage.getItem("name")}</span>
            {/* <Avatar
              style={{ marginLeft: 8 }}
              src={
                "https://de.web.img3.acsta.net/pictures/16/03/22/12/38/275927.jpg"
              }
            /> */}
          </Fragment>
        }
      >
        <Menu.Item key="SignOut">Sign out</Menu.Item>
      </Menu.SubMenu>
    </Menu>,
  ];

  const sideBarAdmin = [
    {
      path: "/dashboard",
      text: "Dashboard",
      icon: <PieChartOutlined />,
      subMenu: [],
    },
    {
      path: "/mitra",
      text: "Mitra",
      icon: <UserOutlined />,
      subMenu: [],
    },
    {
      path: "/customers",
      text: "Customers",
      icon: <CustomerServiceFilled />,
      subMenu: [],
    },
    // {
    //   path: "/orders",
    //   text: "Orders",
    //   icon: <DesktopOutlined />,
    //   subMenu: [],
    // },
    {
      path: "/settings",
      text: "Settings",
      icon: <SettingOutlined />,
      subMenu: [
        {
          path: "/admin",
          text: "Admin",
        },
        {
          path: "/business-partner",
          text: "Business Partner",
        },
        {
          path: "/service",
          text: "Layanan",
        },
        {
          path: "/marketing-tool",
          text: "Marketing Tool",
        },
      ],
    },
  ];

  const sideBarBusinessPartner = [
    {
      path: "/dashboard",
      text: "Dashboard",
      icon: <PieChartOutlined />,
      subMenu: [],
    },
    {
      path: "/mitra",
      text: "Mitra",
      icon: <UserOutlined />,
      subMenu: [],
    },
    // {
    //   path: "/customers",
    //   text: "Customers",
    //   icon: <CustomerServiceFilled />,
    //   subMenu: [],
    // },
    {
      path: "/orders",
      text: "Orders",
      icon: <DesktopOutlined />,
      subMenu: [],
    },
    // {
    //   path: "/settings",
    //   text: "Settings",
    //   icon: <SettingOutlined />,
    //   subMenu: [
    //     {
    //       path: "/admin",
    //       text: "Admin",
    //     },
    //     {
    //       path: "/business-partner",
    //       text: "Business Partner",
    //     },
    //     {
    //       path: "/service",
    //       text: "Layanan",
    //     },
    //     {
    //       path: "/marketing-tool",
    //       text: "Marketing Tool",
    //     },
    //   ],
    // },
  ];

  const sideBar =
    localStorage.getItem("role") === "admin"
      ? sideBarAdmin
      : sideBarBusinessPartner;
  return (
    <>
      <Layout>
        <Sider
          width={256}
          breakpoint="lg"
          trigger={null}
          collapsible
          collapsed={collapsed}
          // onBreakpoint={!isMobile && onCollapseChange}
          className={"sider"}
        >
          <div className={"brand"}>
            <div className={"logo"}>
              <img alt="logo" src={"logo.png"} />
              {!collapsed && <h1>YOBEN</h1>}
            </div>
          </div>
          <Menu
            // theme="dark"
            mode="inline"
            defaultSelectedKeys={["1"]}
            selectedKeys={selectedKeys}
            openKeys={openSubKeys}
          >
            {sideBar.map((menu) => {
              if (menu.subMenu.length == 0) {
                return (
                  <Menu.Item key={menu.path} icon={menu.icon}>
                    <Link to={menu.path}>{menu.text}</Link>
                  </Menu.Item>
                );
              } else {
                return (
                  <Menu.SubMenu
                    key={menu.path}
                    icon={menu.icon}
                    title={menu.text}
                    onTitleClick={() => {
                      if (openSubKeys.find((subKey) => subKey === menu.path)) {
                        setOpenSubKeys([""]);
                      } else {
                        setOpenSubKeys([menu.path]);
                      }
                    }}
                  >
                    {menu.subMenu.map((subMenu) => {
                      return (
                        <Menu.Item key={subMenu.path}>
                          <Link to={`${menu.path}${subMenu.path}`}>
                            {subMenu.text}
                          </Link>
                        </Menu.Item>
                      );
                    })}
                  </Menu.SubMenu>
                );
              }
            })}
          </Menu>
        </Sider>
        <div className={`container ${collapsed ? "" : "open"}`}>
          <Header
            id="layoutHeader"
            className={`header ${collapsed ? "collapsed" : "fixed"}`}
          >
            <div className={`button`} onClick={() => setCollapsed(!collapsed)}>
              {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            </div>
            <div className={"rightContainer"}>{rightContent}</div>
          </Header>
          <Content className={"content"}>
            <AppRouter url={match.path} />
          </Content>
        </div>
      </Layout>
    </>
  );
};

export default Main;
