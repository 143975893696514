/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-bind */
import React from 'react';
// import moment from 'moment';
import { Upload, notification } from 'antd';
import imageCompression from 'browser-image-compression';
import { InboxOutlined } from '@ant-design/icons';

import { UploadFile, UploadChangeParam } from 'antd/lib/upload/interface';
import { Container } from './style';

const { Dragger } = Upload;

interface UploadImageProps {
  setStateFileList: (fileList: UploadFile<any>[], name: string) => Promise<unknown>;
  fileList: UploadFile<any>[];
  sliceFileList: (name: string) => void;
  setStateForm: (uri: string | ArrayBuffer | null, name: string) => void;
  name: string;
  disabled?: any;
  accept: string;
  icon?: JSX.Element;
  height?: string
}


const UploadImage: React.FC<UploadImageProps> = (props) => {
  const onChangeDragger = (info: UploadChangeParam<UploadFile<any>>, name: string) => {
    // const { status } = info.file;
    if (props.setStateFileList) {
      let file: any = info.file.originFileObj;

      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1000,
      };
      if (file?.type.includes('image')) {
        if (
          file?.type.includes('jpeg')
          || file?.type.includes('jpg')
          || file?.type.includes('png')
        ) {
          imageCompression(file, options)
            .then(compressedFile => {
              imageCompression.getDataUrlFromFile(compressedFile)
              .then(base64 => {
                imageCompression
                  .getFilefromDataUrl(base64, info.file.name)
                  .then(newFile => {
                    const converted: any = new File([newFile], info.file.name, { type: newFile.type });
                    converted.originFileObj = converted;
                    converted.uid = file.uid;
                    const fileList: any = converted;
                    file = fileList.originFileObj;
                    if (props.setStateFileList) {
                      if (fileList.response) {
                        // Component will show file.url as link
                        file.url = file.response.url;
                        file.thumbUrl = file.response.url;
                      }
    
                      props.setStateFileList([fileList], info.file.name)
                        .then(() => {
                          if (props.fileList && props.fileList.length > 1) {

                            if (props.sliceFileList) {
                              props.sliceFileList(name);
                              notification.error({
                                placement: 'topRight',
                                bottom: 50,
                                duration: 3,
                                message: 'Only one file allowed at a time',
                              });
                            }
                          } else {
                            const file = fileList;
                            // const reader: any = new FileReader();
                            // const mime = file ? file.type : undefined;
                            if (props.setStateForm) {
                              props.setStateForm(base64,name);
                            }
                          
                            
                          }
                        });
                    }
                    
                  });
                });
            });
        
        } else {
          notification.error({
            placement: 'topRight',
            bottom: 50,
            duration: 3,
            message: 'Only image (jpeg/jpg, png) and pdf file are allowed',
          });
        }
      } else {
        notification.error({
          placement: 'topRight',
          bottom: 50,
          duration: 3,
          message: 'Only image (jpeg/jpg, png) and pdf file are allowed',
        });
      }
    }
  };


  return (
    <Container height={props.height}>
      <Dragger
        name={ props.name }
        fileList={ props.fileList }
        // onRemove={ props.onRemove && props.onRemove }
        accept={ props.accept && props.accept }
        multiple={ false }
        disabled={ props.disabled ? props.disabled : false }
        onChange={ e => onChangeDragger(e, props.name ? props.name : '') }
      >
        <div>
          {props.icon ? props.icon : <InboxOutlined style={{fontSize: '24px'}}/>}
          <p>Upload image</p>
        </div>
      </Dragger>

    </Container>
  )
  
}

export default UploadImage;
