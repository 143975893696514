import userReducer from "./user/user.reducer";
import authReducer from "./auth/auth.reducer";
import mitraReducer from "./mitra/mitra.reducer";
import serviceReducer from "./service/service.reducer";
import orderReducer from "./order/order.reducer";

export const rootReducer = {
  user: userReducer,
  auth: authReducer,
  mitra: mitraReducer,
  service: serviceReducer,
  order: orderReducer,
};
