import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Button, Modal, Spin, Card, Col, Row, Popconfirm } from "antd";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faUser,
  faVenus,
  faMars,
  faPhone,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";

import * as OrderActions from "../../../state/order/order.action";
import * as OrderSelectors from "../../../state/order/order.selector";
import { Colors, Layout } from "../../../const";
import { IOrder } from "../../../state/order/order.interface";

const DashboardBusinessPartner = (props) => {
  const dispatch = useDispatch();
  const unassignedOrder = useSelector(OrderSelectors.selectUnassignedOrders);
  const isLoading = useSelector(OrderSelectors.selectLoading);
  const isSuccess = useSelector(OrderSelectors.selectSuccess);

  const availableMitras = useSelector(OrderSelectors.selectAvailableMitras);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedOrder, setSelectedOrder] = useState({
    code: "",
    price: 0,
    status: "",
    work_time: "",
    service: {
      code: "",
      name: "",
      image: "",
    },
    user: {
      code: "",
      name: "",
      gender: "",
      phone: "",
      image: null,
    },
    address: {
      code: "",
      name: "",
      address: "",
      description: "",
    },
    duration: {
      code: "",
      duration: 0
    }
  });
  const [selectedMitra, setSelectedMitra] = useState<string>("");

  useEffect(() => {
    dispatch(
      OrderActions.getOrder.request({
        orderStatus: "looking_for_mitra",
        limit: 100
      })
    );
  }, []);

  useEffect(() => {
    // TODO FIX BUG RADIO BUTTON
    setSelectedMitra("")
  }, [isModalOpen])

  useEffect(() => {
    if (isSuccess) {
      setIsModalOpen(false);
    }
  }, [isSuccess]);

  const handleAssignMitra = (order) => {
    setIsModalOpen(true);
    setSelectedOrder(order);
    dispatch(
      OrderActions.getMitraAvailable.request({
        orderCode: order.code,
      })
    );
  };

  const renderModalAssignMitra = () => {
    const columns = [
      {
        title: "Name",
        key: "name",
        dataIndex: "name",
        width: '200px'
      },
      {
        title: "Keterangan",
        key: "typeNotAvailable",
        dataIndex: "typeNotAvailable",
      },
    ];

    return (
      <Modal
        title="MITRA AVAILABLE"
        visible={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        confirmLoading={isLoading}
        footer={[
          <Button 
            type="primary"
            loading={isLoading}
            disabled={selectedMitra ? false: true} 
            onClick={() => dispatch(
              OrderActions.assignMitra.request({
                orderCode: selectedOrder.code,
                mitraCode: selectedMitra,
            })
          )}>Assign</Button>,
          <Popconfirm
            title="Apakah anda yakin membatalkan order ini?"
            onConfirm={() => dispatch(
              OrderActions.cancelOrder.request({
                orderCode: selectedOrder.code,
                source: 'unassignedOrders'
              })
            )}
          >
            <Button type="primary" danger>Batalkan</Button>
          </Popconfirm>
        ]}
      >
        <p>
          {selectedOrder.service.name}
          <span> - {`${selectedOrder.duration ? selectedOrder.duration.duration / 60 : 0} menit`}</span>
        </p>
        <p>
          Jam pelayanan: {moment(selectedOrder.work_time).format("ll")} -{" "}
          {moment(selectedOrder.work_time).format("HH:mm")}
        </p>
        <Table
          showHeader={false}
          loading={isLoading}
          rowSelection={{
            type: "radio",
            onChange: (selectedRowKeys: any, selectedRows) => {
              setSelectedMitra(selectedRowKeys[0]);
            },
            getCheckboxProps: (mitra) => {
              return {
                disabled: mitra.isAvailable === false, // Column configuration not to be checked
              };
            },
          }}
          columns={columns}
          dataSource={availableMitras.map((mitra, index) => {
            {
              return {
                ...mitra,
                key: mitra.code,
              };
            }
          })}
          {...Layout.table}
        />
      </Modal>
    );
  };

  return (
    <>
      <h1>Order belum di assign</h1>
      <div>
        <Row gutter={16}>
          {unassignedOrder ? (
            unassignedOrder.map((order: IOrder) => {
              return (
                <Col
                  span={6}
                  style={{
                    marginBottom: "16px",
                  }}
                >
                  <Card
                    title={`${order.service.name} - ${order.duration ? order.duration.duration / 60 : 0} menit`}
                    bordered={false}
                  >
                    <p>{order.code}</p>
                    {/* {order.duration ? (
                    <p>
                      Layanan: {order.service.name} -{" "}
                      {order.duration.duration / 60} menit
                    </p>
                  ) : null} */}

                    <p>
                      <FontAwesomeIcon
                        style={{
                          marginRight: 10,
                        }}
                        icon={faClock}
                      />
                      {moment(order.workTime).format("ll")} -{" "}
                      {moment(order.workTime).format("HH:mm")}
                    </p>
                    <p>
                      <FontAwesomeIcon
                        style={{
                          marginRight: 10,
                        }}
                        icon={faUser}
                      />{" "}
                      {order.user.name}{" "}
                      <FontAwesomeIcon
                        style={{
                          color:
                            order.user.gender === "f"
                              ? Colors.red
                              : Colors.yobenBlue,
                          marginRight: 10,
                        }}
                        icon={order.user.gender === "f" ? faVenus : faMars}
                      />
                    </p>
                    <p>
                      <FontAwesomeIcon
                        icon={faPhone}
                        style={{
                          marginRight: 10,
                        }}
                      />
                      {order.user.phone}
                    </p>
                    <p>
                      <FontAwesomeIcon
                        style={{
                          marginRight: 10,
                        }}
                        icon={faMapMarkerAlt}
                      />
                      <a
                        target="_blank"
                        href={`https://www.google.com/maps/search/?api=1&query=${order.address?.latitude},${order.address?.longitude}`}
                      >
                        {order.address?.address}
                      </a>
                    </p>
                    <p>Deskripsi: {order.address?.description}</p>
                    <Button
                      style={{
                        width: "100%",
                      }}
                      type="primary"
                      onClick={() => handleAssignMitra(order)}
                    >
                      Assign Mitra
                    </Button>
                  </Card>
                </Col>
              );
            })
          ) : (
            <Row justify="center">
              <Spin size="large" />
            </Row>
          )}
        </Row>
      </div>
      {renderModalAssignMitra()}
    </>
  );
};

export default DashboardBusinessPartner;
