import { createSelector } from "reselect";

import { IUserReducer } from "./user.interface";

const selectUserReducer = (state: any) => state.user;

const selectLoading = createSelector(
  selectUserReducer,
  (userReducer: IUserReducer) => userReducer.loading
);

const selectAdminList = createSelector(
  selectUserReducer,
  (userReducer: IUserReducer) => userReducer.adminList
);

const selectSuccess = createSelector(
  selectUserReducer,
  (userReducer: IUserReducer) => userReducer.success
);
export { selectLoading, selectAdminList, selectSuccess };
