const limit = 10;

const skip = (currentPage?: number, optionalLimit = limit) => {
  if (currentPage) {
    return (currentPage - 1) * optionalLimit;
  }

  return 0;
};

export default { limit, skip };
