import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  Modal,
  Button,
  Form,
  Input,
  Select,
  Row,
  DatePicker,
  Popconfirm,
} from "antd";
import moment from "moment";
import { InboxOutlined } from '@ant-design/icons';
import { UploadFile } from 'antd/lib/upload/interface';

import * as ServiceAction from "../../../state/service/service.action";
import * as ServiceSelector from "../../../state/service/service.selector";
import { ICoupon, IService } from "../../../state/service/service.interface";
import { Layout } from "../../../const";
import { CustomImage, SimplePagination, UploadImage } from '../../../components';
import PromotionSetting from "../promotion";

const MarketingTool = () => {
  const dispatch = useDispatch();
  const coupons = useSelector(ServiceSelector.selectCoupons);
  const isLoading = useSelector(ServiceSelector.selectLoading);
  const isSuccess = useSelector(ServiceSelector.selectSuccess);

  const allServices = useSelector(ServiceSelector.selectAllServices);
  const [form] = Form.useForm();

  const [isModalOpen, setIsModalOpen] = useState<boolean>();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [currentCoupon, setCurrentCoupon] = useState<ICoupon>({
    code: "",
    name: "",
    type: "",
    endDate: "",
    startDate: "",
    discount: 0,
    maxDiscount: 0,
    serviceCode: "",
    imageUrl: "",
    description: "",
  });
  const [currentType, setCurrentType] = useState<string>("");
  const [modalAction, setModalAction] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [images, setImages] = useState<any>([]);

  useEffect(() => {
    dispatch(
      ServiceAction.getServiceParent.request({
        areaCode: process.env.REACT_DEFAULT_AREA_CODE || "",
        // TO DO: areaCode should not be hard coded
      })
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      ServiceAction.getCoupon.request({
        page: currentPage,
      })
    );
  }, [currentPage, dispatch]);

  useEffect(() => {
    setIsModalOpen(false);
  }, [isSuccess]);

  const columns = [
    {
      title: "Kode",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      render: (startDate: string) => <p>{moment(startDate).format("ll")}</p>,
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      render: (endDate: string) => <p>{moment(endDate).format("ll")}</p>,
    },
    {
      title: "Action",
      key: "action",
      render: (coupon: ICoupon) => (
        <Button
          onClick={() => {
            setIsModalOpen(true);
            setCurrentCoupon(coupon);
            setModalAction("edit");
            form.setFieldsValue({
              name: coupon.name,
              type: coupon.type,
              dateRange: [moment(coupon.startDate), moment(coupon.endDate)],
              discount: coupon.discount,
              maxDiscount: coupon.maxDiscount,
              serviceCode: coupon.serviceCode,
              imageUrl: coupon.imageUrl,
              description: coupon.description,
            });
            setIsEdit(false);
          }}
        >
          Detail
        </Button>
      ),
    },
  ];

  const setStateFileList = (fileList: UploadFile[], name: string) => {
    // eslint-disable-next-line no-undef
    return new Promise(async (resolve) => {
      await setImages(fileList);
      await resolve()
    });
  };

  const sliceFileList = (name: string) => {
    setImages(images[name].splice(0, 1));
  };

  const setStateForm = (result: string | ArrayBuffer | null, name: string) => {
    if (result) {
      setCurrentCoupon({
        ...currentCoupon,
        imageUrl: result
      })
      form.setFieldsValue({
        imageUrl: result
      })
    }
  };

  const handleSubmitCoupon = (value: any) => {
    value.startDate = `${moment(value.dateRange[0]).format(
      "YYYY-MM-DD"
    )} 00:00:01`;
    value.endDate = `${moment(value.dateRange[1]).format(
      "YYYY-MM-DD"
    )} 23:59:59`;

    if (isEdit) {
      value.code = currentCoupon.code;
      dispatch(ServiceAction.editCoupon.request(value));
    } else {
      dispatch(ServiceAction.createCoupon.request(value));
    }
  };

  const onSelectCouponType = (value: string) => {
    setCurrentType(value);
    form.setFieldsValue({
      maxDiscount: 0,
    });
  };

  const onChangeDatePicker = (value) => {
    if (value) {
      form.setFieldsValue({
        startDate: value[0],
        endDate: value[1],
      });
    }
  };

  const onClickDelete = () => {
    dispatch(ServiceAction.deleteCoupon.request(currentCoupon.code));
  };

  const disabledInput = () =>  !isEdit && modalAction !== "create";
  const renderCouponModal = () => {
    return (
      <Form
        id="couponForm"
        form={form}
        onFinish={handleSubmitCoupon}
        {...Layout.formItem}
      >
        <Modal
          title={`${
            modalAction === "create" ? "Create Coupon" : "Detail Coupon"
          }`}
          visible={isModalOpen}
          onCancel={() => {
            setIsModalOpen(false);
            setIsEdit(false);
            form.resetFields();
          }}
          footer={[
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => onClickDelete()}
            >
              <Button
                danger
                loading={isLoading}
                style={{
                  display: isEdit && modalAction !== "create" ? "" : "none",
                }}
              >
                Delete 
              </Button>
            </Popconfirm>,
            <Button
              key="submit"
              type="primary"
              loading={isLoading}
              onClick={() => setIsEdit(!isEdit)}
              style={{
                display: modalAction === "create" || isEdit ? "none" : "",
              }}
            >
              {isEdit ? "Cancel Edit" : "Edit"}
            </Button>,
            <Button
              type="primary"
              loading={isLoading}
              key="edit"
              htmlType="submit"
              form="couponForm"
              style={{
                display: modalAction === "edit" && isEdit ? "" : "none",
              }}
            >
              Submit Edit
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={isLoading}
              htmlType="submit"
              form="couponForm"
              style={{
                display: modalAction === "create" ? "" : "none",
              }}
            >
              Submit
            </Button>,
          ]}
        >
          <Form.Item
            rules={[{ required: true }]}
            required={true}
            name="name"
            label="Name"
          >
            <Input placeholder="Name" disabled={disabledInput()} />
          </Form.Item>
          <Form.Item
            rules={[{ required: true }]}
            required={true}
            name="serviceCode"
            label="Layanan"
          >
            <Select placeholder="Pilih layanan" disabled={disabledInput()}>
              {allServices.map((service: IService) => {
                return (
                  <Select.Option value={service.code}>
                    {service.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            rules={[{ required: true }]}
            required={true}
            name="description"
            label="Description"
          >
            <Input placeholder="Deskripsi" disabled={disabledInput()}/>
          </Form.Item>
          <Form.Item
            rules={[{ required: true }]}
            required={true}
            name="imageUrl"
            label="Image"
          >
            { currentCoupon.imageUrl ?
              <CustomImage image={currentCoupon.imageUrl} command={() => setCurrentCoupon({
                ...currentCoupon,
                imageUrl: ""
              })}></CustomImage> : 
              <UploadImage
                setStateFileList={ setStateFileList }
                sliceFileList={ sliceFileList }
                setStateForm={ setStateForm }
                name='image'
                fileList={ images.image }
                accept='.png,.jpg'
                icon={ <InboxOutlined style={{fontSize: '24px'}}/> }
              />
            }
          </Form.Item>
          <Form.Item
            rules={[{ required: true }]}
            name="dateRange"
            label="Durasi Coupon"
          >
            <DatePicker.RangePicker
              style={{
                width: "100%",
              }}
              onChange={onChangeDatePicker}
              disabled={disabledInput()}
            ></DatePicker.RangePicker>
          </Form.Item>
          <Form.Item
            rules={[{ required: true }]}
            required={true}
            name="type"
            label="Tipe"
          >
            <Select
              placeholder="Pilih tipe kupon"
              onChange={onSelectCouponType}
              disabled={disabledInput()}
            >
              <Select.Option value="DISCOUNT">Diskon</Select.Option>
              <Select.Option value="DISCOUNT_PERCENT">
                Persen diskon
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            rules={[{ required: true }]}
            required={true}
            name="discount"
            label={`${
              currentType === "DISCOUNT" ? "Discount" : "Persent Discount"
            }`}
          >
            <Input placeholder="Diskon" disabled={disabledInput()}/>
          </Form.Item>

          <Form.Item
            name="maxDiscount"
            label="Max Discount"
            style={{
              display: currentType === "DISCOUNT" ? "none" : "",
            }}
          >
            <Input placeholder="Maksimal diskon" disabled={disabledInput()}/>
          </Form.Item>
        </Modal>
      </Form>
    );
  };

  return (
    <>
      <PromotionSetting />
      <Row justify="space-between">
        <h1>Coupon List</h1>
        <Button
          onClick={() => {
            setIsModalOpen(true);
            setModalAction("create");
            setCurrentCoupon({
              code: "",
              name: "",
              type: "",
              endDate: "",
              startDate: "",
              discount: 0,
              maxDiscount: 0,
              serviceCode: "",
              imageUrl: "",
              description: "",
            });
          }}
        >
          Create Coupon
        </Button>
      </Row>
      <Table
        loading={isLoading}
        dataSource={coupons}
        columns={columns}
        {...Layout.table}
      />
      <SimplePagination
        currentPage={currentPage}
        onChange={setCurrentPage}
        dataLength={coupons.length}
      />
      {renderCouponModal()}
    </>
  );
};

export default MarketingTool;
