import { all, takeEvery, call, put, fork } from "redux-saga/effects";
import { notification } from "antd";
import { goBack, push } from "react-router-redux";

import * as MitraActions from "./mitra.action";
import * as MitraServices from "../../services/mitra.service";
import { Http } from "../../const";

export function* getMitra() {
  yield takeEvery(MitraActions.getMitra.request, function* ({ payload }) {
    const result = yield call(MitraServices.getMitra, {
      limit: Http.limit,
      skip: Http.skip(payload.page),
      filter: payload.filter,
      value: payload.value
    });

    if (result.code === 200) {
      yield put(
        MitraActions.getMitra.success({
          mitra: result.data.results,
        })
      );
    } else {
      yield put(
        MitraActions.getMitra.failure({
          errorMsg: "Get Mitra error",
        })
      );
      notification.error({
        placement: "topRight",
        bottom: 50,
        duration: 3,
        message: result.message,
      });
    }
  });
}

export function* editMitra() {
  yield takeEvery(MitraActions.editMitra.request, function* ({ payload }) {
    const result = yield call(MitraServices.editMitra, payload);

    if (result.code === 200) {
      yield put(
        MitraActions.editMitra.success({
          mitra: result.data,
        })
      );

      notification.success({
        placement: "topRight",
        bottom: 50,
        duration: 3,
        message: "Edit Mitra Success",
      });
    } else {
      yield put(
        MitraActions.editMitra.failure({
          errorMsg: "Edit Mitra error",
        })
      );
    }
  });
}

export function* getMitraByCode() {
  yield takeEvery(MitraActions.getMitraByCode.request, function* ({ payload }) {
    const result = yield call(MitraServices.getMitraByCode, payload.mitraCode);

    if (result.code === 200) {
      yield put(
        MitraActions.getMitraByCode.success({
          mitra: result.data,
        })
      );
    } else {
      yield put(
        MitraActions.getMitra.failure({
          errorMsg: "Get Mitra by code error",
        })
      );
    }
  });
}

export function* getMitraServices() {
  yield takeEvery(
    MitraActions.getMitraServices.request,
    function* ({ payload }) {
      const result = yield call(
        MitraServices.getMitraServices,
        payload.mitraCode
      );

      if (result.code === 200) {
        yield put(
          MitraActions.getMitraServices.success({
            services: result.data.results,
            mitraCode: payload.mitraCode,
          })
        );
      } else {
        yield put(
          MitraActions.getMitraServices.failure({
            errorMsg: "Get Mitra services error",
          })
        );
      }
    }
  );
}

export function* insertMitraService() {
  yield takeEvery(
    MitraActions.insertMitraService.request,
    function* ({ payload }) {
      const result = yield call(MitraServices.insertMitraService, {
        mitraCode: payload.mitraCode,
        serviceCode: payload.service.code,
      });

      if (result.code === 202) {
        yield put(
          MitraActions.insertMitraService.success({
            service: payload.service,
          })
        );
      } else {
        yield put(
          MitraActions.insertMitraService.failure({
            errorMsg: "Insert Mitra services error",
          })
        );
      }
    }
  );
}

export function* removeMitraService() {
  yield takeEvery(
    MitraActions.removeMitraService.request,
    function* ({ payload }) {
      const result = yield call(MitraServices.removeMitraService, {
        mitraCode: payload.mitraCode,
        serviceCode: payload.service.code,
      });

      if (result.code === 202) {
        yield put(
          MitraActions.removeMitraService.success({
            service: payload.service,
          })
        );
      } else {
        yield put(
          MitraActions.removeMitraService.failure({
            errorMsg: "Remove Mitra services error",
          })
        );
      }
    }
  );
}

export function* registerMitra() {
  yield takeEvery(MitraActions.registerMitra.request, function* ({ payload }) {
    const result = yield call(MitraServices.registerMitra, payload);

    if (result.code === 200) {
      yield put(
        MitraActions.registerMitra.success({
          mitra: result.data,
        })
      );

      notification.success({
        placement: "topRight",
        bottom: 50,
        duration: 3,
        message: "Register Mitra Success",
      });
      // yield put(push(`/mitra/${result.data.code}`));
      yield put(goBack());

    } else {
      yield put(
        MitraActions.registerMitra.failure({
          errorMsg: "Register mitra  error",
        })
      );
    }
  });
}

export function* getTransaction() {
  yield takeEvery(MitraActions.getTransaction.request, function* ({ payload }) {
    const result = yield call(MitraServices.getTransaction, {
      transactionStatus: payload.status,
      limit: Http.limit,
      skip: Http.skip(payload.page),
      type: payload.type
    });

    if (result.code === 200) {
      yield put(
        MitraActions.getTransaction.success({
          transactions: result.data.results,
          page: payload.page,
        })
      );
    } else {
      yield put(
        MitraActions.getTransaction.failure({
          errorMsg: "Get transaction error",
        })
      );
    }
  });
}

export function* editTransactionStatus() {
  yield takeEvery(
    MitraActions.editTransactionStatus.request,
    function* ({ payload }) {
      const result = yield call(MitraServices.editTransactionStatus, payload);

      if (result.code === 200) {
        notification.success({
          placement: "topRight",
          bottom: 50,
          duration: 3,
          message: "Edit Transaction Success",
        });
        yield put(
          MitraActions.editTransactionStatus.success({
            transaction: result.data,
          })
        );
      } else {
        yield put(
          MitraActions.editTransactionStatus.failure({
            errorMsg: "Update status transaction error",
          })
        );
      }
    }
  );
}

export function* getMitraBalance() {
  yield takeEvery(
    MitraActions.getMitraBalance.request,
    function* ({ payload }) {
      const result = yield call(MitraServices.getMitraBalance, payload);
      if (result.code === 200) {
        yield put(
          MitraActions.getMitraBalance.success({
            amount: result.data.amount,
            mitraCode: result.data.mitraCode
          })
        );
      } else {
        yield put(
          MitraActions.getMitraBalance.failure({
            errorMsg: "Get Mitra balance error",
          })
        );
      }
    }
  );
}

export function* getOrderOnGoing() {
  yield takeEvery(
    MitraActions.getOrderOnGoing.request,
    function* ({ payload }) {
      const result = yield call(MitraServices.getOrderOnGoing, payload);
      if (result.code === 200) {
        yield put(
          MitraActions.getOrderOnGoing.success({
            orders: result.data
          })
        );
      } else {
        yield put(
          MitraActions.getOrderOnGoing.failure({
            errorMsg: "Get Order on going error",
          })
        );
      }
    }
  );
}

export function* getOrderHistory() {
  yield takeEvery(
    MitraActions.getOrderHistory.request,
    function* ({ payload }) {
      const result = yield call(MitraServices.getOrderHistory, payload);
      if (result.code === 200) {
        yield put(
          MitraActions.getOrderHistory.success({
            orders: result.data
          })
        );
      } else {
        yield put(
          MitraActions.getOrderHistory.failure({
            errorMsg: "Get order history error",
          })
        );
      }
    }
  );
}

export default function* rootSaga() {
  yield all([fork(getMitra)]);
  yield all([fork(getMitraByCode)]);
  yield all([fork(editMitra)]);
  yield all([fork(getMitraServices)]);
  yield all([fork(insertMitraService)]);
  yield all([fork(removeMitraService)]);
  yield all([fork(registerMitra)]);
  yield all([fork(getTransaction)]);
  yield all([fork(editTransactionStatus)]);
  yield all([fork(getMitraBalance)]);
  yield all([fork(getOrderOnGoing)]);
  yield all([fork(getOrderHistory)]);
}
