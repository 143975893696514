import React, { FunctionComponent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Row, Input, Form } from "antd";

import * as AuthSelectors from "../../state/auth/auth.selector";
import * as AuthActions from "../../state/auth/auth.action";

import { Style } from "./style";

const FormItem = Form.Item;

type Props = {
  history: any;
};

const Login: FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch();

  const handleLogin = (values: { username: String; password: String }) => {
    dispatch(AuthActions.loginPassword.request(values));
  };

  const role =
    process.env.REACT_APP_ROLE === "businessPartner"
      ? "Business partner"
      : "Admin";

  return (
    <Style>
      <div className={"form"}>
        <div className={"logo"}>
          <img alt="logo" src={"logo.png"} />
          <span>YOBEN {role}</span>
        </div>

        <Form onFinish={handleLogin}>
          <FormItem name="username" rules={[{ required: true }]} hasFeedback>
            <Input placeholder={"username"} />
          </FormItem>
          <FormItem name="password" rules={[{ required: true }]} hasFeedback>
            <Input type="password" placeholder={"password"} />
          </FormItem>
          <Row>
            <Button
              type="primary"
              htmlType="submit"
              loading={useSelector(AuthSelectors.selectLoading)}
            >
              Sign in
            </Button>
          </Row>
        </Form>
      </div>
    </Style>
  );
};

export default Login;
