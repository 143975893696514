import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import {
  Button,
  Divider,
  Input,
  InputNumber,
  Popconfirm,
  Row,
  Spin,
  Table,
  Form,
  Modal,
  Select,
  Dropdown,
  Menu,
} from "antd";
import { DownOutlined } from "@ant-design/icons";

import * as ServiceActions from "../../../../state/service/service.action";
import {
  IParentService,
  IService,
  IServiceDuration,
} from "../../../../state/service/service.interface";
import * as ServiceSelector from "../../../../state/service/service.selector";
import { Layout } from "../../../../const";

const ServiceEachArea = () => {
  interface pathParams {
    areaCode: string;
  }
  let { areaCode } = useParams<pathParams>();

  const dispatch = useDispatch();
  const parentServices = useSelector(ServiceSelector.selectParentServices);
  const isLoading = useSelector(ServiceSelector.selectLoading);
  const isSuccess = useSelector(ServiceSelector.selectSuccess);
  const serviceChilds = useSelector(ServiceSelector.selectServiceChildByParent);
  const serviceDurations = useSelector(ServiceSelector.selectServiceDuration);

  const [selectedParent, setSelectedParent] = useState<IParentService>({
    code: "",
    name: "",
    imageUrl: "",
    description: "",
  });

  // edit service child state
  const [modalService, setModalService] = useState<boolean>(false);
  const [isEditServiceChild, setIsEditServiceChild] = useState<boolean>(false);

  // edit duration state
  const [modalDuration, setModalDuration] = useState<boolean>(false);
  const [activeChildService, setActiveChildService] = useState<IService>({
    code: "",
    name: "",
    description: "",
    imageUrl: "",
  });
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");

  useEffect(() => {
    dispatch(
      ServiceActions.getServiceParent.request({
        areaCode: areaCode,
      })
    );
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setEditingKey("");
      setModalDuration(false);
      setModalService(false);
    }
  }, [isSuccess]);

  interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
    editing: boolean;
    dataIndex: string;
    title: any;
    inputType: "number" | "text";
    record: IServiceDuration;
    index: number;
    children: React.ReactNode;
  }
  const EditableCell: React.FC<EditableCellProps> = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode = inputType === "number" ? <InputNumber /> : <Input />;

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const renderExpandedRow = (durations: IServiceDuration[]) => {
    const isEditing = (record: IServiceDuration) => record.code === editingKey;
    const edit = (record: IServiceDuration) => {
      form.setFieldsValue({
        // code: "",
        // name: "",
        // duration: "",
        // price: 0,
        ...record,
      });
      setEditingKey(record.code);
    };

    const onClickEditDuration = async (duration: IServiceDuration) => {
      const editedDuration = (await form.validateFields()) as IServiceDuration;
      dispatch(
        ServiceActions.editServiceDuration.request({
          name: editedDuration.name,
          duration: editedDuration.duration,
          price: editedDuration.price,
          serviceCode: duration.serviceCode,
          durationCode: duration.code,
        })
      );
    };

    const onClickDeleteDuration = async (duration: IServiceDuration) => {
      dispatch(
        ServiceActions.removeServiceDuration.request({
          durationCode: duration.code,
        })
      );
    };

    const expandedColumn = [
      {
        title: "Kode",
        dataIndex: "code",
        key: "code",
      },
      {
        title: "Nama",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Durasi",
        dataIndex: "duration",
        key: "duration",
      },
      {
        title: "Harga",
        dataIndex: "price",
        key: "price",
      },
      {
        title: "Action",
        key: "action",
        render: (duration: IServiceDuration) => {
          const editable = isEditing(duration);

          return editable ? (
            <span>
              <a
                href="javascript:;"
                onClick={() => onClickEditDuration(duration)}
                style={{ marginRight: 8 }}
              >
                Save
              </a>
              <Popconfirm
                title="Sure to cancel?"
                onConfirm={() => setEditingKey("")}
              >
                <a style={{ marginRight: 8 }}>Cancel</a>
              </Popconfirm>
              <Popconfirm
                title="Sure to delete?"
                onConfirm={() => onClickDeleteDuration(duration)}
              >
                <a>Delete</a>
              </Popconfirm>
            </span>
          ) : (
            <a onClick={() => edit(duration)}>Edit</a>
          );
        },
      },
    ];

    const mergedColumns = expandedColumn.map((col) => {
      if (col.key == "action" || col.key == "code") {
        return col;
      } else {
        return {
          ...col,
          onCell: (record: IServiceDuration) => ({
            record,
            inputType: col.dataIndex === "price" ? "number" : "text",
            dataIndex: col.dataIndex,
            title: col.title,
            editing: isEditing(record),
          }),
        };
      }
    });

    return (
      <Form form={form} component={false} {...Layout.formItem}>
        <Table
          scroll={isMobile ? { x: "80vw" } : undefined}
          columns={mergedColumns}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          dataSource={durations}
          pagination={false}
          // footer={() => (
          //   <Button
          //     onClick={(value) => {
          //       setModalDuration(true);
          //       // setActiveChildService()
          //     }}
          //   >
          //     Tambah Durasi Baru
          //   </Button>
          // )}
        ></Table>
      </Form>
    );
  };

  const onClickParentService = (parentService: IParentService) => {
    setSelectedParent(parentService);
    dispatch(ServiceActions.setParentCode(parentService.code));
    dispatch(
      ServiceActions.getServiceChild.request({
        parentCode: parentService.code,
      })
    );
  };

  const mapServicesKey = (services) => {
    services.map((service) => (service.key = service.code));
    return services;
  };

  const menu = (service: IService) => {
    return (
      <Menu>
        <Menu.Item key="edit-service">
          <a
            onClick={() => {
              setModalService(true);
              setActiveChildService(service);
              setIsEditServiceChild(true);
              form.setFieldsValue({
                name: service.name,
                imageUrl: service.imageUrl,
                description: service.description,
                parentCode: service.parentCode,
              });
            }}
          >
            Edit
          </a>
        </Menu.Item>
        <Menu.Item key="edit-Duration">
          <a
            onClick={() => {
              setModalDuration(true);
              setActiveChildService(service);
            }}
          >
            Add Duration {service.name}
          </a>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="3" disabled>
          Delete
        </Menu.Item>
      </Menu>
    );
  };

  const columns = [
    {
      title: "Kode",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Nama",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Image",
      dataIndex: "imageUrl",
      key: "imageUrl",
      render: (imageUrl: string) => (
        <a target="_blank" href={imageUrl}>
          {imageUrl}
        </a>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (service: IService) => {
        return (
          <>
            <Dropdown overlay={menu(service)} trigger={["click"]}>
              <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                Action <DownOutlined />
              </a>
            </Dropdown>
          </>
        );
      },
    },
  ];

  const handleFormService = (value) => {
    value.areaCode = areaCode;

    if (isEditServiceChild) {
      value.code = activeChildService.code;
      dispatch(
        ServiceActions.editServiceChild.request({
          service: value,
        })
      );
    } else {
      dispatch(ServiceActions.createServiceChild.request(value));
    }
  };

  const handleExpand = (expanded: boolean, service: IService) => {
    if (expanded) {
      dispatch(
        ServiceActions.getServiceDuration.request({
          serviceCode: service.code,
        })
      );
    }
  };

  const onClickDeleteServiceChild = () => {
    dispatch(
      ServiceActions.deleteService.request({
        service: activeChildService,
      })
    );
  };

  const renderModalService = () => {
    return (
      <Form
        id="serviceForm"
        form={form}
        onFinish={handleFormService}
        {...Layout.formItem}
      >
        <Modal
          title="Create New Service"
          visible={modalService}
          onCancel={() => {
            setModalService(false);
            setIsEditServiceChild(false);
            form.resetFields();
          }}
          footer={[
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => onClickDeleteServiceChild()}
            >
              <Button
                style={{
                  display: isEditServiceChild ? "" : "none",
                }}
                danger
                loading={isLoading}
              >
                Delete
              </Button>
            </Popconfirm>,
            <Button
              key="submit"
              type="primary"
              loading={isLoading}
              htmlType="submit"
              form="serviceForm"
            >
              {isEditServiceChild ? "Edit" : "Submit"}
            </Button>,
          ]}
        >
          <Form.Item
            label="Nama"
            name="name"
            rules={[{ required: true }]}
            hasFeedback
          >
            <Input placeholder={"name"} />
          </Form.Item>

          <Form.Item
            label="Image"
            name="imageUrl"
            rules={[{ required: true, type: "string" }]}
            hasFeedback
          >
            <Input type="url" placeholder={ "image "} />
          </Form.Item>
          <Form.Item
            name="description"
            label="Deskripsi"
            rules={[{ required: true, type: "string" }]}
            hasFeedback
          >
            <Input type="text" placeholder={"description"} />
          </Form.Item>
          <Form.Item
            name="parentCode"
            label="Produk"
            rules={[{ required: true, type: "string" }]}
            hasFeedback
          >
            <Select>
              {parentServices.map((parentService: IParentService) => {
                return (
                  <Select.Option value={parentService.code}>
                    {parentService.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Modal>
      </Form>
    );
  };

  const handleFormDuration = (value) => {
    dispatch(
      ServiceActions.createServiceDuration.request({
        name: value.name,
        price: value.price,
        duration: value.duration,
        serviceCode: activeChildService?.code,
      })
    );
  };

  const renderModalDuration = () => {
    return (
      <Form
        id="durationForm"
        onFinish={handleFormDuration}
        {...Layout.formItem}
      >
        <Modal
          title="Tambah durasi"
          visible={modalDuration}
          onCancel={() => setModalDuration(false)}
          footer={[
            // <Button
            //   // style={{
            //   //   display: isEdit ? "" : "none",
            //   // }}
            //   danger
            //   loading={isLoading}
            //   // onClick={() => onClickDelete()}
            // >
            //   Delete
            // </Button>,
            <Button
              key="submit"
              type="primary"
              loading={isLoading}
              htmlType="submit"
              form="durationForm"
            >
              {/* {isEdit ? "Edit" : "Submit"} */}
              Submit
            </Button>,
          ]}
        >
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true }]}
            hasFeedback
          >
            <Input placeholder={"name"} />
          </Form.Item>

          <Form.Item
            name="price"
            label="Price"
            rules={[{ required: true }]}
            hasFeedback
          >
            <Input type="number" placeholder={"price"} />
          </Form.Item>
          <Form.Item
            name="duration"
            label="Duration"
            rules={[{ required: true }]}
            hasFeedback
          >
            <Input type="number" placeholder={"duration"} />
          </Form.Item>
        </Modal>
      </Form>
    );
  };

  return (
    <>
      <h1>SERVICE PER AREA</h1>
      {isLoading ? (
        <Row justify="center">
          <Spin size="large" />
        </Row>
      ) : (
        <Row>
          {parentServices.map((parentService: IParentService) => {
            return (
              <Button
                style={{
                  marginRight: 10,
                }}
                type={
                  selectedParent.code === parentService.code
                    ? "primary"
                    : undefined
                }
                onClick={() => onClickParentService(parentService)}
              >
                {parentService.name}
              </Button>
            );
          })}
        </Row>
      )}
      <Divider />
      <Row
        justify="space-between"
        style={{
          marginBottom: 10,
        }}
      >
        <h1>Service List</h1>
        <Button onClick={() => setModalService(true)}>
          Create new service
        </Button>
      </Row>
      {selectedParent.code === "" ? (
        <>Please select BUGAR/RESIK</>
      ) : (
        <>
          <Table
            dataSource={mapServicesKey(serviceChilds)}
            columns={columns}
            expandedRowRender={(row) =>
              renderExpandedRow(
                serviceDurations.filter(
                  (duration) => duration.serviceCode === row.code
                )
              )
            }
            onExpand={handleExpand}
            {...Layout.table}
          />
          {renderModalService()}
          {renderModalDuration()}
        </>
      )}
    </>
  );
};

export default ServiceEachArea;
