export enum OrderActionType {
  OrderError = "[ORDER] ACTION Error",

  GetOrder = "[ORDER] Get Order",
  GetOrderSuccess = "[ORDER] Get Order Success",

  GetOrderDetail = "[ORDER] Get Order Detail",
  GetOrderDetailSuccess = "[ORDER] Get Order Detail Success",

  GetMitraAvailable = "[ORDER] Get Mitra List For Order",
  GetMitraAvailableSuccess = "[ORDER] Get Mitra List For Order Success",

  AssignMitra = "[ORDER] Assign Mitra",
  AssignMitraSuccess = "[ORDER] Assign Mitra Success",

  CancelOrder = '[ORDER] Cancel Order',
  CancelOrderSuccess = '[ORDER] Cancel Order Success',

  GetMitraTransaction = '[ORDER] Get Mitra Transaction',
  GetMitraTransactionSuccess = '[ORDER] Get Mitra Transaction Success',

  PostTransaction = '[ORDER] Post Transaction',
  PostTransactionSuccess = '[ORDER] Post Transaction Success'
}