import { isMobile } from "react-device-detect";
import { TablePaginationConfig } from "antd/lib/table";

const formItem = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

interface ITable {
  // hasData: boolean;
  // bordered: boolean;
  // // size: SizeType;
  // // title?: ((currentPageData: any[]) => ReactNode);
  // showHeader: boolean;
  scroll?:
    | ({
        x?: string | number | true | undefined;
        y?: string | number | undefined;
      } & { scrollToFirstRowOnChange?: boolean | undefined })
    | undefined;
  // tableLayout?: "auto" | "fixed" | undefined;
  // defaultExpandAllRows: boolean;
  pagination: false | TablePaginationConfig | undefined;
}

const table: ITable = {
  scroll: isMobile ? { x: "80vw" } : undefined,
  pagination: false,
};

export default {
  formItem,
  table,
};
