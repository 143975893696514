import { faPray } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

import {Colors} from '../../../const'

const Style = styled.div`
  .balance-clickable {
    cursor: pointer;
  }

  .divider-container {
    margin-top: 0px;
  }
`;

type PropsOrderTab = {
  selected?: boolean
}

const OrderTab = styled.div<PropsOrderTab>`
  padding: 10px;
  cursor: pointer;
  border: 1px solid rgb(240, 240, 240);
  background: ${(props) => props.selected ? '#1890ff' : 'transparent'};
  color: ${(props) => props.selected ? 'white' : 'rgba(0, 0, 0, 0.85)'};

  p {
    margin: 0px;
  }
`

const Transaction = styled.p<{
  type: string
}>`
  color: ${(props) => props.type === 'withdraw' ? 'red' : 'green'}
`
export { Style, OrderTab, Transaction };