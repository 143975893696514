import styled from 'styled-components';

const Container = styled.div`
  .ant-avatar > img {
    object-fit: contain;
  }

  .ant-avatar {
    height: auto !important;
    width: 100% !important;
  }
`;

export { Container };