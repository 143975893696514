import { all } from "redux-saga/effects";
import userSagas from "./user/user.saga";
import authSagas from "./auth/auth.saga";
import mitraSagas from "./mitra/mitra.saga";
import serviceSagas from "./service/service.saga";
import orderSagas from "./order/order.saga";

export default function* rootSaga() {
  yield all([
    userSagas(),
    authSagas(),
    mitraSagas(),
    serviceSagas(),
    orderSagas(),
  ]);
}
